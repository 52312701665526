<template>

    <div class="adt-logo">

        <router-link to="/" class="adt-logo__link">

            <img src="@/assets/images/adt-logo.png" class="adt-logo__link-img" alt="" />

            <span class="adt-logo__link-text">{{ getTranslate.APP_NAME }}</span>

        </router-link> 

    </div>

</template>

<script>
import { mapGetters } from 'vuex'
export default {
    
    name: 'ADTLogo',

    computed: {

        ...mapGetters([

            'getTranslate'
        ])
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/scss/components/logo';
</style>