export default {

    state: {

        chartOnlineIDs: {},
        chartOnlineIDsProcess: false,
        chartOnlineIDsMsg: '',

        chartOnlineWS: null,
        chartOnlineWSToken: '',
        chartOnlineWSRestore: null,

        chartOnlineDeviceID: 0,

        chartOnline: {},
        chartOnlineMsg: '',
        chartOnlineProcess: false,

        chartOnlineConfig: null,

        updateIndicator: '',

        chartOnlinePeriod: 1000
    },

    actions: {

        /**
         * Получает ID блоков, доступных для вывода данных
         */
        async readChartOnlineIDs({rootState, state, getters, dispatch}) {

            const token = rootState.token.access

            if(token) {

                state.chartOnlineIDsProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.UDS_HTTP}/info`, 
                    method: 'GET'
                })

                if(!request.error) {

                    for(let ecu in request) {

                        const curentECU = request[ecu]

                        let dids = {}

                        curentECU.DIDs.map( item => {

                            dids[item.did] = item.description
                        })

                        curentECU.DIDs = dids
                    }

                    state.chartOnlineIDsMsg = { success: true, text: '' }
                    state.chartOnlineIDs = request

                } else {

                    state.chartOnlineIDsMsg = { success: false }
                    state.chartOnlineIDsMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.chartOnlineIDsProcess = false

            } else {

                state.chartOnlineIDsMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        async changeServerMode({rootState, state, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/control/set_diagnostic_mode`, 
                    method: 'POST', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.chartOnlineDeviceID = request.id
                    state.chartOnlineWSToken = request.token

                    dispatch('readChartOnline')
                }
            }
        },

        async readChartOnline({rootState, state, getters}, payload) {

            if(typeof WebSocket !== 'undefined') {

                const token = state.chartOnlineWSToken

                if(token) {

                    let ws,
                        params = ''

                    if(payload) {

                        for( let param in payload) {

                            params.length ?
                                params += '&' + param + '=' + payload[param] :
                                params = '?' + param + '=' + payload[param]
                        }
                    }

                    (function connect() {

                        ws = new WebSocket(
                            `${rootState.config.UDS_WS}/ws${params}`, 
                            ['Bearer', token]
                        )

                        ws.onmessage = function(msg) {

                            try {

                                const data = JSON.parse(msg.data)

                                if(!('error' in data)) {

                                    state.chartOnlineProcess = false

                                    for(let item in data) {

                                        if(!state.chartOnline[item]) {

                                            state.chartOnline[item] = {}
                                        }

                                        let ecu = state.chartOnline[item]

                                        data[item].map( did => {

                                            // Заполняем пустые значения на графике слева в начале мониторинга
                                            if(!ecu[did.did]) {

                                                ecu[did.did] = []

                                                //                 1652443477650 - 1000 * 99 = 1652443378650 
                                                const startValue = did.tm - state.chartOnlinePeriod * 99

                                                //      i = 1652443378650; i < 1652443477650
                                                for(let i = startValue; i < did.tm;) {

                                                    ecu[did.did].push({ date: i, value: 0})

                                                    i = i + state.chartOnlinePeriod
                                                }

                                                ecu[did.did].push({ date: did.tm, value: did.value})

                                            } else {

                                                ecu[did.did].push({ date: did.tm, value: did.value})
                                                ecu[did.did].shift()
                                            }
                                        })
                                    }

                                    state.updateIndicator = Date.now()

                                    state.chartOnlineMsg = { success: true, text: '' }

                                } else {

                                    let errorMsg = {}

                                    switch(data.error) {

                                        case 'EAuthError': errorMsg = 'CHART_ONLINE_ERROR_TOKEN'
                                            break
                                        default: errorMsg = 'EUnknown'
                                            break
                                    }

                                    state.chartOnlineMsg = { success: false, text: getters.getTranslate[errorMsg] }
                                }

                            } catch {

                                state.chartOnlineMsg = { success: false, text: getters.getTranslate.EUnknown }
                            }
                        }

                        ws.onopen = () => {

                            if(state.chartOnlineWSRestore) { 

                                clearInterval(state.chartOnlineWSRestore)
                                state.chartOnlineWSRestore = null
                            }

                            state.chartOnlineMsg = { success: true, text: '' }

                            state.chartOnlineWS = ws
                        }

                        ws.onerror = () => {

                            state.chartOnlineMsg = { success: false, text: getters.getTranslate.EWSDisconnected }

                            ws = null

                            if(!state.chartOnlineWSRestore) 
                                state.chartOnlineWSRestore = setInterval( () => connect(), 5000 )
                        }

                        ws.onclose = event => {

                            ws = null

                            if(event.wasClean) {

                                if(state.chartOnlineWSRestore) {

                                    clearInterval(state.chartOnlineWSRestore)
                                    state.chartOnlineWSRestore = null
                                }

                                return
                            }

                            if(!state.chartOnlineWSRestore) 
                                state.chartOnlineWSRestore = setInterval( () => connect(), 5000 )
                        }
                    })()

                } else {

                    return { error: 'EUnauthRequest' }
                }
            }
        }
    },

    mutations: {

        setChartOnline:         (state, data) => state.chartOnline          = data,
        setChartOnlinePeriod:   (state, data) => state.chartOnlinePeriod    = data,

        setChartOnlineMsg:      (state, data) => state.chartOnlineMsg       = data,
        setChartOnlineWS:       (state, data) => state.chartOnlineWS        = data,
        setChartOnlineProcess:  (state, data) => state.chartOnlineProcess   = data,
        setChartOnlineWSMsg:    (state, data) => state.chartOnlineWSMsg     = data,
        setChartOnlineWSRestore:(state, data) => state.chartOnlineWSRestore = data,

        setChartOnlineConfig:   (state, data) => state.chartOnlineConfig    = data
    }
}