/**
 * Цвета кузовов автомобилей
 */
 export default {

    state: {

        NOT_SET:            'CAR_COLOR_NOT_SET',
        LUSTRE_WHITE:       'CAR_COLOR_LUSTRE_WHITE',
        PORCELAIN:          'CAR_COLOR_PORCELAIN',
        LATTE_MACCHIATO:    'CAR_COLOR_LATTE_MACCHIATO',
        SUNSET_GOLD:        'CAR_COLOR_SUNSET_GOLD',
        RUSSIAN_GOLD:       'CAR_COLOR_RUSSIAN_GOLD',
        DESERT_TAUPE:       'CAR_COLOR_DESERT_TAUPE',
        BLACK_COPPER:       'CAR_COLOR_BLACK_COPPER',
        SILVER_LINING:      'CAR_COLOR_SILVER_LINING',
        BLUE_MAGIC:         'CAR_COLOR_BLUE_MAGIC',
        PULSAR_BLUE:        'CAR_COLOR_PULSAR_BLUE',
        GALANT_GREY:        'CAR_COLOR_GALANT_GREY',
        GRAPHITE_TRACE:     'CAR_COLOR_GRAPHITE_TRACE',
        UNIVERSE_BLACK:     'CAR_COLOR_UNIVERSE_BLACK',
        DESERT_RED:         'CAR_COLOR_DESERT_RED',
        VELVET_RUBY:        'CAR_COLOR_VELVET_RUBY',
        SHADE_OF_NIGHT:     'CAR_COLOR_SHADE_OF_NIGHT',
        DEEP_FOREST:        'CAR_COLOR_DEEP_FOREST',
    }
}