export default {

    state: {

        firmwares: { 

            array: [] 
        },
        firmwaresProcess: false,
        firmwaresMsg: '',

        firmwareCreateProcess: false,
        firmwareCreateMsg: '',

        firmwareDeleteProcess: false,
        firmwareDeleteMsg: ''
    },

    getters: {
        getFirmwaresProcess: state => state.firmwaresProcess
    },

    actions: {

        /**
         * Создаёт новый файл прошивки
         * 
         * @param {Object} payload - file: объект с полями формы создания нового файла прошивки, vin: VIN-код автомобиля
         */
        async createFirmware({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.firmwareCreateProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/egts/firmwares`, 
                    method: 'POST', 
                    token,
                    type: 'file',
                    data: payload.file
                })

                if(!request.error) {

                    state.firmwareCreateMsg = { success: true, text: getters.getTranslate.FIRMWARE_CREATE_SUCCESS }
                    dispatch('readFirmwares', { vin: payload.vin, 'c.limit': 10 } )

                } else {

                    state.firmwareCreateMsg = { success: false }
                    state.firmwareCreateMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.firmwareCreateProcess = false

            } else {

                state.firmwareCreateMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Получает автомобили
         * 
         * @param {String} payload - параметры запроса [см.swagger]
         */
        async readFirmwares({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.firmwaresProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        if(param !== 'vin') {

                            params.length ?
                                params += '&' + param + '=' + payload[param] :
                                params = '?' + param + '=' + payload[param]
                        }
                    }
                }

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/egts/firmwares/${payload.vin}${params}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    request.array.map( firmware => {

                        let status  = ''

                        switch(firmware.status) {

                            case 0: status = firmware.isSent ? 'EGTS_PC_OK' : 'EGTS_PC_IN_PROGRESS'
                                break

                            case 1: status = 'EGTS_PC_IN_PROGRESS'
                                break

                            case 128: status = 'EGTS_PC_UNS_PROTOCOL'
                                break

                            case 129: status = 'EGTS_PC_DECRYPT_ERROR'
                                break

                            case 130: status = 'EGTS_PC_PROC_DENIED'
                                break

                            case 131: status = 'EGTS_PC_INC_HEADERFORM'
                                break

                            case 132: status = 'EGTS_PC_INC_DATAFORM'
                                break

                            case 133: status = 'EGTS_PC_UNS_TYPE'
                                break

                            case 134: status = 'EGTS_PC_NOTEN_PARAMS'
                                break

                            case 135: status = 'EGTS_PC_DBL_PROC'
                                break

                            case 136: status = 'EGTS_PC_PROC_SRC_DENIED'
                                break

                            case 137: status = 'EGTS_PC_HEADERCRC_ERROR'
                                break

                            case 138: status = 'EGTS_PC_DATACRC_ERROR'
                                break

                            case 139: status = 'EGTS_PC_INVDATALEN'
                                break

                            case 140: status = 'EGTS_PC_ROUTE_NFOUND'
                                break

                            case 141: status = 'EGTS_PC_ROUTE_CLOSED'
                                break

                            case 142: status = 'EGTS_PC_ROUTE_DENIED'
                                break

                            case 143: status = 'EGTS_PC_INVADDR'
                                break

                            case 144: status = 'EGTS_PC_TTLEXPIRED'
                                break

                            case 145: status = 'EGTS_PC_NO_ACK'
                                break

                            case 146: status = 'EGTS_PC_OBJ_NFOUND'
                                break

                            case 147: status = 'EGTS_PC_EVNT_NFOUND'
                                break

                            case 148: status = 'EGTS_PC_SRVC_NFOUND'
                                break

                            case 149: status = 'EGTS_PC_SRVC_DENIED'
                                break

                            case 150: status = 'EGTS_PC_SRVC_UNKN'
                                break

                            case 151: status = 'EGTS_PC_AUTH_DENIED'
                                break

                            case 152: status = 'EGTS_PC_ALREADY_EXISTS'
                                break

                            case 153: status = 'EGTS_PC_ID_NFOUND'
                                break

                            case 154: status = 'EGTS_PC_INC_DATETIME'
                                break

                            case 155: status = 'EGTS_PC_IO_ERROR'
                                break

                            case 156: status = 'EGTS_PC_NO_RES_AVAIL'
                                break

                            case 157: status = 'EGTS_PC_MODULE_FAULT'
                                break

                            case 158: status = 'EGTS_PC_MODULE_PWR_FLT'
                                break

                            case 159: status = 'EGTS_PC_MODULE_PROC_FLT'
                                break

                            case 160: status = 'EGTS_PC_MODULE_SW_FLT'
                                break

                            case 161: status = 'EGTS_PC_MODULE_FW_FLT'
                                break

                            case 162: status = 'EGTS_PC_MODULE_IO_FLT'
                                break

                            case 163: status = 'EGTS_PC_MODULE_MEM_FLT'
                                break

                            case 164: status = 'EGTS_PC_TEST_FAILED'
                                break
                        }

                        firmware.status = status
                    })

                    state.firmwares = request

                    state.firmwaresMsg = { success: true }

                } else {

                    state.firmwares = { array: [] }

                    state.firmwaresMsg = { success: false }
                    state.firmwaresMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.firmwaresProcess = false

            } else {

                state.firmwaresMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Удаляет выбранную прошивку ЭБУ
         * 
         * @param {String} payload - { vin: "VIN-код автомобиля", name: "имя прошивки" }
         */
        async deleteFirmware({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.firmwareDeleteProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/egts/firmwares/${payload.vin}/${payload.name}`, 
                    method: 'DELETE', 
                    token
                })

                if(!request.error) {

                    state.firmwareDeleteMsg = { success: true, text: getters.getTranslate.FIRMWARE_DELETE_SUCCESS }
                    dispatch('readFirmwares', { vin: payload.vin, 'c.limit': 10 } )

                } else {

                    state.firmwareDeleteMsg = { success: false }
                    state.firmwareDeleteMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.firmwareDeleteProcess = false

            } else {

                state.firmwareDeleteMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        }
    },

    mutations: {

        setCreateFirmwareMsg:   (state, data) => state.firmwareCreateMsg    = data,
        setFirmwaresMsg:        (state, data) => state.firmwaresMsg         = data,
        setDeleteFirmwareMsg:   (state, data) => state.firmwareDeleteMsg    = data,

        resetFirmware(state) {

            state.firmware = {}
            state.firmwareProcess = false
            state.firmwareMsg = ''

            state.firmwareCreateProcess = false
            state.firmwareCreateMsg = ''

            state.firmwareDeleteProcess = false
            state.firmwareDeleteMsg = ''
        }
    }
}