export default {

    state: {

        carinfo: { data: {} },
        carinfoProcess: false,
        carinfoMsg: '',

        carinfoUpdateProcess: false,
        carinfoUpdateMsg: ''
    },

    actions: {

        /**
         * Получает информацию об автомобиле
         * 
         * @param {String} payload - VIN-код автомобиля
         */
        async readCarinfo({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.carinfoProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/cars/${payload}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    state.carinfo = request

                } else {

                    state.carinfoMsg = { success: false }
                    state.carinfoMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.carinfoProcess = false

            } else {

                state.carinfoMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Обновляет информацию об автомобиле
         * 
         * @param {Object} payload - поля формы редактирования информации об автомобиле
         */
        async updateCarinfo({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.carinfoUpdateProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/cars`, 
                    method: 'PUT', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.carinfo = request
                    state.carinfoUpdateMsg = { success: true, text: getters.getTranslate.CAR_INFO_UPDATE_SUCCESS }

                } else {

                    state.carinfoUpdateMsg = { success: false }
                    state.carinfoUpdateMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.carinfoUpdateProcess = false

            } else {

                state.carinfoUpdateMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        }
    },

    mutations: {

        setCarinfo:             (state, data)   => state.carinfo          = data,
        setCarinfoMsg:          (state, data)   => state.carinfoMsg       = data,
        setCarinfoUpdateMsg:    (state, data)   => state.carinfoUpdateMsg = data,

        resetCarinfo: state => state.carinfo = { data: {} }
    }
}