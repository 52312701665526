<template>

    <div class="adt-lang">

        <div class="adt-lang__desktop">

            <div class="adt-lang__current" @click="showLangList = !showLangList">{{ getLang }}</div>

            <div class="adt-lang__choose"  :class="{ 'adt-lang__choose--show' : showLangList }">

                <ul class="adt-lang__choose-list" id="ADTLangChooseList">

                    <template v-for="(language, index) in languages" :key="index">

                        <li
                            class="adt-lang__choose-list-item" 
                            v-if="language.code != currentLanguage.code"
                            :data-code="language.code" 
                            @click="changeLang($event)"
                        >

                            {{ language.code }}

                        </li>

                    </template>

                </ul>

            </div>

        </div>

        <form class="adt-lang__mobile">

            <div 
                class="adt-lang__mobile-item" 
                :class="{ 'adt-lang__mobile-item--selected' : language.code == getLang }"
                v-for="(language, index) in languages" 
                :key="index"
            >

                <label class="adt-lang__mobile-item-label" :for="'ADTLanguageInput-' + language.code">{{ language.code }}</label>
                
                <input 
                    class="adt-lang__mobile-item-input" 
                    type="radio" 
                    name="ADTLanguageInput" 
                    :id="'ADTLanguageInput-' + language.code"
                    :data-code="language.code"
                    @change="changeLang($event)"
                >

            </div>

        </form>

    </div>

</template>

<script>

import { mapActions, mapGetters } from 'vuex'

export default {

    name: 'ADTLanguage',

    data() {

        return {

            languages: [

                { code: 'RU' },
                { code: 'EN' }
            ],

            currentLanguage: {

                code: null,
            },

            showLangList: false
        }
    },

    computed: {

        ...mapGetters([

            'getTranslate',
            'getLang'
        ])
    },

    methods: {

        ...mapActions([

            'updateLanguage'
        ]),

        changeLang(event) {

            let code = event.target.dataset.code
            this.currentLanguage.code = this.getLang
            this.showLangList = false
            this.updateLanguage(code)
        },

        clickListener(event) {

            if(
                this.showLangList && 
                !event.target.classList.contains('adt-lang__current') && 
                !event.target.classList.contains('adt-lang__choose-list-item')
            ) {

                this.showLangList = false
            }
        }
    },

    mounted() {

        this.currentLanguage.code = this.getLang

        document.addEventListener('click', this.clickListener)
    },

    watch: {

        getLang(code) {

            this.currentLanguage.code = code
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/scss/components/language.scss';
</style>