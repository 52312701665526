export default {

    state: {

        userSets: {},
        userSetsProcess: false,
        userSetsMsg: null,

        userSetsCreateProcess: false,
        userSetsCreateMsg: null,

        userSetDeleteProcess: false,
        userSetDeleteMsg: null
    },

    actions: {

        /**
         * Создаёт связь одного набора с одним пользователем
         * 
         * @param {Object} payload - объект с полями формы создания связи правило-политика
         */
         async createUserSets({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.userSetsCreateProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/policy/links`, 
                    method: 'POST', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.userSetsCreateMsg = { success: true, text: getters.getTranslate.POLICY_USER_SET_CREATE_SUCCESS  }

                } else {

                    state.userSetsCreateMsg = { success: false }
                    state.userSetsCreateMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.setOnesCreateProcess = false

            } else {

                state.userSetsCreateMsg = { success: false, text:getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Получает наборы пользователя
         * 
         * @param {Object} payload - UUID набора
         */
         async readUserSets({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.setOnesProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        params.length ?
                            params += '&' + param + '=' + payload[param] :
                            params = '?' + param + '=' + payload[param]
                    }
                }

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/policy/sets${params}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    state.userSets = request
                    state.userSetsMsg = { success: true }

                } else {

                    state.userSets = {}

                    state.userSetsMsg = { success: false }
                    state.userSetsMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.userSetsProcess = false

            } else {

                state.userSetsMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Обновляет набор
         * 
         * @param {Object} payload - объект с полями формы обновления набора
         */
         async updateSet({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.setUpdateProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/policy/sets`, 
                    method: 'POST', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.setUpdateMsg = { success: true, text: getters.getTranslate.POLICY_SET_UPDATE_SUCCESS }

                } else {

                    state.setUpdateMsg = { success: false }
                    state.setUpdateMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.setUpdateProcess = false

            } else {

                state.setUpdateMsg = { success: false, text:getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Удаляет набор пользователя
         * 
         * @param {Object} payload - связка набор-пользователь [см.swagger]
         */
         async deleteUserSet({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.userSetDeleteProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/policy/links`, 
                    method: 'DELETE', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.userSetDeleteMsg = { success: true, text: getters.getTranslate.POLICY_USER_SET_DELETE_SUCCESS }

                } else {

                    state.ones = null

                    state.userSetDeleteMsg = { success: false }
                    state.userSetDeleteMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.userSetDeleteProcess = false

            } else {

                state.userSetDeleteMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }

            return false
        }
    },

    mutations: {

        setUserSets:                (state, data) => state.userSets                 = data,
        setUserSetsProcess:         (state, data) => state.userSetsProcess          = data,
        setUserSetsMsg:             (state, data) => state.userSetsMsg              = data,
        setUserSetsCreateProcess:   (state, data) => state.userSetsCreateProcess    = data,
        setUserSetsCreateMsg:       (state, data) => state.userSetsCreateMsg        = data,

        setUserSetDeleteProcess:    (state, data) => state.userSetDeleteProcess     = data,
        setUserSetDeleteMsg:        (state, data) => state.userSetDeleteMsg         = data,
    }
}