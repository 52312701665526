export default {

    state: {

        usercarsCreateList: {

            array: []
        },
        usercarsCreateListProcess: false,
        usercarsCreateListMsg: null,

        usercarsCreateLinks: {

            array: []
        },
        usercarsCreateLinksProcess: false,
        usercarsCreateLinksMsg: null,

        usercarsCreateProcess: false,
        usercarsCreateMsg: null
    },

    getters: {

        getUsercarsCreateList: state => state.usercarsCreateList,
        getUsercarsCreateListProcess: state => state.usercarsCreateListProcess,
        getUsercarsCreateListMsg: state => state.usercarsCreateListMsg,

        getUsercarsCreateLinks: state => state.usercarsCreateLinks,
        getUsercarsCreateLinksProcess: state => state.usercarsCreateLinksProcess,
        getUsercarsCreateLinksMsg: state => state.usercarsCreateLinksMsg,

        getUsercarsCreateProcess: state => state.usercarsCreateProcess,
        getUsercarsCreateMsg: state => state.usercarsCreateMsg
    },

    actions: {

        /**
         * Создаёт связи пользователь-автомобили
         * 
         * Нельзя устанавливать права пользователя на машины выше, чем общие права пользователя в проложениии
         * 
         * @param {Object} payload - объект с полями формы создания связи пользователь-автомобили
         */
         async createUsercars({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.usercarsCreateProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/car_users`, 
                    method: 'POST', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.usercarsCreateMsg = { success: true, text: getters.getTranslate.USER_CARS_CREATE_SUCCESS }

                } else {

                    state.usercarsCreateMsg = { success: false }
                    state.usercarsCreateMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.usercarsCreateProcess = false

            } else {

                state.usercarsCreateMsg = { success: false, text:getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Получает пользователей автомобиля
         * 
         * @param {String} payload - объект вида { vin: VIN_код_автомобиля; ...необязательные атрибуты списка с пагинацией }
         */
        async readUsercarsCreateList({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access
            
            if(token) {

                state.usercarsCreateListProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        params.length ?
                            params += '&' + param + '=' + payload[param] :
                            params = '?' + param + '=' + payload[param]
                    }
                }

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/cars${params}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    state.usercarsCreateList = request
                    state.usercarsCreateListMsg = { success: true }

                } else {

                    state.usercarsCreateListMsg         = { success: false }
                    state.usercarsCreateListMsg.text    = getters.getTranslate[request.error] || request.error
                }

                state.usercarsCreateListProcess = false

            } else {

                state.usercarsCreateListMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Получает связки пользователель <-> автомобили
         * 
         * @param {String} payload - объект вида { uuid: UUID_пользователя, [см.swagger] }
         */
        async readUsercarsCreateLinks({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.usercarsCreateLinksProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/car_users${payload}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    state.usercarsCreateLinks = request
                    state.usercarsCreateLinksMsg = { success: true }
                    
                } else {

                    if(request.error == 'ECarUserNotFound') {

                        state.usercarsCreateLinks = { array: [] }
                        state.usercarsCreateLinksMsg = { success: true }
                        return
                    }

                    state.usercarsCreateLinks = { array: [] }
                    state.usercarsCreateLinksMsg = { success: false }
                    state.usercarsCreateLinksMsg.text = getters.getTranslate[request.error] || request.error
                }
            
                state.usercarsCreateLinksProcess = false
                
            } else {

                state.usercarsCreateLinksMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        }
    },

    mutations: {

        setCreateUsercarsMsg: (state, data) => state.usercarsCreateMsg = data
    }
}
