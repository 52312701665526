export default {

    state: {

        diag: {},
        diagProcess: false,
        diagMsg: '',

        diagDeleteProcess: false,
        diagDeleteMsg: '',

        diagPDF: '',
        diagPDFProcess: false,
        diagPDFMsg: '',

        diagEmailProcess: false,
        diagEmailMsg: '',

        reports: {},
        reportsProcess: false,
        reportsMsg: '',

        diagRunProcess: false,
        diagRunMsg: '',

        diagDeleteDTCProcess: false,
        diagDeleteDTCMsg: ''
    },

    getters: {

        getDiag: state => state.diag,

        getDiagPDF: state => state.diagPDF,
        getDiagPDFProcess: state => state.diagPDFProcess,
        getDiagPDFMsg: state => state.diagPDFMsg,

        getDiagEmailProcess: state => state.diagEmailProcess,

        getDiagEmailMsg: state => state.diagEmailMsg,

        getDiagDeleteProcess: state => state.diagDeleteProcess,
        getDiagDeleteMsg: state => state.diagDeleteMsg,

        getReports: state => state.reports,
        getReportsProcess: state => state.reportsProcess,
        getReportsMsg: state => state.reportsMsg
    },

    actions: {

        /**
         * Получает информацию о последних файлах отчёта по ЭБУ выбранного автомобиля
         * 
         * @param {Object} payload - { vin: VIN_автомобиля, ...params... }
         */
        async readDiag({rootState,state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.diagProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        if(param !== 'vin') {

                            params.length ?
                                params += '&' + param + '=' + payload[param] :
                                params = '?' + param + '=' + payload[param]
                        }
                    }
                }

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/egts/reports/${payload.vin}${params}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    state.diag = request.lastReport
                    state.diagMsg = { success: true }

                } else {

                    state.diagMsg = { success: false }
                    state.diagMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.diagProcess = false

            } else {

                state.diagMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Получает содержимое файла PDF для диагностики
         * 
         * @param {Object} payload - { vin: VIN-код_автомобиля , name: название_файла_отчета__DTC_timestamp_blockName }
         */
        async readDiagPDF({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.diagPDFProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/egts/reports/pdf`, 
                    method: 'POST', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.diagPDF = request.result.pdfFileChunks
                    state.diagPDFMsg = { success: true }

                } else {

                    state.diagPDFMsg = { success: false }
                    state.diagPDFMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.diagPDFProcess = false

            } else {

                state.diagPDFMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Отправляет PDF-файл с выбранными отчётами по указанному адресу e-mail
         * 
         * @param {Object} payload - { vin: VIN-код_автомобиля , name: массив с именами блоков отчётов , email: e-mail_адрес }
         */
         async readDiagEmail({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.diagEmailProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/egts/reports/email`, 
                    method: 'POST', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.diagEmailMsg = { success: true, text: getters.getTranslate.DIAG_IMPORT_EMAIL_SUCCESS }

                } else {

                    state.diagEmailMsg = { success: false }
                    state.diagEmailMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.diagEmailProcess = false
                
            } else {

                state.diagEmailMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Запуск диагностики ЭБУ выбранного автомобиля
         * 
         * @param {Object} payload - { vin: VIN_автомобиля, ...params... }
         */
         async runDiag({rootState,state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.diagRunProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/egts/run_diag/${payload.vin}/${payload.block}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    state.diagRunMsg = { success: true, text: getters.getTranslate.ECUS_DIAG_REFRESH_SUCCESS }

                } else {

                    state.diagRunMsg = { success: false }
                    state.diagRunMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.diagRunProcess = false

            } else {

                state.diagRunMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Удаляет диагностические отчеты ЭБУ автомобиля
         * 
         * @param {Object} payload - { vin: VIN-код_автомобиля , name: название_файла_отчета__DTC_timestamp_blockName }
         */
        async deleteDiag({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.diagDeleteProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/egts/reports/${payload.vin}/${payload.name}`, 
                    method: 'DELETE', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.diagDeleteMsg = { success: true, text: getters.getTranslate.DIAG_BLOCK_DELETE_SUCCESS }

                } else {

                    state.diagDeleteMsg = { success: false }
                    state.diagDeleteMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.diagDeleteProcess = false
                
            } else {

                state.diagDeleteMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Получает список отчетов ЭБУ автомобиля
         * 
         * @param {String} payload - параметры запроса [см.swagger]
         */
        async readReports({ rootState, state, getters, dispatch }, payload) {

            const token = rootState.token.access

            if (token) {

                state.reportsProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        params.length ?
                            params += '&' + param + '=' + payload[param] :
                            params = '?' + param + '=' + payload[param]
                    }
                }

                const request  = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/egts/reports${params}`,
                    method: 'GET',
                    token
                })

                if (!request.error) {
                    state.reports = request
                    state.reportsMsg = { success: true } 

                } else {
                    state.reportsMsg = { success: false }
                    state.reportsMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.reportsProcess = false

            } else {
                state.reportsMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Удаляет коды ошибок по ЭБУ автомобиля
         * 
         * @param {Object} payload - { vin: VIN_автомобиля , name: имя_блока }
         */
         async deleteDiagDTC({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.diagDeleteDTCProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/egts/clear_dtc/${payload.vin}/${payload.block}`, 
                    method: 'DELETE', 
                    token
                })

                if(!request.error) {

                    state.diagDeleteDTCMsg = { success: false, text: getters.getTranslate.CMD_SUCCESS }

                } else {

                    state.diagDeleteDTCMsg = { success: false }
                    state.diagDeleteDTCMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.diagDeleteDTCProcess = false
                
            } else {

                state.diagDeleteDTCMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        }
    },

    mutations: {

        setDiag:            (state, data) => state.diag           = data,
        setDiagMsg:         (state, data) => state.diagMsg        = data,

        setDiagRunMsg:      (state, data) => state.diagRunMsg     = data,

        setDiagPDFMsg:      (state, data) => state.diagPDFMsg     = data,
        setDiagEmailMsg:    (state, data) => state.diagEmailMsg   = data,

        setDiagDeleteMsg:   (state, data) => state.diagDeleteMsg  = data,

        setReports:         (state, data) => state.reports        = data,
        setReportsMsg:      (state, data) => state.reportsMsg     = data,

        setDiagDeleteDTCMsg:(state, data) => state.diagDeleteDTCMsg = data
    }
}