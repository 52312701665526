export default {

    state: {

        modulesAttrs: {},
        modulesAttrsPsocess: false,
        modulesAttrsMsg: null,

        ruleCreateProcess: false,
        rulesCreateMsg: null,

        rules: null,
        rulesProcess: false,
        rulesMsg: null,

        rule: null,
        ruleProcess: false,
        ruleMsg: null,

        ruleUpdateProcess: false,
        ruleUpdateMsg: null,

        ruleDeleteAttrProcess: false,
        ruleDeleteAttrMsg: null,

        rulesDeleteProcess: false,
        rulesDeleteMsg: null
    },

    actions: {

        /**
         * Создаёт новое правило
         * 
         * @param {Object} payload - объект с полями формы создания нового правила
         */
         async createRule({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.ruleCreateProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/policy/rules`, 
                    method: 'POST', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.rulesCreateMsg = { success: true, text: payload.uuid ? getters.getTranslate.POLICY_RULE_ATTR_CREATE_SUCCESS : getters.getTranslate.POLICY_RULES_CREATE_SUCCESS }

                } else {

                    state.rulesCreateMsg = { success: false }
                    state.rulesCreateMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.ruleCreateProcess = false

            } else {

                state.rulesCreateMsg = { success: false, text:getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Получает доступные правила
         * 
         * @param {String} payload - имя модуля для получения атрибутов
         */
        async readModulesAttrs({rootState, state, getters, dispatch}) {

            const token = rootState.token.access

            if(token) {

                state.modulesAttrsPsocess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/policy/attrs`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    request.array.map( attr => {

                        if(typeof attr.target == 'string' ) {

                            const attrModule = attr.target.split('_')[0]

                            if(attrModule in state.modulesAttrs) {

                                state.modulesAttrs[attrModule].attrs.push( attr.target )

                            } else {

                                state.modulesAttrs[attrModule] = {

                                    name: 'POLICY_' + attrModule.toUpperCase(),
                                    attrs: [attr.target]
                                }
                            }
                        }
                    })
                }

                state.modulesAttrsPsocess = false

            } else {

                state.modulesAttrsMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Получает доступные правила
         * 
         * @param {Object} payload - параметры для GET-запроса
         */
        async readRules({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.rulesProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        if(typeof payload[param] == 'object' && payload[param].length) {

                            payload.vin_array.map( el => {

                                params.length ?
                                    params += '&' + param + '=' + el :
                                    params = '?' + param + '=' + el
                            })

                        } else {

                            params.length ?
                                params += '&' + param + '=' + payload[param] :
                                params = '?' + param + '=' + payload[param]
                        }
                    }
                }

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/policy/rules${params}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    state.rules = request
                    state.rulesMsg = { success: true }

                } else {

                    state.rules = null

                    state.rulesMsg = { success: false }
                    state.rulesMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.rulesProcess = false

            } else {

                state.rulesMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Получает правило по UUID
         * 
         * @param {String} payload - UUID правила
         */
         async readRule({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.ruleProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/policy/rules/${payload}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    state.rule = request
                    state.ruleMsg = { success: true }

                } else {

                    state.rule = null

                    state.ruleMsg = { success: false }
                    state.ruleMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.ruleProcess = false

            } else {

                state.ruleMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Обновляет выбранное правило
         * 
         * @param {Object} payload - объект с полями формы редактирования правила
         */
         async updateRule({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.ruleUpdateProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/policy/rules`, 
                    method: 'POST', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.ruleUpdateMsg = { success: true, text: getters.getTranslate.POLICY_RULE_UPDATE_SUCCESS }

                } else {

                    state.ruleUpdateMsg = { success: false }
                    state.ruleUpdateMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.ruleUpdateProcess = false

            } else {

                state.ruleUpdateMsg = { success: false, text:getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Удаляет правило
         * 
         * @param {String} payload - ID правила
         */
        async deleteRules({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.rulesDeleteProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/policy/rules/${payload}`, 
                    method: 'DELETE', 
                    token
                })

                if(!request.error) {

                    state.rulesDeleteMsg = { success: true }

                } else {

                    state.rules = null

                    state.rulesDeleteMsg = { success: false }
                    state.rulesDeleteMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.rulesDeleteProcess = false

            } else {

                state.rulesDeleteMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }

            return false
        },

        /**
         * Удаляет атрибут правила
         * 
         * @param {String} payload - condition атрибута + delete=true [см.swagger]
         */
         async deleteRuleAttr({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.ruleDeleteAttrProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/policy/rules`, 
                    method: 'POST', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.ruleDeleteAttrMsg = { success: true, text: getters.getTranslate.POLICY_ATTRS_DELETE_SUCCESS }

                } else {

                    state.rules = null

                    state.ruleDeleteAttrMsg = { success: false }
                    state.ruleDeleteAttrMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.ruleDeleteAttrProcess = false

            } else {

                state.ruleDeleteAttrMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }

            return false
        }
    },

    mutations: {

        setRule:                (state, data) => state.rule                 = data,
        setRules:               (state, data) => state.rules                = data,
        setRulesMsg:            (state, data) => state.rulesMsg             = data,
        setRulesCreateMsg:      (state, data) => state.rulesCreateMsg       = data,
        setRuleUpdateMsg:       (state, data) => state.ruleUpdateMsg        = data,
        setRuleDeleteMsg:       (state, data) => state.ruleDeleteMsg        = data,
        setRuleDeleteAttrMsg:   (state, data) => state.ruleDeleteAttrMsg    = data,
        setModulesAttrs:        (state, data) => state.modulesAttrs         = data,
        setModulesAttrsMsg:     (state, data) => state.modulesAttrsMsg      = data
    }
}