export default {
    state: {
        TIMEGAP: 20
    },

    actions: {
        /**
         * Метод для fetch без токена, сейчас нигде не используется, нужен для тестов
         */
        async fetchRequestWoToken(store, payload) {

            let parameters = {

                method: payload.method,
                cache: 'no-cache',
                referrerPolicy: 'origin-when-cross-origin',
                headers: new Headers()
            }

            if(payload.data) {

                if(payload.type) {

                    payload.type == 'json' ?
                        parameters.body = JSON.stringify(payload.data) :
                        parameters.body = payload.data

                } else {

                    parameters.body = JSON.stringify(payload.data)
                }
            }

            try {

                const request = await fetch(payload.address, parameters)

                const response = await request.text()

                const result = JSON.parse( response )

                if(request.ok) {

                    return result
                
                } else if(result.message) {

                    return { error: result.message }

                } else {

                    return { error: 'EUnknown' }
                }

            } catch {

                return { error: 'EConnection' }
            }
        },

        /**
         * payload: { address: 'адрес запроса', method: 'метод', token: true|false, data: 'параметры запроса', type: 'json|нативный' }
         */
        async fetchRequest({rootState, state, commit, dispatch}, payload) {
            try {

                /**
                 * 1. Если рефреш токен не обновляется в данный момент
                 *      иначе см. 1.1
                 */
                if (rootState.token.refreshPromise === null) {

                    let parameters = {

                        method: payload.method,
                        cache: 'no-cache',
                        referrerPolicy: 'origin-when-cross-origin',
                        headers: new Headers()
                    }

                    /**
                     * 2. Если запрос с токеном
                     *      иначе см. 4
                     */
                    if (payload.token) {

                        let token = JSON.parse(atob(rootState.token.access.split('.')[1]))

                        // время истечения аксесс токена
                        let expTime = token.exp * 1000
                        // время сейчас
                        let now = Date.now()

                        let delta = (expTime - now) / 1000

                        /**
                         * 3. Если срок годности токена < TIMEGAP (20 сек)
                         *      вызываем метод refreshToken
                         *      получившийся промис кладем в rootState.token.refreshPromise
                         *      выполняем fetchRequest еще раз
                         * 
                         *      иначе не вызываем метод refreshToken
                         */
                        if (delta < state.TIMEGAP) {

                            let promise = dispatch('refreshToken')

                            commit('setRefreshPromise', promise)

                            return dispatch('fetchRequest', payload)
                        }

                        parameters.headers.append('Authorization', 'Bearer ' + rootState.token.access)
                    }

                    if(payload.data) {

                        if(payload.type) {
    
                            payload.type == 'json' ?
                                parameters.body = JSON.stringify(payload.data) :
                                parameters.body = payload.data
    
                        } else {
    
                            parameters.body = JSON.stringify(payload.data)
                        }
                    }

                    // 4. Выполняем запрос
                    const request = await fetch(payload.address, parameters)

                    const response = await request.text()

                    const result = JSON.parse( response )

                    if(request.ok) {

                        return result

                    }

                    /**
                     * Следим за ошибками
                     * ETokenError - не корректное обновление токена: повторный запрос аксесс или рефреш с истекшим сроком годности
                     */
                    let msg = result?.details[0]?.value

                    if (msg === 'ETokenError') {

                        dispatch('logout')

                        return
                    }

                    return { error: msg }
                    
                }

                /**
                 * 1.1. Если рефреш токен обновляется в данный момент
                 *      сюда попадает dispatch('fetchRequest', payload) из п.3 
                 *      и 2 + n вызовы fetchRequest
                 */

                // ждем обновление рефреш токена
                await rootState.token.refreshPromise

                commit('setRefreshPromise', null)

                // вызываем dispatch('fetchRequest', payload)
                if(rootState.token.access) {

                    return dispatch('fetchRequest', payload)

                } else {

                    dispatch('logout')
                }
                    
            } catch {
                return { error: 'EConnection' }
            }
        },

        logout() {

            localStorage.clear()
            document.location.reload()
        }
    }
}