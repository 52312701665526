export default {
    
    state: {

        lang: 'RU'
    },

    getters: {

        getLang: state => state.lang
    },
    
    actions: {

        updateLanguage({state}, payload) {
            
            localStorage.setItem('ADT_LANG', payload)
            state.lang = payload

            // Меняем HTML lang при определении/переключении языка
            document.getElementsByTagName('html')[0].setAttribute('lang', payload.toLowerCase())
        }
    }
}