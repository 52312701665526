import parseMinutes from "@/helpers/parseMinutes"

export default {

    state: {

        point: null,
        pointMsg: '',

        carState: {
            door_trunk: {
                value: '—',
                value_text: '',
                unit: '',
                id: 13
            },
            engine_on: {
                value: '—',
                value_text: '',
                unit: '',
                id: 43
            },
            engine_off: {
                value: '—',
                value_text: '',
                unit: '',
                id: 44
            },
            start_moving: {
                value: '—',
                value_text: '',
                unit: '',
                id: 45
            },
            stop_moving: {
                value: '—',
                value_text: '',
                unit: '',
                id: 46
            },
            ride_time: {
                value: '—',
                value_text: '',
                unit: 'UNIT_MINUTE',
                id: 34
            },
            sdm_uptime : {
                value: '—',
                value_text: '',
                unit: 'UNIT_MINUTE',
                id: 128
            },
            // HV_system_failure: {
            //     value: '—',
            //     unit: '',
            //     id: 61
            // },
            wheel_front_left_pressure: {
                value: '—',
                value_text: '',
                unit: 'UNIT_ATM',
                id: 22
            },
            wheel_front_right_pressure: {
                value: '—',
                value_text: '',
                unit: 'UNIT_ATM',
                id: 23
            },
            wheel_back_left_pressure: {
                value: '—',
                value_text: '',
                unit: 'UNIT_ATM',
                id: 24
            },
            wheel_back_right_pressure: {
                value: '—',
                value_text: '',
                unit: 'UNIT_ATM',
                id: 25
            },
            wheel_front_left_status: {
                value: '—',
                value_text: '',
                unit: '',
                id: 37
            },
            wheel_front_right_status: {
                value: '—',
                value_text: '',
                unit: '',
                id: 38
            },
            wheel_back_left_status: {
                value: '—',
                value_text: '',
                unit: '',
                id: 39
            },
            wheel_back_right_status: {
                value: '—',
                value_text: '',
                unit: '',
                id: 40
            },
            door_front_left: {
                value: '—',
                value_text: '',
                unit: '',
                id: 9
            },
            door_front_right: {
                value: '—',
                value_text: '',
                unit: '',
                id: 10
            },
            door_back_left: {
                value: '—',
                value_text: '',
                unit: '',
                id: 11
            },
            door_back_right: {
                value: '—',
                value_text: '',
                unit: '',
                id: 12
            },
            service_date: {
                value: '—',
                value_text: '',
                unit: '',
                id: 114
            },
            // door_front_left_closed: {
            //     value: '—',
            //     unit: '',
            //     id: 27
            // },
            // door_front_right_closed: {
            //     value: '—',
            //     unit: '',
            //     id: 28
            // },
            // door_back_left_closed: {
            //     value: '—',
            //     unit: '',
            //     id: 29
            // },
            // door_back_right_closed: {
            //     value: '—',
            //     unit: '',
            //     id: 30
            // },
            lvb_soh: {
                value: '—',
                value_text: '',
                unit: '%',
                id: 126
            },
            lvb_soc: {
                value: '—',
                value_text: '',
                unit: '%',
                id: 127
            },
            SOH_HV_AKB: {
                value: '—',
                value_text: '',
                unit: '%',
                id: 135
            },
            SOC_HV_AKB: {
                value: '—',
                value_text: '',
                unit: '%',
                id: 136
            },
            // oil_pressure_warning: {
            //     value: '—',
            //     unit: '',
            //     id: 58
            // },
            // mil_ind_blink: {
            //     value: '—',
            //     unit: '',
            //     id: 60
            // },
            // mil_onRq: {
            //     value: '—',
            //     unit: '',
            //     id: 59
            // },
            engine_oil_lvInd: {
                value: '—',
                value_text: '',
                unit: '',
                id: 55
            },
            door_hood: {
                value: '—',
                value_text: '',
                unit: '',
                id: 8
            },
            battery_voltage: {
                value: '—',
                value_text: '',
                unit: 'UNIT_VOLT',
                id: 21
            },
            // engine_rev: {
            //     value: '—',
            //     unit: 'UNIT_RPM',
            //     id: 26
            // },
            window_fully_closed_FL: {
                value: '—',
                value_text: '',
                unit: '',
                id: 117
            },
            window_fully_closed_FR: {
                value: '—',
                value_text: '',
                unit: '',
                id: 118
            },
            window_fully_closed_RL: {
                value: '—',
                value_text: '',
                unit: '',
                id: 119
            },
            window_fully_closed_RR: {
                value: '—',
                value_text: '',
                unit: '',
                id: 120
            },
            // TPMS_sensor_fault_FL: {
            //     value: '—',
            //     unit: '',
            //     id: 100
            // },
            // TPMS_sensor_fault_FR: {
            //     value: '—',
            //     unit: '',
            //     id: 101
            // },
            // TPMS_sensor_fault_RL: {
            //     value: '—',
            //     unit: '',
            //     id: 102
            // },
            // TPMS_sensor_fault_RR: {
            //     value: '—',
            //     unit: '',
            //     id: 103
            // },
            gear_position: {
                value: '—',
                value_text: '',
                unit: '',
                id: 48
            },
            last_diagnostics_time: {
                value: '—',
                value_text: '',
                unit: '',
                id: 52
            },
            last_navigation_time: {
                value: '—',
                value_text: '',
                unit: '',
                id: 50
            },
            last_can_param_time: {
                value: '—',
                value_text: '',
                unit: '',
                id: 51
            },
            last_online: {
                value: '—',
                value_text: '',
                unit: '',
                id: 0
            },
            total_mileage: {
                value: '—',
                value_text: '',
                unit: 'UNIT_KM',
                id: 15
            },
            service_mileage: {
                value: '—',
                value_text: '',
                unit: 'UNIT_KM',
                id: 115
            },
            ride_mileage: {
                value: '—',
                value_text: '',
                unit: 'UNIT_KM',
                id: 31
            },
            mileage_on_fuel_residues: {
                value: '—',
                value_text: '',
                unit: 'UNIT_KM',
                id: 19
            },
            // epb_status: {
            //     value: '—',
            //     unit: '',
            //     id: 20
            // },
            status: {
                value: '—',
                value_text: '',
                unit: '',
                id: 1
            },
            is_locked: {
                value: null,
                value_text: '',
                unit: '',
                id: 14
            },
            esp_speed: {
                value: '—',
                unit: 'UNIT_KMH',
                id: 16
            },
            // EBD_lamp: {
            //     value: '—',
            //     unit: '',
            //     id: 93
            // },
            // ESP_lamp: {
            //     value: '—',
            //     unit: '',
            //     id: 112
            // },
            // TCS_lamp: {
            //     value: '—',
            //     unit: '',
            //     id: 94
            // },
            // ABS_lamp: {
            //     value: '—',
            //     unit: '',
            //     id: 92
            // },
            battery_status: {
                value: '—',
                value_text: '',
                unit: '',
                id: 41
            },
            // st_transmission_warning: {
            //     value: '—',
            //     unit: '',
            //     id: 89
            // },
            // TM_warning_indicator: {
            //     value: '—',
            //     unit: '',
            //     id: 113
            // },
            TPMS_battery_status_FL: {
                value: '—',
                value_text: '',
                unit: '',
                id: 96
            },
            TPMS_battery_status_FR: {
                value: '—',
                value_text: '',
                unit: '',
                id: 97
            },
            TPMS_battery_status_RL: {
                value: '—',
                value_text: '',
                unit: '',
                id: 98
            },
            TPMS_battery_status_RR: {
                value: '—',
                value_text: '',
                unit: '',
                id: 99
            },
            // DMFL_warning_indicator: {
            //     value: '—',
            //     unit: '',
            //     id: 108
            // },
            // DMFR_warning_indicator: {
            //     value: '—',
            //     unit: '',
            //     id: 109
            // },
            // DMRL_warning_indicator: {
            //     value: '—',
            //     unit: '',
            //     id: 110
            // },
            // DMRR_warning_indicator: {
            //     value: '—',
            //     unit: '',
            //     id: 111
            // },
            // brake_light_HM_status: {
            //     value: '—',
            //     unit: '',
            //     id: 62
            // },
            // position_light_RL_status: {
            //     value: '—',
            //     unit: '',
            //     id: 62
            // },
            // position_light_RR_status: {
            //     value: '—',
            //     unit: '',
            //     id: 75
            // },
            // rear_fog_light_status: {
            //     value: '—',
            //     unit: '',
            //     id: 67
            // },
            // SCU_calibration_status: {
            //     value: '—',
            //     unit: '',
            //     id: 91
            // },
            // CCU_failure: {
            //     value: '—',
            //     unit: '',
            //     id: 95
            // },
            // cornering_light_left_status: {
            //     value: '—',
            //     unit: '',
            //     id: 76
            // },
            // daytime_light_left_status: {
            //     value: '—',
            //     unit: '',
            //     id: 78
            // },
            // turn_indicator_RL_status: {
            //     value: '—',
            //     unit: '',
            //     id: 84
            // },
            // turn_indicator_FL_status: {
            //     value: '—',
            //     unit: '',
            //     id: 80
            // },
            // turn_indicator_ML_status: {
            //     value: '—',
            //     unit: '',
            //     id: 82
            // },
            // reverse_light_left_status: {
            //     value: '—',
            //     unit: '',
            //     id: 72
            // },
            // brake_light_left_status: {
            //     value: '—',
            //     unit: '',
            //     id: 63
            // },
            // low_beam_left_status: {
            //     value: '—',
            //     unit: '',
            //     id: 70
            // },
            // high_beam_left_status: {
            //     value: '—',
            //     unit: '',
            //     id: 68
            // },
            // T15_fault_status: {
            //     value: '—',
            //     unit: '',
            //     id: 104
            // },
            // T30i_fault_status: {
            //     value: '—',
            //     unit: '',
            //     id: 105
            // },
            // T30d_fault_status: {
            //     value: '—',
            //     unit: '',
            //     id: 106
            // },
            // BCM_warning_indicator: {
            //     value: '—',
            //     unit: '',
            //     id: 107
            // },
            vas_activation: {
                value: '—',
                value_text: '',
                unit: '',
                id: 129
            },
            // position_light_FL_status: {
            //     value: '—',
            //     unit: '',
            //     id: 86
            // },
            // position_light_FR_status: {
            //     value: '—',
            //     unit: '',
            //     id: 87
            // },
            // front_left_fog_light_status: {
            //     value: '—',
            //     unit: '',
            //     id: 65
            // },
            // front_right_fog_light_status: {
            //     value: '—',
            //     unit: '',
            //     id: 66
            // },
            // SCU_fault_status: {
            //     value: '—',
            //     unit: '',
            //     id: 90
            // },
            // cornering_light_right_status: {
            //     value: '—',
            //     unit: '',
            //     id: 77
            // },
            // daytime_light_right_status: {
            //     value: '—',
            //     unit: '',
            //     id: 79
            // },
            // turn_indicator_RR_status: {
            //     value: '—',
            //     unit: '',
            //     id: 85
            // },
            // turn_indicator_FR_status: {
            //     value: '—',
            //     unit: '',
            //     id: 81
            // },
            // turn_indicator_MR_status: {
            //     value: '—',
            //     unit: '',
            //     id: 83
            // },
            // reverse_light_right_status: {
            //     value: '—',
            //     unit: '',
            //     id: 73
            // },
            // brake_light_right_status: {
            //     value: '—',
            //     unit: '',
            //     id: 64
            // },
            // low_beam_right_status: {
            //     value: '—',
            //     unit: '',
            //     id: 71
            // },
            // high_beam_right_status: {
            //     value: '—',
            //     unit: '',
            //     id: 69
            // },
            // head_light_leveling_ctrl_failure: {
            //     value: '—',
            //     unit: '',
            //     id: 88
            // },
            // ERAG_failure_status: {
            //     value: '—',
            //     unit: '',
            //     id: 53
            // },
            ride_fuel_consumption: {
                value: '—',
                value_text: '',
                unit: 'UNIT_LITER_100_KM',
                id: 33
            },
            // vehicle_secure_lock_state: {
            //     value: '',
            //     usit: '',
            //     id: 116
            // },
            fuel_consumption: {
                value: '—',
                value_text: '',
                unit: 'UNIT_LITER_100_KM',
                id: 18
            },
            ride_average_speed: {
                value: '—',
                value_text: '',
                unit: 'UNIT_KMH',
                id: 32
            },
            front_inside_temperature: {
                value: '—',
                value_text: '',
                unit: '°C',
                id: 121
            },
            back_inside_temperature: {
                value: '—',
                value_text: '',
                unit: '°C',
                id: 122
            },
            temperature_outside: {
                value: '—',
                value_text: '',
                unit: '°C',
                id: 7
            },
            front_left_temperature: {
                value: '—',
                value_text: '',
                unit: '°C',
                id: 3
            },
            front_right_temperature: {
                value: '—',
                value_text: '',
                unit: '°C',
                id: 4
            },
            back_left_temperature: {
                value: '—',
                value_text: '',
                unit: '°C',
                id: 5
            },
            back_right_temperature: {
                value: '—',
                value_text: '',
                unit: '°C',
                id: 6
            },
            // engine_oil_temp: {
            //     value: '—',
            //     unit: '°C',
            //     id: 57
            // },
            engine_temperature: {
                value: '—',
                unit: '°C',
                id: 2
            },
            oil_level: {
                value: '—',
                value_text: '',
                unit: '%',
                id: 56
            },
            washer_fluid: {
                value: '—',
                value_text: '',
                unit: '',
                id: 35
            },
            coolant_fluid: {
                value: '—',
                value_text: '',
                unit: '',
                id: 36
            },
            // LTC_coolant_lvl: {
            //     value: '—',
            //     unit: '',
            //     id: 54
            // },
            fuel: {
                value: '—',
                value_text: '',
                unit: 'UNIT_LITER',
                id: 17
            },
            engine_time: {
                value: '—',
                value_text: '',
                unit: 'UNIT_HOUR',
                id: 130
            },
            total_mileage_am: {
                value: '—',
                value_text: '',
                unit: 'UNIT_KM',
                id: 131
            },
            total_avg_speed_am: {
                value: '—',
                value_text: '',
                unit: 'UNIT_KMH',
                id: 132
            },
            total_mins_am: {
                value: '—',
                value_text: '',
                unit: '',
                id: 133
            },
            cruising_range: {
                value: '—',
                value_text: '',
                unit: 'UNIT_KM',
                id: 134
            }
        },

        carStateMsg: '',

        carOnline: {

            status: 0,
            text: 'OFFLINE'
        },

        carConnection: null,

        connectionType: {

            type: '',
            level: 0
        }
    },

    getters: {

        getPoint:           state => state.point,

        getCarstate:        state => state.carState,
        getCarstateMsg:     state => state.carStateMsg,

        getCarOnline:       state => state.carOnline,

        getCarConnection:   state => state.carConnection,

        getConnectionType:  state => state.connectionType
    },

    actions: {

        async readPoints({state, getters}, msg) {

            // Информация для автомобиля на карте
            if(msg.p.vld) {

                state.point = msg
            }

            // Стейты автомобиля
            let value       = '',
                value_text  = '',
                states      = msg.s

            for( let carStateItem of states ) {
                
                if( carStateItem.valid === true ) {

                    if( 'value' in carStateItem ) {

                        value       = carStateItem.value
                        value_text  = carStateItem.value_text

                    } else {

                        if( ![

                            'engine_on',
                            'engine_off',
                            'start_moving',
                            'stop_moving',
                            'last_diagnostics_time',
                            'last_navigation_time',
                            'last_can_param_time'

                        ].includes(carStateItem.key) ) {

                            value = 0
                        }
                    }

                    switch(carStateItem.key) {
                        case 'high_beam_left_status':
                        case 'rear_fog_light_status':
                        case 'front_left_fog_light_status':
                        case 'front_right_fog_light_status':
                        case 'brake_light_left_status':
                        case 'brake_light_right_status':
                        case 'brake_light_HM_status':
                        case 'mil_onRq':
                        case 'mil_ind_blink':
                        case 'high_beam_right_status':
                        case 'low_beam_left_status':
                        case 'low_beam_right_status':
                        case 'reverse_light_left_status':
                        case 'reverse_light_right_status':
                        case 'position_light_RL_status':
                        case 'position_light_RR_status':
                        case 'cornering_light_left_status':
                        case 'cornering_light_right_status':
                        case 'daytime_light_left_status':
                        case 'daytime_light_right_status':
                        case 'turn_indicator_FL_status':
                        case 'turn_indicator_FR_status':
                        case 'turn_indicator_ML_status':
                        case 'turn_indicator_MR_status':
                        case 'turn_indicator_RL_status':
                        case 'turn_indicator_RR_status':
                        case 'position_light_FL_status':
                        case 'position_light_FR_status':
                        case 'head_light_leveling_ctrl_failure':
                        case 'SCU_fault_status':
                        case 'ABS_lamp':
                        case 'EBD_lamp':
                        case 'TCS_lamp':
                        case 'TPMS_sensor_fault_FL':
                        case 'TPMS_sensor_fault_FR':
                        case 'TPMS_sensor_fault_RL':
                        case 'TPMS_sensor_fault_RR':
                        case 'T15_fault_status':
                        case 'T30i_fault_status':
                        case 'T30d_fault_status':
                        case 'BCM_warning_indicator':
                        case 'DMFL_warning_indicator':
                        case 'DMFR_warning_indicator':
                        case 'DMRL_warning_indicator':
                        case 'DMRR_warning_indicator':
                        case 'ESP_lamp':
                        case 'TM_warning_indicator':
                            switch(value) {
                                case 0: value = 'No failure'
                                    break
                                case 1: value = 'Failure'
                                    break
                            }
                            break

                        case 'TPMS_battery_status_FL':
                        case 'TPMS_battery_status_FR':
                        case 'TPMS_battery_status_RL':
                        case 'TPMS_battery_status_RR':
                            switch(value) {
                                case 0: value = 'Normal'
                                    break
                                case 1: value = 'Low'
                                    break
                            }
                            break  

                        case 'SCU_calibration_status':
                            switch(value) {
                                case 0: value = 'Calibrated'
                                    break
                                case 1: value = 'Uncalibrated'
                                    break
                            }
                            break

                        case 'CCU_failure':
                            switch(value) {
                                case 0x0: value = 'No Failure'
                                    break
                                case 0x1: value = 'Full Failure'
                                    break
                                case 0x2: value = 'Failure in front zone'
                                    break
                                case 0x3: value = 'Failure in rear zone'
                                    break
                                case 0x4: value = 'Failure with pressure in the circuit'
                                    break
                                case 0x5: value = 'Failure with some sensors'
                                    break
                                case 0x6: value = 'Failure with'
                                    break
                            }
                            break

                        case 'st_transmission_warning':

                            switch(value) {
                                case 1: value = 'Overheat'
                                    break
                                case 3: value = 'Critical Failure'
                                    break
                                case 4: value = 'No Failure'
                                    break
                                case 5: value = 'Limited Functionality'
                                    break
                                case 6: value = 'Drive to stop'
                                    break
                                case 'E': value = 'Not Available'
                                    break
                                case 'F': value = 'Default'
                                    break
                            }
                            break

                        case 'status': 

                            switch(value) {
                                case 0: value = 'Deep sleep'
                                    break
                                case 1: value = 'Sleep'
                                    break
                                case 2: value = 'Standby'
                                    break
                                case 3: value = 'Ignition'
                                    break
                                case 4: value = 'Drive'
                                    break
                                case 5: value = 'Remote start'
                                    break
                                case 8: value = 'Preheating'
                                    break
                                case 9: value = 'Comfort'
                                    break
                                case 10: value = 'Multimedia'
                                    break
                                case 15: value = 'Unknown'
                                    break
                                default: value = 'Reserve'
                                    break
                            }
                            break

                        case 'ERAG_failure_status':
                            switch(value) {
                                case 0: value = 'No failure'
                                    break
                                case 1: value = 'Unavailable microphone'
                                    break
                                case 2: value = 'Unavailable speaker'
                                    break
                                case 3: value = 'Unavailable SOS button'
                                    break
                                case 4: value = 'Unavailable GPS'
                                    break
                                case 5: value = 'Unavailable GSM'
                                    break
                                case 6: value = 'Full Failure'
                                    break
                            }
                            break

                        case 'HV_system_failure':
                            switch(value) {
                                case 0: value = 'No failure'
                                    break
                                case 1: value = 'No energy source available'
                                    break
                                case 2: value = 'Warning'
                                    break
                                case 3: value = 'Error'
                                    break
                            }
                            break

                        case 'LTC_coolant_lvl':
                            switch(value) {
                                case 0: value = 'Normal'
                                    break
                                case 1: value = 'Low'
                                    break
                            }
                            break

                        case 'engine_oil_lvInd': 
                            switch(value) {
                                case 0: value_text = 'LEVEL_NORMAL'
                                    break
                                case 1: value_text = 'LEVEL_LOW'
                                    break
                                case 2: value_text = 'LEVEL_HIGH'
                                    break
                                case 3: value_text = 'LEVEL_CRITICAL_LOW'
                                    break
                            }
                            break

                        case 'oil_pressure_warning':
                            switch(value) {
                                case 0: value = 'Normal'
                                    break
                                case 1: value = 'Low'
                                    break
                            }
                            break

                        case 'engine_temperature':
                        case 'temperature_outside':
                            value = value.toFixed()
                            break

                        case 'front_inside_temperature':
                        case 'back_inside_temperature':
                            value = value.toFixed(1)
                            break

                        case 'front_left_temperature':
                        case 'front_right_temperature':
                        case 'back_left_temperature':
                        case 'back_right_temperature':
                            value = String(value.toFixed(1)).split('.')
                            break

                        case 'door_hood':
                            switch(value) {
                                case 0: value_text = getters.getTranslate.STATE_CLOSED
                                    break
                                case 1: value_text = getters.getTranslate.STATE_OPEN
                                    break
                            }
                            break

                        case 'door_front_left':
                        case 'door_front_right':
                        case 'door_back_left':
                        case 'door_back_right':
                            switch(value) {
                                case 0: value_text = getters.getTranslate.STATE_CLOSED 
                                    break
                                case 1: value_text = getters.getTranslate.STATE_OPEN
                                    break
                            }
                            break

                        case 'is_locked':
                            switch(value) {
                                case 1: value_text = getters.getTranslate.STATE_OPEN
                                    break
                                case 2: value_text = getters.getTranslate.STATE_CLOSED
                                    break
                                case 3: value_text = getters.getTranslate.STATE_LOCK_SECURE
                                    break
                                case 4: value_text = getters.getTranslate.STATE_LOCK_SECURE
                                    break
                            }
                            break

                        case 'total_mileage':
                            value = value.toFixed(1)
                            break

                        case 'total_mileage_am':
                            value = value.toFixed(1)
                            break

                        case 'esp_speed':
                            value = value.toFixed()
                            break

                        case 'fuel':
                            value = value.toFixed(1)
                            break
                            
                        case 'fuel_consumption':
                            value = value.toFixed(1)
                            break

                        case 'mileage_on_fuel_residues':
                            value = value.toFixed()
                            break

                        case 'door_trunk':
                            switch(value) {
                                case 1: value_text = getters.getTranslate.STATE_HALF_OPEN
                                    break
                                case 2: value_text = getters.getTranslate.STATE_OPEN
                                    break
                                case 3: value_text = getters.getTranslate.STATE_CLOSED
                            }
                            break

                        case 'epb_status':
                            switch(value) {
                                case 0: value = 'OFF'
                                    break
                                case 1: value = 'ON'
                                    break
                                case 2: value = 'Dynamic'
                                    break
                                case 3: value = 'Failure Indication'
                            }
                            break
                            
                        case 'battery_voltage':
                            value = value.toFixed(1)
                            break
                            
                        case 'wheel_front_left_pressure':
                            value = value.toFixed(1)
                            break
                            
                        case 'wheel_front_right_pressure':
                            value = value.toFixed(1)
                            break
                            
                        case 'wheel_back_left_pressure':
                            value = value.toFixed(1)
                            break
                            
                        case 'wheel_back_right_pressure':
                            value = value.toFixed(1)
                            break

                        case 'engine_rev':
                            value = value.toFixed()
                            break
                            
                        case 'door_front_left_closed':
                        case 'door_front_right_closed':
                        case 'door_back_left_closed':
                        case 'door_back_right_closed':
                            switch(value) {
                                case 0: value = getters.getTranslate.STATE_OPEN
                                    break
                                case 1: value = getters.getTranslate.STATE_CLOSED
                                    break
                                case 2: value = getters.getTranslate.STATE_ERROR
                                    break
                            }
                            break

                        case 'ride_mileage':
                            value = value.toFixed(1)
                            break

                        case 'ride_average_speed':
                            value = value ? value.toFixed(1) : value
                            break
                            
                        case 'ride_fuel_consumption':
                            value = value.toFixed(1)
                            break
                            
                        case 'ride_time':
                            value = parseMinutes(value)
                            break

                        case 'wheel_front_left_status':
                        case 'wheel_front_right_status':
                        case 'wheel_back_left_status':
                        case 'wheel_back_right_status':
                            switch(value) {
                                case 1: value_text ='STATE_WHEEL_PRESSURE_STATUS_HIGH'
                                    break
                                case 2: value_text ='STATE_WHEEL_PRESSURE_STATUS_NORMAL'
                                    break
                                case 3: value_text ='STATE_WHEEL_PRESSURE_STATUS_LOW'
                                    break
                                case 4: value_text ='STATE_WHEEL_PRESSURE_STATUS_CRITICAL'
                                    break
                                case 6: value_text ='STATE_WHEEL_PRESSURE_STATUS_ERROR'
                                    break
                            }
                            break
                        
                        case 'coolant_fluid': 
                            switch(value) {
                                case 0: value ='Normal'
                                    break
                                case 1: value ='Low'
                                    break
                            }
                            break

                        case 'online':
                            switch(value) {

                                case 0: 
                                    state.carOnline.text = 'OFFLINE'
                                    state.carOnline.status = 0

                                    if(carStateItem.updateAt) {

                                        state.carState.last_online.value = new Date( carStateItem.updateAt * 1000).toLocaleString()
                                    } else {
                                        
                                        state.carState.last_online.value = '—'
                                    }

                                    break

                                case 1: 
                                    state.carOnline.text = 'ONLINE'
                                    state.carOnline.status = 1

                                    state.carState.last_online.value = getters.getTranslate.STATE_ONLINE
                                    break

                                case 2: 
                                    state.carOnline.text = 'ONLINE'
                                    state.carOnline.status = 2

                                    state.carState.last_online.value = getters.getTranslate.STATE_ONLINE
                                    break
                            }
                            break

                        case 'washer_fluid':
                            switch(value) {
                                case 0: value_text ='Normal'
                                    break
                                case 1: value_text ='Low level'
                                    break
                            }
                            break

                        case 'battery_status':
                            switch(value) {
                                case 0: value_text ='Normal'
                                    break
                                case 1: value_text ='Low SOC'
                                    break
                            }
                            break

                        case 'gear_position':
                            switch(value) {
                                case 1: value = 'P'
                                    break
                                case 2: value = 'R'
                                    break
                                case 3: value = 'N'
                                    break
                                case 4: value = 'D'
                                    break
                            }
                            break

                        case 'oil_level':
                            value = Math.round(value)
                            break

                        case 'engine_on':
                        case 'engine_off':
                        case 'start_moving':
                        case 'stop_moving':
                        case 'last_diagnostics_time':
                        case 'last_navigation_time':
                        case 'last_can_param_time':
                            value = new Date(carStateItem.updateAt * 1000).toLocaleString()
                            break

                        case 'window_fully_closed_FL':
                        case 'window_fully_closed_FR':
                        case 'window_fully_closed_RL':
                        case 'window_fully_closed_RR':
                            switch(value) {
                                case 0: value_text = getters.getTranslate.STATE_NOT_CLOSED
                                    break
                                case 1: value_text = getters.getTranslate.STATE_CLOSED
                                    break
                            }
                            break

                        // Данные компонента ASConnectionType [НАЧАЛО]
                        case 'received_signal_strength_indicator':
                            state.connectionType.level = value
                            break

                        case 'mobile_network_type_indicator':
                            state.connectionType.type = value
                            break
                        // Данные компонента ASConnectionType [КОНЕЦ]

                        // case 'vehicle_secure_lock_state':
                        //     switch(value) {
                        //         case 0: value = 'Unsecure'
                        //             break
                        //         case 1: value = 'Secure'
                        //             break
                        //     }
                        //     break

                        case 'vas_activation':
                            switch(value) {
                                case 0: value = 'Unguarded'
                                    break
                                case 1: value = 'Guarded'
                                    break
                            }
                            break

                        case 'sdm_uptime':
                            value = parseMinutes(value)
                            break

                        case 'total_avg_speed_am':
                            value = value.toFixed(1)
                            break

                        case 'total_mins_am':
                            value = parseMinutes(value)
                            break
                    } 

                } else {

                    value = '—'
                }

                if(state.carState[carStateItem.key]) {

                    state.carState[carStateItem.key].value      = value
                    state.carState[carStateItem.key].value_text = value_text
                }
            }
        }
    },

    mutations: {

        clearStates(state) {

            const states = state.carState

            for(let state in states) {

                states[state].value = '—'
                states[state].value_text = '—'
            }
        },

        setCarOnline(state, data) {

            state.carOnline = data
        }
    }
}
