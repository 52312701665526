<template>
    <ADTSprite />
    
    <header class="adt-header" v-if="token">

        <div class="container-fluid adt-header__container">

            <div class="navbar navbar-expand-md adt-header__navbar">

                <div class="navbar-brand d-flex d-md-none adt-header__mobile-logo">
                    <ADTLogo />
                </div>

                <button class="navbar-toggler adt-header__mobile-btn material-icons" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    menu
                </button>

                <div class="collapse navbar-collapse adt-header__navbar-collapse" id="navbarSupportedContent">

                    <div class="adt-header__logo d-none d-md-block">
                        <ADTLogo />
                    </div>

                    <div class="adt-header__navi">
                        <ADTNavi />
                    </div>

                    <div class="adt-header__lang">
                        <ADTLanguage />
                    </div>

                    <div class="adt-header__profile">
                        <ADTHeaderProfile />
                        <a href="#" class="adt-header__profile-logout" @click.prevent="logout()">
                            <span class="material-icons adt-header__profile-logout-icon">
                                logout
                            </span>
                        </a>
                    </div>

                </div>

            </div>

        </div>
        
    </header>

    <div class="adt-main">
        <router-view />
    </div>

    <footer class="adt-footer" v-if="token">
        <ADTFooter />
    </footer>

</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import * as bootstrap from 'bootstrap' 

import ADTSprite        from '@/components/ADTSprite'
import ADTLogo          from '@/components/ADTLogo'
import ADTNavi          from '@/components/ADTNavi'
import ADTHeaderProfile from '@/components/ADTHeaderProfile'
import ADTLanguage      from '@/components/ADTLanguage'
import ADTFooter        from '@/components/ADTFooter'

export default {

    name: 'ADT',

    data() {

        return {

            showMobile: false
        }
    },

    computed: {

        ...mapState({

            token:      state => state.token.access,
            lang:       state => state.language.lang,
            httpServer: state => state.config.httpServer,
            wsServer:   state => state.config.wsServer,
        })
    },

    components: {

        ADTSprite,
        ADTLogo,
        ADTNavi,
        ADTHeaderProfile,
        ADTLanguage,
        ADTFooter
    },

    methods: {

        ...mapActions([

            'updateLanguage',
            'addToken',
            'logout'
        ]),

        ...mapMutations([

            'setHttpServer',
            'setWsServer',
            'setWsFlag'
        ]),

        mobileMenu() {

            this.showMobile = !this.showMobile
        },

        closeMobileMenu() {

            const menuMobile = document.getElementById('navbarNavAltMarkup')

            new bootstrap.Collapse(menuMobile, {

                hide: true
            })
        },

        handleVisibilityChange() {
            if (document.hidden) {
                this.setWsFlag(false)
            } else  {
                this.setWsFlag(true)
            }
        }
    },

    created() {  

        const BASE_URL = window.location.host
        const BASE_PROTOCOL = window.location.protocol

        if(process.env.NODE_ENV == 'development') {

            this.setHttpServer(`https://sky.shatl-t.ru/v1`)
            this.setWsServer('wss://sky.shatl-t.ru/v1')

        } else {

            this.setHttpServer( BASE_PROTOCOL + '//' + BASE_URL + '/v1' )
            this.setWsServer( ( BASE_PROTOCOL == 'http:' ? 'ws' : 'wss') + '://' + BASE_URL + '/v1')
        }

        this.updateLanguage( localStorage.getItem('ADT_LANG') || 'RU' )

        document.getElementsByTagName('html')[0].setAttribute('lang', this.lang.toLowerCase())

        this.addToken()
    },

    mounted() {
        document.addEventListener("visibilitychange", this.handleVisibilityChange, false)
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/scss/layout.scss';
</style>