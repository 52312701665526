export default {

    state: {

        ones: {},
        onesProcess: false,
        onesMsg: null,

        one: {},
        oneUpdateProcess: false,
        oneUpdateMsg: null,

        onesCreateProcess: false,
        onesCreateMsg: null,

        oneRulesCreateProcess: false,
        oneRulesCreateMsg: null,

        oneRules: {},
        oneRulesProcess: false,
        oneRulesMsg: null,

        onesDeleteProcess: false,
        onesDeleteMsg: null,

        oneRuleDeleteProcess: false,
        oneRuleDeleteMsg: null,
    },

    actions: {

        /**
         * Создаёт новую политику
         * 
         * @param {Object} payload - объект с полями формы создания новой полдитики
         */
         async createOne({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.ruleCreateProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/policy/ones`, 
                    method: 'POST', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.onesCreateMsg = { success: true, text: getters.getTranslate.POLICY_ONES_CREATE_SUCCESS }

                } else {

                    state.onesCreateMsg = { success: false }
                    state.onesCreateMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.ruleCreateProcess = false

            } else {

                state.onesCreateMsg = { success: false, text:getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Создаёт связь одного правила с одной полтикой
         * 
         * @param {Object} payload - объект с полями формы создания связи правило-политика
         */
         async createOneRule({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.oneRulesCreateProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/policy/links`, 
                    method: 'POST', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.oneRulesCreateMsg = { success: true, text: getters.getTranslate.POLICY_ONE_RULES_CREATE_SUCCESS }

                } else {

                    state.oneRulesCreateMsg = { success: false }
                    state.oneRulesCreateMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.oneRulesCreateProcess = false

            } else {

                state.oneRulesCreateMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Получает доступные политики
         * 
         * @param {Object} payload - параметра для GET-запроса
         */
        async readOnes({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.onesProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        params.length ?
                            params += '&' + param + '=' + payload[param] :
                            params = '?' + param + '=' + payload[param]
                    }
                }

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/policy/ones${params}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    state.ones = request
                    state.onesMsg = { success: true }

                } else {

                    state.ones = {}

                    state.onesMsg = { success: false }
                    state.onesMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.onesProcess = false

            } else {

                state.onesMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Получает политику по UUID
         * 
         * @param {String} payload - UUID политики
         */
         async readOne({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.oneProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/policy/ones/${payload}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    state.one = request
                    state.oneMsg = { success: true }

                } else {

                    state.one = {}

                    state.oneMsg = { success: false }
                    state.oneMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.oneProcess = false

            } else {

                state.onesMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Получает правила политики
         * 
         * @param {String} payload - UUID политики
         */
         async readOneRules({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.oneRulesProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        params.length ?
                            params += '&' + param + '=' + payload[param] :
                            params = '?' + param + '=' + payload[param]
                    }
                }

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/policy/rules${params}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    state.oneRules = request
                    state.oneRulesMsg = { success: true }

                } else {

                    state.oneRules = {}

                    state.oneRulesMsg = { success: false }
                    state.oneRulesMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.oneRulesProcess = false

            } else {

                state.onesMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Обновляет политику
         * 
         * @param {Object} payload - объект с полями формы обновление политики
         */
         async updateOne({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.oneUpdateProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/policy/ones`, 
                    method: 'POST', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.oneUpdateMsg = { success: true, text: getters.getTranslate.POLICY_ONE_UPDATE_SUCCESS }

                } else {

                    state.oneUpdateMsg = { success: false }
                    state.oneUpdateMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.oneUpdateProcess = false

            } else {

                state.oneUpdateMsg = { success: false, text:getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Удаляет политику
         * 
         * @param {String} payload - ID политики
         */
        async deleteOnes({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.onesDeleteProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/policy/ones/${payload}`, 
                    method: 'DELETE', 
                    token
                })

                if(!request.error) {

                    state.onesDeleteMsg = { success: true, text: getters.getTranslate.POLICY_ONES_DELETE_SUCCESS }

                } else {

                    state.ones = null

                    state.onesDeleteMsg = { success: false }
                    state.onesDeleteMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.onesDeleteProcess = false

            } else {

                state.onesDeleteMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }

            return false
        },

        /**
         * Удаляет правило из политики
         * 
         * @param {Object} payload - связка правило-политика [см.swagger]
         */
         async deleteOneRule({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.oneRuleDeleteProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/policy/links`, 
                    method: 'DELETE', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.oneRuleDeleteMsg = { success: true, text: getters.getTranslate.POLICY_ONE_RULE_DELETE_SUCCESS }

                } else {

                    state.ones = null

                    state.oneRuleDeleteMsg = { success: false }
                    state.oneRuleDeleteMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.oneRuleDeleteProcess = false

            } else {

                state.oneRuleDeleteMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }

            return false
        }
    },

    mutations: {

        setOnesMsg:                 (state, data) => state.onesMsg              = data,
        setOnesCreateMsg:           (state, data) => state.onesCreateMsg        = data,
        setOnesDeleteMsg:           (state, data) => state.onesDeleteMsg        = data,

        setOne:                     (state, data) => state.one                  = data,
        setOneProcess:              (state, data) => state.oneProcess           = data,
        setOneMsg:                  (state, data) => state.oneMsg               = data,
        setOneUpdateMsg:            (state, data) => state.oneUpdateMsg         = data,
        setOneDeleteMsg:            (state, data) => state.oneDeleteMsg         = data,

        setOneRulesMsg:             (state, data) => state.oneRulesMsg          = data,

        setOneRulesCreateProcess:   (state, data) => state.oneRulesCreateProcess= data,
        setOneRulesCreateMsg:       (state, data) => state.oneRulesCreateMsg    = data,

        setOneRuleDeleteMsg:        (state, data) => state.oneRuleDeleteMsg     = data,
    }
}