export default {

    state: {

        carusers: { array: [] },
        carusersProcess: false,
        carusersMsg: null,

        caruserslinks: { array: [] },
        caruserslinksProcess: false,
        caruserslinksMsg: null,

        carusersCreateProcess: false,
        carusersCreateMsg: null,

        carusersDeleteProcess: false,
        carusersDeleteMsg: null
    },

    actions: {

        /**
         * Создаёт связь автомобиль <-> юзер
         * 
         * Нельзя устанавливать права пользователя на машины выше, чем общие права пользователя в проложениии
         * 
         * @param {Object} payload - объект с полями формы создания связи автомобиль <-> юзер
         */
        async createCarusers({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.carusersCreateProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/car_users`, 
                    method: 'POST', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.carusersCreateMsg = { success: true, text: getters.getTranslate.CARUSERS_CREATE_SUCCESS }

                } else {

                    state.carusersCreateMsg = { success: false }
                    state.carusersCreateMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.carusersCreateProcess = false

            } else {

                state.carusersCreateMsg = { success: false, text:getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Получает пользователей автомобиля
         * 
         * @param {String} payload - объект вида { vin: VIN_код_автомобиля; ...необязательные атрибуты списка с пагинацией }
         */
        async readCarusers({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.carusersProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        if(param !== 'vin') {

                            params.length ?
                                params += '&' + param + '=' + payload[param] :
                                params = '?' + param + '=' + payload[param]
                        }
                    }
                }

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/cars/${payload.vin}/users${params}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    state.carusers = request
                    state.carusersMsg = { success: true }

                } else {

                    state.carusersMsg = { success: false }
                    state.carusersMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.carusersProcess = false

            } else {

                state.carusersMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Получает связки автомобиль <-> пользователи
         * 
         * @param {String} payload - объект вида { vin_array: VIN_код_автомобиля, [см.swagger] }
         */
         async readCarusersLinks({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.caruserslinkssProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        params.length ?
                            params += '&' + param + '=' + payload[param] :
                            params = '?' + param + '=' + payload[param]
                    }
                }

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/car_users${params}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    state.caruserslinks = request
                    state.caruserslinksMsg = { success: true }

                } else {

                    if(request.error == 'ECarUserNotFound') {

                        state.caruserslinks = { array: [] }
                        state.caruserslinksMsg = { success: true }
                        return
                    }

                    state.caruserslinksMsg = { success: false }
                    state.caruserslinksMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.caruserslinksProcess = false

            } else {

                state.caruserslinksMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Удаляет связь автомобиль <-> юзер
         * 
         * @param {Object} payload - объект с полями формы создания связи автомобиль <-> юзер
         */
        async deleteCaruser({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.carusersDeleteProcess = true

                // TODO: Скорее всего, для семантики стоит поменять метод на POST 
                // В старых браузерах параметры для DELETE нельзя передавать в payload, только строкой, как в GET
                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/car_users`, 
                    method: 'DELETE', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.carusersDeleteMsg = { success: true, text: getters.getTranslate.CARUSERS_DELETE_SUCCESS }

                } else {

                    state.carusersDeleteMsg = { success: false }
                    state.carusersDeleteMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.carusersDeleteProcess = false

            } else {

                state.carusersDeleteMsg = { success: false, text:getters.getTranslate.EUnauthRequest }
            }
        }
    },

    mutations: {

        resetCarusers: (state) => state.carusers = {},

        setCarusersMsg:       (state, data) => state.carusersMsg       = data,
        setCreateCarusersMsg: (state, data) => state.carusersCreateMsg = data,
        setDeleteCarusersMsg: (state, data) => state.carusersDeleteMsg = data,

        resetUsercars(state) {

            state.carusers = { array: [] }
            state.carusersProcess = false
            state.carusersMsg = null

            state.caruserslinks = { array: [] }
            state.caruserslinksProcess = false
            state.caruserslinksMsg = null

            state.carusersCreateProcess = false
            state.carusersCreateMsg = null

            state.carusersDeleteProcess = false
            state.carusersDeleteMsg = null
        }
    }
}