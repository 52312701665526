export default {

    state: {

        favoriteCars: {

            array: []
        },
        favoriteCarsProcess: false,
        favoriteCarsMsg: null,

        favoriteCarCreateProcess: false,
        favoriteCarCreateMsg: null,

        favoriteCarDeleteProcess: false,
        favoriteCarDeleteMsg: null
    },

    actions: {

        /**
         * Создаёт новый автомобиль
         * 
         * @param {Object} payload - объект с полями формы создания нового автомобиля
         */
        async createFavoriteCar({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.favoriteCarCreateProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/cars/favorites`, 
                    method: 'POST', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.favoriteCars = request
                    state.favoriteCarCreateMsg = { success: true, text: getters.getTranslate.FAVORITE_CARS_CREATE_SUCCESS }

                } else {

                    state.favoriteCarCreateMsg = { success: false }
                    state.favoriteCarCreateMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.favoriteCarCreateProcess = false

                return request

            } else {

                state.favoriteCarCreateMsg = { success: false, text:getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Получает избранные автомобили
         * 
         * @param {String} payload - параметры запроса [см.swagger]
         */
         async readFavoriteCars({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.carsProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        if(typeof payload[param] == 'object' && payload[param].length) {

                            payload.vin_array.map( el => {

                                params.length ?
                                    params += '&' + param + '=' + el :
                                    params = '?' + param + '=' + el
                            })

                        } else {

                            params.length ?
                                params += '&' + param + '=' + payload[param] :
                                params = '?' + param + '=' + payload[param]
                        }
                    }
                }

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/cars/favorites${params}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    state.favoriteCars = request
                    state.favoriteCarsMsg = { success: true }

                    return request

                } else {

                    state.favoriteCars = { array: [] }
                    state.favoriteCarsMsg = { success: false }
                    state.favoriteCarsMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.favoriteCarsProcess = false

            } else {

                state.favoriteCarsMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }

            return false
        },

        /**
         * Удаляет автомобили
         * 
         * @param {String} payload - UUID автомобиля
         */
        async deleteFavoriteCar({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.favoriteCarDeleteProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        params.length ?
                            params += '&' + param + '=' + payload[param] :
                            params = '?' + param + '=' + payload[param]
                    }
                }

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/cars/favorites${params}`, 
                    method: 'DELETE', 
                    token
                })

                if(!request.error) {

                    state.favoriteCars = request
                    state.favoriteCarDeleteMsg = { success: true, text: getters.getTranslate.CAR_DELETE_SUCCESS } 

                } else {

                    state.favoriteCars = { array: [] }
                    state.favoriteCarDeleteMsg = { success: false }
                    state.favoriteCarDeleteMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.favoriteCarDeleteProcess = false

                return request

            } else {

                state.favoriteCarDeleteMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        }
    },

    mutations: {

        setFavoriteCarsMsg:         (state, data) => state.favoriteCarsMsg      = data,
        setDeleteFavoriteCarMsg:    (state, data) => state.favoriteCarDeleteMsg = data,
        setCreateFavoriteCarMsg:    (state, data) => state.favoriteCarCreateMsg = data,
    }
}