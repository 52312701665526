export default {

    state: {

        track:        null,
        trackPart:    null,
        trackProcess: false,
        trackMsg:     '',

        trackODM:     0,

        trackStart:   {},
        trackStop:    {}
    },

    getters: {

        getTrack:        state => state.track,
        getTrackPart:    state => state.trackPart,
        getTrackProcess: state => state.trackProcess,
        getTrackMsg:     state => state.trackMsg,

        getTrackODM:     state => state.trackODM,

        getTrackStart:   state => state.trackStart,
        getTrackStop:    state => state.trackStop,
    },

    actions: {

        async readTrack({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access
            
            if(token) {

                state.trackProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        params.length ?
                            params += '&' + param + '=' + payload[param] :
                            params = '?' + param + '=' + payload[param]
                    }
                }

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/points/track${params}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    state.trackPart = request
                    state.trackMsg = { success: true, text: '' }

                } else {

                    state.trackMsg = { success: false }
                    state.trackMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.trackProcess = false

            } else {

                state.trackMsg = { success: false, text:getters.getTranslate.EUnauthRequest }
            }
        }
    },

    mutations: {

        setTrack:      (state, data) => state.track      = data,
        setTrackPart:  (state, data) => state.trackPart  = data,
        setTrackMsg:   (state, data) => state.trackMsg   = data,

        setTrackODM:   (state, data) => state.trackODM   = data,

        setTrackStart: (state, data) => state.trackStart = data,
        setTrackStop:  (state, data) => state.trackStop  = data
    }
}