export default {

    state: {

        usercars: {

            array: []
        },
        usercarsProcess: false,
        usercarsMsg: null,

        usercarslinks: {},
        usercarslinksProcess: false,
        usercarslinksMsg: null,

        usercarDeleteProcess: false,
        usercarDeleteMsg: null
    },

    actions: {

        /**
         * Получает пользователей автомобиля
         * 
         * @param {String} payload - объект вида { vin: VIN_код_автомобиля; ...необязательные атрибуты списка с пагинацией }
         */
        async readUsercars({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.usercarsProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        if(param !== 'uuid') {

                            params.length ?
                                params += '&' + param + '=' + payload[param] :
                                params = '?' + param + '=' + payload[param]
                        }
                    }
                }

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/users/${payload.uuid}/cars${params}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    state.usercars = request
                    state.usercarsMsg = { success: true }

                } else {

                    state.usercars = { array: [] }
                    state.usercarsMsg = { success: false }
                    state.usercarsMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.usercarsProcess = false

                return request

            } else {

                state.usercarsMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Получает связки пользователель <-> автомобили
         * 
         * @param {String} payload - объект вида { uuid: UUID_пользователя, [см.swagger] }
         */
         async readUsercarsLinks({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.usercarslinkssProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        params.length ?
                            params += '&' + param + '=' + payload[param] :
                            params = '?' + param + '=' + payload[param]
                    }
                }

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/car_users${params}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    state.usercarslinks = request
                    state.usercarslinksMsg = { success: true }

                } else {

                    if(request.error == 'ECarUserNotFound') {

                        state.usercarslinks = { array: [] }
                        state.usercarslinksMsg = { success: true }
                    }

                    state.usercarslinksMsg = { success: false }
                    state.usercarslinksMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.usercarslinksProcess = false

            } else {

                state.usercarslinksMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Удаляет связь пользователь-автомобили
         * 
         * @param {Object} payload - объект вида [{ vin: vin_автомобиля, uuid: uuid_пользователя, access: "no_access" }, ... ]
         */
         async deleteUsercar({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.usercarDeleteProcess = true

                // TODO: Скорее всего, для семантики стоит поменять метод на POST 
                // В старых браузерах параметры для DELETE нельзя передавать в payload, только строкой, как в GET
                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/car_users`, 
                    method: 'DELETE', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.usercarDeleteMsg = { success: true, text: getters.getTranslate.USER_CARS_DELETE_SUCCESS }

                } else {

                    state.usercarDeleteMsg = { success: false }
                    state.usercarDeleteMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.usercarDeleteProcess = false

            } else {

                state.usercarDeleteMsg = { success: false, text:getters.getTranslate.EUnauthRequest }
            }
        }
    },

    mutations: {

        setUsercars:            (state)       => state.usercars             = {},
        setUsercarsMsg:         (state, data) => state.usercarsMsg          = data,
        setDeleteUserCarMsg:    (state, data) => state.usercarDeleteMsg     = data
    }
}