export default {

    state: {

        captcha: null,
        captchaProcess: false,
        captchaMsg: null,

        auth: false,
        authProcess: false,
        authMsg: null,

        requestCodeProcess: false,
        requestCodeMsg: null
    },

    actions: {

        /**
         * Отправляет запрос на авторизацию
         * 
         * @param {Object} payload - объект со значениями полей формы авторизации
         */
        async sendAuth ({ rootState, state, getters, dispatch }, payload) {

            state.authProcess = true

            const request = await dispatch('fetchRequest', {
                address: `${ rootState.config.httpServer}/auth/login`, 
                method: 'POST', 
                data: payload
            })

            if(!request.error) {

                state.auth = true
                state.authMsg = { success: true }

                localStorage.setItem('ADT_UUID', request.user.uuid)

                dispatch('addToken', { access: request.tokens.access, refresh: request.tokens.refresh })
                dispatch('readProfile')

            } else {

                state.auth = false
                state.authMsg = { success: false }
                state.authMsg.text = getters.getTranslate[request.error] || request.error
            }

            state.authProcess = false
        },

        /**
         * Отправляет запрос на получения одноразового пароля
         * 
         * @param {Object} payload - объект со значениями полей формы запроса кода авторизации для одноразового входа
         */
        async sendRequestCode ({ rootState, state, getters, dispatch }, payload) {

            state.requestCodeProcess = true

            const request = await dispatch('fetchRequest', {
                address: `${rootState.config.httpServer}/auth/send_code`, 
                method: 'POST', 
                data: payload
            })

            if(!request.error) {

                state.requestCodeMsg = { success: true }

            } else {

                state.requestCodeMsg = { success: false }
                state.requestCodeMsg.text = getters.getTranslate[request.error] || request.error
            }

            state.requestCodeProcess = false
        },

        /**
         * Отправляет запрос на получение captcha
         */
        async readCaptcha ({ rootState, state, getters, dispatch }) {

            state.captcha = null
            state.captchaProcess = true
            state.captchaMsg = null

            let lang = rootState.language.lang

            const request = await dispatch('fetchRequest', {
                address: `${rootState.config.httpServer}/auth/captcha?lang=${lang}`,
                method: 'GET'
            })

            if(!request.error) {

                state.captcha = { id: request.id, value: request.b64s, sound: request.wavS }
                state.captchaMsg = { success: true }

            } else {

                state.captcha = null
                state.captchaMsg = { success: false }
                state.captchaMsg.text = getters.getTranslate[request.error] || request.error
            }

            state.captchaProcess = false
        }
    },

    mutations: {

        setAuthMsg(state, data) {

            state.authMsg = data
        },

        setRequestCode(state, data) {

            state.singleAuthMsg = data
        }
    }
}
