export default {

    state: {

        users: {

            array: []
        },
        usersProcess: false,
        usersMsg: null,

        user: {},
        userProcess: false,
        userMsg: null,

        userCreateProcess: false,
        userCreateMsg: null,

        userUpdateProcess: false,
        userUpdateMsg: null,

        userDeleteProcess: false,
        userDeleteMsg: null
    },

    actions: {

        /**
         * Создаёт нового пользователя
         * 
         * @param {Object} payload - объект с полями формы создания нового пользователя
         */
        async createUser({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.userCreateProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/users`, 
                    method: 'POST', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.userCreateMsg = { success: true, text: getters.getTranslate.USER_CREATE_SUCCESS }

                } else {

                    state.userCreateMsg = { success: false }
                    state.userCreateMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.userCreateProcess = false

            } else {

                state.userCreateMsg = { success: false, text:getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Получает пользователей
         * 
         * @param {String} payload - параметры запроса [см.swagger]
         */
        async readUsers({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.usersProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        params.length ?
                            params += '&' + param + '=' + payload[param] :
                            params = '?' + param + '=' + payload[param]
                    }
                }

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/users${params}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    state.users = request
                    state.usersMsg = { success: true }

                } else {

                    state.users = { array: [] }
                    state.usersMsg = { success: false }
                    state.usersMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.usersProcess = false

            } else {

                state.usersMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Получает пользователя по UUID
         * 
         * @param {String} payload - UUID пользователя в системе
         */
        async readUser({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.userProcess = true

                let params = ''

                if(payload) params = '/' + payload

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/users${params}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    state.user = request
                    state.userMsg = { success: true }

                } else {

                    state.userMsg = { success: false }
                    state.userMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.userProcess = false

            } else {

                state.userMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Обновляет профиль пользователя
         * 
         * @param {Object} payload - объект с полями формы профиля пользователя
         */
        async updateUser({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.userUpdateProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/users`, 
                    method: 'PUT', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.userUpdateMsg = { success: true, text: getters.getTranslate.USER_UPDATE_SUCCESS }

                } else {

                    state.userUpdateMsg = { success: false }
                    state.userUpdateMsg.text = getters.getTranslate[request.error] || request.error
                }
            
                state.userUpdateProcess = false
                
            } else {

                state.userUpdateMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Удаление пользователя
         * 
         * @param {Object} payload - ID пользователя
         */
        async deleteUser ({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.userDeleteProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/users/${payload}`, 
                    method: 'DELETE', 
                    token
                })

                if(!request.error) {

                    state.userDeleteMsg = { success: true, text: getters.getTranslate.USER_DELETE_SUCCESS }
                    dispatch('readUsers')

                } else {

                    state.userDeleteMsg = { success: false }
                    state.userDeleteMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.userDeleteProcess = false

            } else {

                state.userDeleteMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        }
    },

    mutations: {

        setCreateUserMsg: (state, data) => state.userCreateMsg = data,
        setUsersMsg:      (state, data) => state.usersMsg      = data,
        setUser:          (state, data) => state.user          = data,
        setUserMsg:       (state, data) => state.userMsg       = data,
        setUpdateUserMsg: (state, data) => state.userUpdateMsg = data,
        setDeleteUserMsg: (state, data) => state.userDeleteMsg = data
    }
}