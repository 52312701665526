export default {

    state: {

        access: null,
        refreshPromise: null
    },

    actions: {

        /**
         * Добавляет токены, полученные в случае успешной авторизации
         * 
         * @param {Object} payload - { access: 'токен для запросов', refresh: 'токен для обновления access' }
         */
        addToken ({state}, payload) {

            if(payload) {

                state.access    = payload.access

                localStorage.setItem('ADT_TOKEN_ACCESS', payload.access)
                localStorage.setItem('ADT_TOKEN_REFRESH', payload.refresh)

            } else if(localStorage.getItem('ADT_TOKEN_ACCESS') && localStorage.getItem('ADT_TOKEN_REFRESH')) {
                state.access    = localStorage.getItem('ADT_TOKEN_ACCESS')

            } else {
                state.access    = null
            }
        },

        async refreshToken({rootState, state, dispatch, commit}) {

            const refresh = localStorage.getItem('ADT_TOKEN_REFRESH')

            const request = await dispatch('fetchRequestWoToken', {
                address: `${rootState.config.httpServer}/auth/refresh_tokens`, 
                method: 'POST', 
                data: `"${refresh}"`,
                type: 'string'
            })

            if(request.tokens && request.user) {

                state.access = request.tokens.access

                commit('setProfile', request.user)

                localStorage.setItem('ADT_TOKEN_ACCESS', request.tokens.access)
                localStorage.setItem('ADT_TOKEN_REFRESH', request.tokens.refresh)
                localStorage.setItem('ADT_UUID', request.user.uuid)

                return request

            } else {

                state.access = null

                dispatch('logout')
            }
        }
    },

    mutations: {

        setRefreshPromise(state, val) {

            state.refreshPromise = val
        }
    }
}