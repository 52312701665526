import { createStore } from 'vuex'

import config           from '@/store/modules/config.js'            // Конфигурация приложения
import language         from '@/store/modules/language.js'          // Язык интерфейса
import translater       from '@/store/modules/translater.js'        // Языковые константы для локализации
import requests         from '@/store/modules/requests.js'          // Работа с запросами к API
import auth             from '@/store/modules/auth.js'              // Работа с авторизацией
import token            from '@/store/modules/token.js'             // Token запросов после авторизации
import profile          from '@/store/modules/profile.js'           // Профиль авторизованного пользователя
import users            from '@/store/modules/users.js'             // Пользователи
import cars             from '@/store/modules/cars.js'              // Автомобили
import carusers         from '@/store/modules/carusers.js'          // Пользователи автомобиля
import usercars         from '@/store/modules/usercars.js'          // Автомобили пользователя
import usercarscreate   from '@/store/modules/usercarscreate.js'    // Управление автомобилями пользователя
import carinfo          from '@/store/modules/carinfo.js'           // Информация о выбранном автомобиле
import ecus             from '@/store/modules/ecus'                 // Электронные блоки автомобиля
import events           from '@/store/modules/events.js'            // Зарегистрирванные события
import points           from '@/store/modules/points.js'            // Оперативная информация по автомобилю (координаты, показания приборов, датчики...)
import cmd              from '@/store/modules/cmd.js'               // Отправка команд (открыть/закрыть дверь, старт/стоп двигатель)
import track            from '@/store/modules/track.js'             // Маршрут автомобиля за выбранный промежуток времени
import egtsFirmware     from '@/store/modules/egtsFirmware.js'      // Прошивки электронных блоков
import egtsModule       from '@/store/modules/egtsModule.js'        // Информация о модуме SDM автомобиля
import diag             from '@/store/modules/diag.js'              // Диагностика ЭБУ автомобиля
import chartHistory     from '@/store/modules/chartHistory.js'      // Архивные графики (записи показаний приборов и датчиков)
import chartOnline      from '@/store/modules/chartOnline.js'       // Графики онлайн
import ws               from '@/store/modules/ws.js'                // Работа с WebSocket
import logs             from '@/store/modules/logs.js'              // Логирование действий пользователей
import carColors        from '@/store/modules/carColors.js'         // Цвета кузовов автомобилей
import favoriteCars     from '@/store/modules/favoriteCars.js'      // Избранные автомобиле на главном экране
import policyRules      from '@/store/modules/policy/policyRules.js'// Управление доступом: правила
import policyOnes       from '@/store/modules/policy/policyOnes.js' // Управление доступом: политики
import policySets       from '@/store/modules/policy/policySets.js' // Управление доступом: наборы
import policyUsers      from '@/store/modules/policy/policyUsers.js' // Управление доступом: пользователи
import testform         from '@/store/modules/testform.js'          // Форма тестовых запросов

export default createStore({

    modules: {

        config,
        language,
        translater,
        requests,
        auth,
        token,
        profile,
        users,
        cars,
        carusers,
        usercars,
        usercarscreate,
        carinfo,
        ecus,
        events,
        points,
        cmd,
        track,
        egtsFirmware,
        egtsModule,
        diag,
        chartHistory,
        chartOnline,
        ws,
        logs,
        carColors,
        favoriteCars,
        policyRules,
        policyOnes,
        policySets,
        policyUsers,
        testform
    }
})
