<template>
    <svg style="display: none;">
        <symbol id="icon_map_fullscreen" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="39" height="39" rx="7.5" fill="var(--white)" stroke="var(--gold)"/>
            <path d="M9 23V28.7778C9 29.9923 10.0077 31 11.2222 31H17V28.7778H11.2222V23H9Z" fill="var(--black)"/>
            <path d="M9 17V11.2222C9 10.0077 10.0077 9 11.2222 9H17V11.2222H11.2222V17H9Z" fill="var(--black)"/>
            <path d="M31 23V28.7778C31 29.9923 29.9923 31 28.7778 31H23V28.7778H28.7778V23H31Z" fill="var(--black)"/>
            <path d="M31 17V11.2222C31 10.0077 29.9923 9 28.7778 9H23V11.2222H28.7778V17H31Z" fill="var(--black)"/>
        </symbol>

        <symbol id="icon_map_fullscreen_exit" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="39" height="39" rx="7.5" fill="var(--white)" stroke="var(--gold)"/>
            <path d="M17 31V25.2222C17 24.0077 15.9923 23 14.7778 23H9V25.2222H14.7778V31H17Z" fill="var(--black)"/>
            <path d="M17 9V14.7778C17 15.9923 15.9923 17 14.7778 17H9V14.7778H14.7778V9H17Z" fill="var(--black)"/>
            <path d="M23 31V25.2222C23 24.0077 24.0077 23 25.2222 23H31V25.2222H25.2222V31H23Z" fill="var(--black)"/>
            <path d="M23 9V14.7778C23 15.9923 24.0077 17 25.2222 17H31V14.7778H25.2222V9H23Z" fill="var(--black)"/>
        </symbol>

        <symbol id="icon_map_layers" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="39" height="39" rx="7.5"  fill="var(--white)" stroke="var(--gold)"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.38922 17.4665C8.90948 17.0667 8.90948 16.3299 9.38922 15.9301L19.8272 7.23178C20.1981 6.92274 20.7366 6.92274 21.1075 7.23178L31.5455 15.9301C32.0252 16.3299 32.0252 17.0667 31.5455 17.4665L21.1075 26.1648C20.7366 26.4738 20.1981 26.4738 19.8272 26.1648L9.38922 17.4665ZM20.4673 23.5L12.5 16.6983L20.4673 10L28 16.6983L20.4673 23.5Z" fill="var(--black)"/>
            <path d="M8.29291 23.3727C7.85907 23.8065 7.91461 24.5252 8.41009 24.8872L19.862 33.2559C20.2208 33.5181 20.7094 33.5121 21.0617 33.2411L32.5646 24.3927C33.043 24.0246 33.0888 23.3198 32.662 22.893L32.5646 22.7932C32.2089 22.4374 31.6442 22.4014 31.2462 22.709L21.1996 30.4826C20.848 30.7542 20.3594 30.7613 20 30.5L9.79529 23C9.39734 22.7106 8.79802 22.8521 8.45 23.2L8.29291 23.3727Z" fill="var(--black)"/>
        </symbol>

        <symbol id="icon_map_track" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="39" height="39" rx="7.5" fill="var(--white)" stroke="var(--gold)"/>
            <ellipse cx="10.4194" cy="27.6" rx="2.4" ry="2.41935" transform="rotate(-90 10.4194 27.6)" fill="var(--black)"/>
            <path d="M10.4194 27.2002C10.285 24.8002 10.4538 22.2299 10.8227 20.4002C11.6561 16.2654 16.4678 16.0002 18.484 18.0002C20.4646 19.9648 21.3066 26.2002 24.1292 26.8002C26.0109 27.2002 30.9839 27.2002 29.7742 12.4002" stroke="var(--black)" stroke-width="2"/>
            <path d="M29.6645 9.50326C29.8241 9.26385 30.1759 9.26385 30.3355 9.50326L33.5821 14.3731C33.7607 14.6411 33.5686 15 33.2466 15H26.7534C26.4314 15 26.2393 14.6411 26.4179 14.3731L29.6645 9.50326Z" fill="var(--black)"/>
        </symbol>

        <symbol id="icon_link_bin" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.0286 4.29744H1.9715C1.86268 4.28869 1.75487 4.32275 1.67336 4.39163C1.59322 4.4646 1.55606 4.57025 1.57398 4.6742L2.60752 13.208C2.72871 14.1488 3.57521 14.8539 4.57522 14.8469H9.58392C10.6121 14.8608 11.4781 14.122 11.5715 13.1514L12.4262 4.63653C12.4315 4.54519 12.3953 4.45606 12.3268 4.39163C12.2453 4.32275 12.1374 4.28869 12.0286 4.29744H12.0286ZM10.7765 13.1138C10.714 13.6874 10.1919 14.1163 9.58392 14.0934H4.57522C3.97818 14.1163 3.46496 13.6959 3.40255 13.1326L2.40876 5.05097H11.5914L10.7765 13.1138ZM13.4796 1.84846H9.38516V1.24563C9.39613 0.956076 9.28096 0.674986 9.06669 0.468333C8.85241 0.261678 8.55789 0.147652 8.25224 0.153013H5.74789C5.44224 0.147653 5.14772 0.261679 4.93345 0.468333C4.71917 0.674988 4.604 0.956076 4.61497 1.24563V1.84846H0.520563C0.301021 1.84846 0.123047 2.01714 0.123047 2.22523C0.123047 2.43331 0.30102 2.60199 0.520563 2.60199H13.4796C13.6991 2.60199 13.8771 2.43331 13.8771 2.22523C13.8771 2.01714 13.6991 1.84846 13.4796 1.84846ZM5.41001 1.84846V1.24563C5.39785 1.15601 5.42876 1.06594 5.49422 1.00025C5.55969 0.934551 5.65279 0.900159 5.74789 0.906544H8.25224C8.34734 0.900159 8.44045 0.934551 8.50591 1.00025C8.57137 1.06594 8.60229 1.15601 8.59013 1.24563V1.84846H5.41001Z" fill="#C8C9C7"/>
        </symbol>

        <symbol id="icon_link_ok" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="2.26471" width="12.2353" height="12.2353" rx="1.5" stroke="#C8C9C7"/>
            <rect x="2.26465" y="0.5" width="12.2353" height="12.2353" rx="1.5" fill="#FAFAFA" stroke="#C8C9C7"/>
            <path d="M7.4461 8.88497L4.2119 5.77259C4.0598 5.61895 3.82282 5.16603 4.21188 4.77306C4.51024 4.47169 4.9054 4.61969 5.05776 4.77307L7.64411 7.37426L12.0038 3.14732C12.1561 2.99394 12.6167 2.91206 12.8496 3.14734C13.0825 3.38262 13.0149 3.79762 12.8496 3.97955L7.99758 8.88497C7.84522 9.03835 7.59846 9.03834 7.4461 8.88497Z" fill="#C8C9C7"/>
        </symbol>

        <symbol id="icon_btn_email" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.1599 7.62571V7.16663H14.3999V9.62948V10.1666H15.0004H15.3599H15.9999H16.0618L15.9999 10.2408L15.3599 11.008L14.3933 12.1666L13.8372 12.8333L13.2799 13.5013L12.7227 12.8333L12.1665 12.1666L10.9587 10.7187L10.498 10.1666H12.1599V7.62571ZM13.7599 7.83329V9.05697V10.8333H14.658L13.546 12.1666L13.2799 12.4856L13.0138 12.1666L11.9018 10.8333H12.7999V8.19821V7.83329H13.7599Z" fill="#E8E8E8"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.333252 0.166626V12.1666L10.6666 12.1666V11.5H0.973254V10.6712L5.32992 6.8079C5.49173 6.95477 5.76582 7.20365 6.01015 7.42552L6.02093 7.43531L6.02493 7.43895L6.02554 7.4395L6.02811 7.44183L6.02859 7.44227L6.03569 7.44872L6.05911 7.46998C6.28488 7.67499 6.47575 7.84831 6.51304 7.88212C7.1268 8.43879 7.75768 8.49931 7.99992 8.49931C8.2428 8.49931 8.87335 8.43944 9.4868 7.88277L9.52222 7.85063C9.69615 7.69283 10.3574 7.09286 10.6699 6.8092L11.1605 6.36324C11.5074 6.04837 12.182 5.31404 12.8929 4.54023C13.6844 3.67874 14.4999 2.83329 15.0266 2.33329V1.33329C13.4999 2.99996 9.83325 6.8092 9.06742 7.38082C8.61686 7.78948 8.1708 7.83394 7.99992 7.83394C7.82936 7.83394 7.3833 7.78982 6.93242 7.38082C6.73046 7.19764 5.63759 6.14413 4.43588 4.9857C2.97189 3.57443 1.34635 2.00743 0.973252 1.66858V0.833292H15.0266V0.166626H0.333252ZM0.973252 9.82157L4.83867 6.36194C4.07742 5.67092 3.30739 4.88362 2.59961 4.15996C1.99797 3.54482 1.44131 2.97567 0.973252 2.55074V9.82157Z" fill="#E8E8E8"/>
            <path d="M15.6666 0.166626H15.0266V0.833292V1.33329V6.49996H15.6666L15.6666 0.166626Z" fill="#E8E8E8"/>
        </symbol>

        <symbol id="icon_btn_pdf" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.740234 0.818115V15.4528H10.0552L9.49898 14.8165H1.38023V1.45441H7.46023V5.58906H11.6202V9.40806H12.2602V5.32435C12.2659 5.29 12.2659 5.25494 12.2602 5.22058V5.13359L7.90586 0.818115H0.740234ZM8.10023 1.89869L11.1615 4.95277H8.10023V1.89869ZM4.04148 8.29206C3.7858 8.29206 3.54644 8.31901 3.36148 8.36228V11.9532H3.93773V10.5303C3.99213 10.536 4.04164 10.5365 4.10148 10.5365C4.41156 10.5365 4.76513 10.4116 4.98273 10.1574C5.15137 9.96273 5.25461 9.71432 5.25461 9.34654C5.25461 9.03317 5.1567 8.75184 4.96086 8.56795C4.74294 8.36243 4.42773 8.29206 4.04149 8.29206H4.04148ZM6.35898 8.29206C6.109 8.29206 5.87571 8.31909 5.70711 8.36228V11.942C5.83767 11.9637 6.03895 11.9799 6.24023 11.9799C6.71319 11.9799 7.07231 11.8609 7.31711 11.6121C7.59455 11.342 7.79023 10.8497 7.79023 10.0655C7.79023 9.33533 7.60538 8.85969 7.30586 8.59467C7.08826 8.39456 6.7833 8.29206 6.35898 8.29206L6.35898 8.29206ZM8.26273 8.30822V11.9532H8.84461V10.3799H9.70961V9.89832H8.84461V8.81091H9.76961V8.30822H8.26273ZM6.45148 8.75685C6.9574 8.75685 7.19086 9.23301 7.19086 10.0605C7.19086 11.1689 6.82622 11.5046 6.42398 11.5046C6.3859 11.5046 6.33169 11.5042 6.28273 11.4934V8.77363C6.33169 8.76281 6.39164 8.75685 6.45148 8.75685ZM4.13898 8.76244C4.53066 8.76244 4.67211 9.049 4.67211 9.38941C4.67211 9.80618 4.46041 10.0655 4.07961 10.0655C4.01977 10.0655 3.98125 10.0657 3.93773 10.0549V8.78916C3.98669 8.77294 4.05738 8.76244 4.13898 8.76244ZM10.6602 10.0443V12.9077H9.06023L11.8421 16.0904L14.5002 12.9077H12.9002V10.0443H10.6602ZM11.3002 10.6806H12.2602V13.544H13.2202L11.8421 15.1347L10.3402 13.544H11.3002V10.6806Z" fill="#E8E8E8"/>
        </symbol>

        <symbol id="icon_tick" viewBox="0 0 12 8" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.99991 5.07095L2.17891 1.24995C1.76491 0.835953 1.09291 0.835953 0.678908 1.24995C0.264908 1.66395 0.264908 2.33595 0.678908 2.74995L5.29291 7.36395C5.68391 7.75495 6.31691 7.75495 6.70691 7.36395L11.3209 2.74995C11.7349 2.33595 11.7349 1.66395 11.3209 1.24995C10.9069 0.835953 10.2349 0.835953 9.82091 1.24995L5.99991 5.07095Z" />
        </symbol>

        <symbol id="icon_bin" viewBox="0 0 15 18" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.5 6V16H3.5V6H11.5ZM10 0H5L4 1H0.5V3H14.5V1H11L10 0ZM13.5 4H1.5V16C1.5 17.1 2.4 18 3.5 18H11.5C12.6 18 13.5 17.1 13.5 16V4Z" fill="#AD7C59"/>
        </symbol>
        
        <symbol id="icon_map_toggle" width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.19204 13.8921C9.59146 14.4398 10.4085 14.4398 10.808 13.8921L19.2333 2.33923C19.7152 1.67841 19.2432 0.75 18.4253 0.75L1.57466 0.75C0.756772 0.75 0.284771 1.67841 0.7667 2.33923L9.19204 13.8921Z" fill="#D2D2D2"/>
        </symbol>

        <symbol id="icon_auth_refresh" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 512 512">
            <g>
                <g>
                    <path d="m404.5,62.6l24.8-3.1c11.1-1.4 19-11.5 17.7-22.7-1.3-10.3-9.7-18.7-22.7-17.7l-74.6,9.3c-11.1,1.4-19,11.5-17.7,22.6l9.2,75.6c1.4,11.1 11.5,19.1 22.6,17.7 11.1-1.4 19.1-11.5 17.7-22.7l-3.4-28.1c70.1,52 99.2,142.2 72.3,225.7-26.9,83.5-105.9,140.8-193.2,141-12.9,0-21.1,8.6-21.4,19.8-0.2,11.2 7.8,20.8 21.4,20.8 104.8,0 199.7-68.9 231.9-169.2 32-99.1-2-206.4-84.6-269v-2.13163e-14z"/>
                    <path d="m170.7,385.3c-1.4-11.1-11.5-19.1-22.6-17.7-11.1,1.4-19.1,11.5-17.7,22.7l3.4,28.1c-70.1-52-99.2-142.2-72.3-225.7 26.9-83.5 105.9-140.8 193.2-141 12.9,0 21.1-8.6 21.4-19.8 0.2-11.2-7.8-20.8-21.4-20.8-104.7-0.1-199.6,68.8-231.8,169.1-32,99.3 2,206.6 84.6,269.2l-24.8,3.1c-11.1,1.4-19,11.5-17.7,22.7 1.3,10.3 9.7,18.7 22.7,17.7l74.6-9.3c11.1-1.4 19-11.5 17.7-22.6l-9.3-75.7z"/>
                </g>
            </g>
        </symbol>

        <symbol id="icon_auth_sound" width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3692_13995)">
                <path d="M2.83325 14.6667V20.3334C2.83325 23.1667 4.24992 23.7334 7.08325 23.7334H9.10909C9.63325 23.7334 10.1574 23.8892 10.6108 24.1584L14.7474 27.6009C18.3174 29.8392 21.2499 28.21 21.2499 24.0025V10.9975C21.2499 6.77586 18.3174 5.16086 14.7474 7.39919L10.6108 10.8417C10.1574 11.1109 9.63325 11.2667 9.10909 11.2667H7.08325C4.24992 11.2667 2.83325 11.8334 2.83325 14.6667Z" stroke="#D2D2D2" stroke-width="2.55"/>
                <path d="M25.5 11.8333C28.0217 15.1908 28.0217 19.8092 25.5 23.1667" stroke="#D2D2D2" stroke-width="2.55" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M28.9 8.29166C32.9942 13.7458 32.9942 21.2542 28.9 26.7083" stroke="#D2D2D2" stroke-width="2.55" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_3692_13995">
                    <rect width="34" height="34" fill="white" transform="translate(0 0.499985)"/>
                </clipPath>
            </defs>
        </symbol>
        
        <symbol id="icon_arrow_right" viewBox="0 0 27 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.7071 8.70711C26.0976 8.31658 26.0976 7.68342 25.7071 7.2929L19.3431 0.928934C18.9526 0.538409 18.3195 0.538409 17.9289 0.928934C17.5384 1.31946 17.5384 1.95262 17.9289 2.34315L23.5858 8L17.9289 13.6569C17.5384 14.0474 17.5384 14.6805 17.9289 15.0711C18.3195 15.4616 18.9526 15.4616 19.3431 15.0711L25.7071 8.70711ZM1 9L25 9L25 7L1 7L1 9Z"/>
        </symbol>
    </svg>
</template>