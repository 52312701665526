export default {

    state: {

        ws: {},
        wsMsg: '',
        wsRestore: false,
        wsFlag: false
    },

    actions: {

        async openWs({rootState, state, getters, dispatch}, payload) {

            if(typeof WebSocket !== 'undefined') {

                if(rootState.token.access) {

                    let params = ''

                    if(payload) {

                        for( let param in payload) {

                            if(param == 'vin_array') {

                                payload.vin_array.map( vin => {

                                    params.length ?
                                        params += '&vin_array=' + vin :
                                        params = '?vin_array=' + vin
                                })
                                
                            } else {

                                params.length ?
                                    params += '&' + param + '=' + payload[param] :
                                    params = '?' + param + '=' + payload[param]
                            }
                        }
                    }

                    (function connect() {

                        state.ws = new WebSocket(
                            `${rootState.config.wsServer}/points/ws${params}`, 
                            ['Bearer', rootState.token.access]
                        )

                        state.ws.onopen = () => {

                            if(state.wsRestore) {

                                clearInterval(state.wsRestore)
                                state.wsRestore = false
                            }

                            state.wsMsg = { success: true }
                        }

                        state.ws.onmessage = msg => {

                            msg = JSON.parse(msg.data)

                            if( 'result' in msg ) {

                                state.wsMsg = { success: true, text: '' }
                                dispatch('readPoints', msg.result)

                            } else if(msg?.error?.details[0]?.value === 'ETokenExpired' && state.wsFlag) {

                                dispatch('readProfile').then(res => {
                                    if(res.uuid) {
                                        if(!state.wsRestore) state.wsRestore = setInterval( () => connect(), 5000 )
                                        return 
                                    } else {
                                        state.wsMsg = { success: true, text: 'ETokenError' }
                                        return 
                                    }
                                })

                                // Тестирование восстановления токена при работе с WS
                                // dispatch('refreshToken').then(tokens => {

                                //     if(tokens.access) {

                                //         if(!state.wsRestore) state.wsRestore = setInterval( () => connect(), 5000 )
                                //         return 

                                //     } else {

                                //         state.wsMsg = { success: true, text: 'ETokenError' }
                                //         return 
                                //     }
                                // })
                            }
                        }

                        state.ws.onerror = () => {

                            state.ws.close()

                            state.wsMsg = { success: false, text: getters.getTranslate.EWSDisconnected }

                            if(!state.wsRestore) state.wsRestore = setInterval( () => connect(), 5000 )
                        }

                        state.ws.onclose = msg => {

                            if(msg.wasClean) {

                                if(state.wsRestore) {

                                    clearInterval(state.wsRestore)
                                    state.wsRestore = false
                                }

                                return
                            }

                            if(!state.wsRestore) state.wsRestore = setInterval( () => connect(), 5000 )
                        }
                    })()

                } else {

                    state.wsMsg = { success: false, text: 'EUnauthRequest' }
                }

            } else {

                state.wsMsg = { success: false, text: 'EWSNotSuport' }
            }
        }
    },

    mutations: {

        setWsRestore: (state, data) => state.wsRestore = data,
        setWsFlag: (state, data) => state.wsFlag = data
    }
}
