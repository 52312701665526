export default {

    state: {

        ecus: {

            SDM: {

                info: {

                    calibration: null,
                    fullName: 'Sky Diagnostic Module',
                    fw: null,
                    hw: null,
                    mileage: null,
                    name: 'SDM',
                    sn: null,
                    soft: null,
                    timeStamp: null,
                    vin: null
                },

                report: {

                    status: null,
                    val: []
                },

                installed: false
            },

            CGW: {

                info: {

                    calibration: null,
                    fullName: 'Central Gateway',
                    fw: null,
                    hw: null,
                    mileage: null,
                    name: 'CGW',
                    sn: null,
                    soft: null,
                    timeStamp: null,
                    vin: null
                },

                report: {

                    status: null,
                    val: []
                },

                installed: false
            },

            DMFR: {

                info: {

                    calibration: null,
                    fullName: 'Door Module Front Right',
                    fw: null,
                    hw: null,
                    mileage: null,
                    name: 'DMFR',
                    sn: null,
                    soft: null,
                    timeStamp: null,
                    vin: null
                },

                report: {

                    status: null,
                    val: []
                },

                installed: false
            },

            BCM: {

                info: {

                    calibration: null,
                    fullName: 'Body Control Module',
                    fw: null,
                    hw: null,
                    mileage: null,
                    name: 'BCM',
                    sn: null,
                    soft: null,
                    timeStamp: null,
                    vin: null
                },

                report: {

                    status: null,
                    val: []
                },

                installed: false
            },

            EPB: {

                info: {

                    calibration: null,
                    fullName: 'Electric Parking Brake',
                    fw: null,
                    hw: null,
                    mileage: null,
                    name: 'EPB',
                    sn: null,
                    soft: null,
                    timeStamp: null,
                    vin: null
                },

                report: {

                    status: null,
                    val: []
                },

                installed: false
            },

            ATC: {

                info: {

                    calibration: null,
                    fullName: 'Active Torque Control',
                    fw: null,
                    hw: null,
                    mileage: null,
                    name: 'ATC',
                    sn: null,
                    soft: null,
                    timeStamp: null,
                    vin: null
                },

                report: {

                    status: null,
                    val: []
                },

                installed: false
            }, 

            SMRR: {

                info: {

                    calibration: null,
                    fullName: 'Seat Module Rear Right',
                    fw: null,
                    hw: null,
                    mileage: null,
                    name: 'SMRR',
                    sn: null,
                    soft: null,
                    timeStamp: null,
                    vin: null
                },

                report: {

                    status: null,
                    val: []
                },

                installed: false
            }, 

            IC: {

                info: {

                    calibration: null,
                    fullName: 'Instrument Cluster',
                    fw: null,
                    hw: null,
                    mileage: null,
                    name: 'IC',
                    sn: null,
                    soft: null,
                    timeStamp: null,
                    vin: null
                },

                report: {

                    status: null,
                    val: []
                },

                installed: false
            }, 

            DMS: {

                info: {

                    calibration: null,
                    fullName: 'Drive Mode Switch',
                    fw: null,
                    hw: null,
                    mileage: null,
                    name: 'DMS',
                    sn: null,
                    soft: null,
                    timeStamp: null,
                    vin: null
                },

                report: {

                    status: null,
                    val: []
                },

                installed: false
            }, 

            TMDTM: {

                info: {

                    calibration: null,
                    fullName: '??? Door Trunk Module',
                    fw: null,
                    hw: null,
                    mileage: null,
                    name: 'TMDTM',
                    sn: null,
                    soft: null,
                    timeStamp: null,
                    vin: null
                },

                report: {

                    status: null,
                    val: []
                },

                installed: false
            }, 

            ESP: {

                info: {

                    calibration: null,
                    fullName: 'Electronic Stability Program',
                    fw: null,
                    hw: null,
                    mileage: null,
                    name: 'ESP',
                    sn: null,
                    soft: null,
                    timeStamp: null,
                    vin: null
                },

                report: {

                    status: null,
                    val: []
                },

                installed: false
            }, 

            DCDC_INC: {

                info: {

                    calibration: null,
                    fullName: 'DC/DC converter 12V',
                    fw: null,
                    hw: null,
                    mileage: null,
                    name: 'DCDC_INC',
                    sn: null,
                    soft: null,
                    timeStamp: null,
                    vin: null
                },

                report: {

                    status: null,
                    val: []
                },

                installed: false
            }, 

            SMRL: {

                info: {

                    calibration: null,
                    fullName: 'Seat Module Rear Lef',
                    fw: null,
                    hw: null,
                    mileage: null,
                    name: 'SMRL',
                    sn: null,
                    soft: null,
                    timeStamp: null,
                    vin: null
                },

                report: {

                    status: null,
                    val: []
                },

                installed: false
            }, 

            ERAG: {

                info: {

                    calibration: null,
                    fullName: 'ERA GLONASS control unit',
                    fw: null,
                    hw: null,
                    mileage: null,
                    name: 'ERAG',
                    sn: null,
                    soft: null,
                    timeStamp: null,
                    vin: null
                },

                report: {

                    status: null,
                    val: []
                },

                installed: false
            }, 

            PTG: {

                info: {

                    calibration: null,
                    fullName: 'Power Tale Gate (trunk lid opening system)',
                    fw: null,
                    hw: null,
                    mileage: null,
                    name: 'PTG',
                    sn: null,
                    soft: null,
                    timeStamp: null,
                    vin: null
                },

                report: {

                    status: null,
                    val: []
                },

                installed: false
            }, 

            VAU: {

                info: {

                    calibration: null,
                    fullName: 'Vehicle Access Unit',
                    fw: null,
                    hw: null,
                    mileage: null,
                    name: 'VAU',
                    sn: null,
                    soft: null,
                    timeStamp: null,
                    vin: null
                },

                report: {

                    status: null,
                    val: []
                },

                installed: false
            }, 

            SCU: {

                info: {

                    calibration: null,
                    fullName: 'Suspension and pneumo control unit',
                    fw: null,
                    hw: null,
                    mileage: null,
                    name: 'SCU',
                    sn: null,
                    soft: null,
                    timeStamp: null,
                    vin: null
                },

                report: {

                    status: null,
                    val: []
                },

                installed: false
            }, 

            TCU: {

                info: {

                    calibration: null,
                    fullName: 'Transmission Control Unit',
                    fw: null,
                    hw: null,
                    mileage: null,
                    name: 'TCU',
                    sn: null,
                    soft: null,
                    timeStamp: null,
                    vin: null
                },

                report: {

                    status: null,
                    val: []
                },

                installed: false
            }, 

            OCUFL: {

                info: {

                    calibration: null,
                    fullName: 'Occupant Classification Unit Front Left',
                    fw: null,
                    hw: null,
                    mileage: null,
                    name: 'OCUFL',
                    sn: null,
                    soft: null,
                    timeStamp: null,
                    vin: null
                },

                report: {

                    status: null,
                    val: []
                },

                installed: false
            }, 

            SVU: {

                info: {

                    calibration: null,
                    fullName: 'Surround View Unit',
                    fw: null,
                    hw: null,
                    mileage: null,
                    name: 'SVU',
                    sn: null,
                    soft: null,
                    timeStamp: null,
                    vin: null
                },

                report: {

                    status: null,
                    val: []
                },

                installed: false
            }, 

            DMRL: {

                info: {

                    calibration: null,
                    fullName: 'Door Module Rear Left',
                    fw: null,
                    hw: null,
                    mileage: null,
                    name: 'DMRL',
                    sn: null,
                    soft: null,
                    timeStamp: null,
                    vin: null
                },

                report: {

                    status: null,
                    val: []
                },

                installed: false
            }, 

            CDA: {

                info: {

                    calibration: null,
                    fullName: 'Central Driver Assistance',
                    fw: null,
                    hw: null,
                    mileage: null,
                    name: 'CDA',
                    sn: null,
                    soft: null,
                    timeStamp: null,
                    vin: null
                },

                report: {

                    status: null,
                    val: []
                },

                installed: false
            }, 

            EMS: {

                info: {

                    calibration: null,
                    fullName: 'Engine Management System',
                    fw: null,
                    hw: null,
                    mileage: null,
                    name: 'EMS',
                    sn: null,
                    soft: null,
                    timeStamp: null,
                    vin: null
                },

                report: {

                    status: null,
                    val: []
                },

                installed: false
            }, 

            APU: {

                info: {

                    calibration: null,
                    fullName: 'Auxiliary Preheating Unit',
                    fw: null,
                    hw: null,
                    mileage: null,
                    name: 'APU',
                    sn: null,
                    soft: null,
                    timeStamp: null,
                    vin: null
                },

                report: {

                    status: null,
                    val: []
                },

                installed: false
            }, 

            OCURL: {

                info: {

                    calibration: null,
                    fullName: 'Occupant Classification Unit Rear Left',
                    fw: null,
                    hw: null,
                    mileage: null,
                    name: 'OCURL',
                    sn: null,
                    soft: null,
                    timeStamp: null,
                    vin: null
                },

                report: {

                    status: null,
                    val: []
                },

                installed: false
            }, 

            PACU: {

                info: {

                    calibration: null,
                    fullName: 'Parking Aid Control Unit',
                    fw: null,
                    hw: null,
                    mileage: null,
                    name: 'PACU',
                    sn: null,
                    soft: null,
                    timeStamp: null,
                    vin: null
                },

                report: {

                    status: null,
                    val: []
                },

                installed: false
            }, 

            DMRR: {

                info: {

                    calibration: null,
                    fullName: 'Door Module Rear Right',
                    fw: null,
                    hw: null,
                    mileage: null,
                    name: 'DMRR',
                    sn: null,
                    soft: null,
                    timeStamp: null,
                    vin: null
                },

                report: {

                    status: null,
                    val: []
                },

                installed: false
            }, 

            FCU: {

                info: {

                    calibration: null,
                    fullName: 'Front Camera Unit',
                    fw: null,
                    hw: null,
                    mileage: null,
                    name: 'FCU',
                    sn: null,
                    soft: null,
                    timeStamp: null,
                    vin: null
                },

                report: {

                    status: null,
                    val: []
                },

                installed: false
            }, 

            INC: {

                info: {

                    calibration: null,
                    fullName: 'Inverter Control with DC/DC Converter',
                    fw: null,
                    hw: null,
                    mileage: null,
                    name: 'INC',
                    sn: null,
                    soft: null,
                    timeStamp: null,
                    vin: null
                },

                report: {

                    status: null,
                    val: []
                },

                installed: false
            }, 

            SMFL: {

                info: {

                    calibration: null,
                    fullName: 'Seat Module Front Left',
                    fw: null,
                    hw: null,
                    mileage: null,
                    name: 'SMFL',
                    sn: null,
                    soft: null,
                    timeStamp: null,
                    vin: null
                },

                report: {

                    status: null,
                    val: []
                },

                installed: false
            }, 

            OCUFR: {

                info: {

                    calibration: null,
                    fullName: 'Occupant Classification Unit Front Right',
                    fw: null,
                    hw: null,
                    mileage: null,
                    name: 'OCUFR',
                    sn: null,
                    soft: null,
                    timeStamp: null,
                    vin: null
                },

                report: {

                    status: null,
                    val: []
                },

                installed: false
            }, 

            DMFL: {

                info: {

                    calibration: null,
                    fullName: 'Door Module Front Left',
                    fw: null,
                    hw: null,
                    mileage: null,
                    name: 'DMFL',
                    sn: null,
                    soft: null,
                    timeStamp: null,
                    vin: null
                },

                report: {

                    status: null,
                    val: []
                },

                installed: false
            }, 

            CCU: {

                info: {

                    calibration: null,
                    fullName: 'Climate Control Unit',
                    fw: null,
                    hw: null,
                    mileage: null,
                    name: 'CCU',
                    sn: null,
                    soft: null,
                    timeStamp: null,
                    vin: null
                },

                report: {

                    status: null,
                    val: []
                },

                installed: false
            }, 

            ACU: {

                info: {

                    calibration: null,
                    fullName: 'Airbag Control Unit',
                    fw: null,
                    hw: null,
                    mileage: null,
                    name: 'ACU',
                    sn: null,
                    soft: null,
                    timeStamp: null,
                    vin: null
                },

                report: {

                    status: null,
                    val: []
                },

                installed: false
            }, 

            BMS: {

                info: {

                    calibration: null,
                    fullName: 'Battery Management System',
                    fw: null,
                    hw: null,
                    mileage: null,
                    name: 'BMS',
                    sn: null,
                    soft: null,
                    timeStamp: null,
                    vin: null
                },

                report: {

                    status: null,
                    val: []
                },

                installed: false
            }, 

            SMFR: {

                info: {

                    calibration: null,
                    fullName: 'Seat Module Front Right',
                    fw: null,
                    hw: null,
                    mileage: null,
                    name: 'SMFR',
                    sn: null,
                    soft: null,
                    timeStamp: null,
                    vin: null
                },

                report: {

                    status: null,
                    val: []
                },

                installed: false
            }, 

            OCURR: {

                info: {

                    calibration: null,
                    fullName: 'Occupant Classification Unit Rear Right',
                    fw: null,
                    hw: null,
                    mileage: null,
                    name: 'OCURR',
                    sn: null,
                    soft: null,
                    timeStamp: null,
                    vin: null
                },

                report: {

                    status: null,
                    val: []
                },

                installed: false
            },

            HUD: {

                info: {

                    calibration: null,
                    fullName: 'Head-Up Display',
                    fw: null,
                    hw: null,
                    mileage: null,
                    name: 'HUD',
                    sn: null,
                    soft: null,
                    timeStamp: null,
                    vin: null
                },

                report: {

                    status: null,
                    val: []
                },

                installed: false
            }
        },
        ecusProcess: false,
        ecusMsg: null,
    },

    actions: {

        /**
         * Получает ЕБУ автомобиля
         */
        async readEcus({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.ecusProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/egts/reports/last/${payload.vin}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error && request.lastReport && request.lastReport.length) {

                    for(let ecu of request.lastReport) {

                        let stateEcu = state.ecus[ecu.info.name]

                        if(stateEcu) {

                            stateEcu.info.callibration  = ecu.info.callibration
                            stateEcu.info.fw            = ecu.info.fw
                            stateEcu.info.hw            = ecu.info.hw
                            stateEcu.info.mileage       = ecu.info.mileage ? (ecu.info.mileage / 10).toFixed(1) : '—'
                            stateEcu.info.sn            = ecu.info.sn
                            stateEcu.info.soft          = ecu.info.soft
                            stateEcu.info.timeStamp     = ecu.info.timeStamp
                            stateEcu.info.vin           = ecu.info.vin

                            stateEcu.installed          = true

                            stateEcu.report             = ecu.report
                        }
                    }

                    // state.ecus = request.lastReport
                    state.ecusMsg = { success: true }

                } else {

                    state.ecusMsg = { success: false }
                    state.ecusMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.ecusProcess = false

            } else {

                state.ecusMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        }
    },

    mutations: {

        setEcusMsg: (state, data) => state.ecusMsg = data,

        resetEcus: state => {

            for(let ecuKey of Object.keys(state.ecus)) {

                for( let valueKey in state.ecus[ecuKey].info ) {

                    const val = state.ecus[ecuKey]

                    if(valueKey !== 'name' && valueKey !== 'fullName') {

                        val.info[valueKey] = null
                    }
                }

                state.ecus[ecuKey].report = { 

                    status: null, 
                    val: [] 
                }

                state.ecus[ecuKey].installed = false
            }
        }
    }
}