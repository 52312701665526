export default {

    state: {

        statesHistory:        null,
        statesHistoryPart:    null,
        statesHistoryProcess: false,
        statesHistoryMsg:     ''
    },

    getters: {

        getStatesHistory:        state => state.statesHistory,
        getStatesHistoryPart:    state => state.statesHistoryPart,
        getStatesHistoryProcess: state => state.statesHistoryProcess,
        getStatesHistoryMsg:     state => state.statesHistoryMsg
    },

    actions: {

        async readStatesHistory({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access
            
            if(token) {

                state.statesHistoryProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        if(param == 'key_array') {

                            payload.key_array.map( item => {

                                params.length ?
                                    params += '&key_array=' + item :
                                    params = '?key_array=' + item
                            })

                        } else {

                            params.length ?
                                params += '&' + param + '=' + payload[param] :
                                params = '?' + param + '=' + payload[param]
                        }
                    }
                }

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/points/track${params}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    state.statesHistoryPart = request
                    state.statesHistoryMsg = { success: true, text: '' }

                } else {

                    state.statesHistory = null
                    state.statesHistoryPart = null

                    state.statesHistoryMsg = { success: false }

                    switch(request.error) {

                        case 'ECarPointNotFound': 
                            state.statesHistoryMsg.text = getters.getTranslate.HISTORY_EMPTY_POINTS
                            break

                        default:
                            state.statesHistoryMsg.text = getters.getTranslate[request.error] || request.error
                            break
                    }
                }

                state.statesHistoryProcess = false

            } else {

                state.statesHistoryMsg = { success: false, text:getters.getTranslate.EUnauthRequest }
            }
        }
    },

    mutations: {

        setStatesHistory:      (state, data) => state.statesHistory      = data,
        setStatesHistoryPart:  (state, data) => state.statesHistoryPart  = data,
        setStatesHistoryMsg:   (state, data) => state.statesHistoryMsg   = data
    }
}