export default {

    state: {

        egtsModules:        { array: [] },
        egtsModulesProcess: false,
        egtsModulesMsg:     ''
    },

    getters: {

        getEgtsModules:         state => state.egtsModules,
        getEgtsModulesProcess:  state => state.egtsModulesProcess,
        getEgtsModulesMsg:      state => state.egtsModulesMsg
    },

    actions: {

        /**
         * Получает информацию о модулях автомобиля [не путать с ЭБУ!]
         * 
         * @param {String} payload - параметры запроса [см.swagger]
         */
        async readEgtsModules({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.egtsModulesProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        if(param !== 'vin') {

                            params.length ?
                                params += '&' + param + '=' + payload[param] :
                                params = '?' + param + '=' + payload[param]
                        }
                    }
                }

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/egts/modules/${payload.vin}${params}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    state.egtsModules = request
                    state.egtsModulesMsg = { success: true }

                } else {

                    state.egtsModulesMsg = { success: false }
                    state.egtsModulesMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.egtsModulesProcess = false

            } else {

                state.egtsModulesMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        }
    },

    mutations: {

        resetModules(state) {

            state.egtsModules           = { array: [] }
            state.egtsModulesProcess    = false
            state.egtsModulesMsg        = ''
        }
    }
}