<template>
    <div class="adt-footer">
        {{ getTranslate.COPYRIGHT }}
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    
    name: 'ADTCopyright',

    computed: {

        ...mapGetters([

            'getTranslate'
        ])
    }
}
</script>