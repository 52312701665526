export default {

    state: {

        sendCMDProcess: false,
        sendCMDMsg: null
    },

    actions: {

        /**
         * Отправляет команду на сервер для пересылки на устойство
         * Если устройство недоступно - возвратит ошибку
         * 
         * @param {Object} payload - команда (см.swagger)
         */
        async sendCMD({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.sendCMDProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/egts/commands`, 
                    method: 'POST', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    console.log('============================')
                    console.log('CMD response: ', request)
                    
                    let responseStatus,
                        responseText

                    switch(request.status) {

                        case 'NOT_SET': 
                            responseStatus = false, 
                            responseText = 'CMD_RESULT_NOT_SET'
                            break

                        case 'SEND_IN_PROCESS': 
                            responseStatus = true, 
                            responseText = 'CMD_RESULT_SEND_IN_PROCESS'
                            break

                        case 'SEND_TIMEOUT': 
                            responseStatus = false, 
                            responseText = 'CMD_RESULT_SEND_TIMEOUT'
                            break

                        case 'SEND_CONFIRMED': 
                            responseStatus = false, 
                            responseText = 'CMD_RESULT_SEND_CONFIRMED'
                            break

                        case 'RESULT_OK': 
                            responseStatus = true, 
                            responseText = 'CMD_RESULT_RESULT_OK'
                            break

                        case 'RESULT_ERROR': 
                            responseStatus = false, 
                            responseText = 'CMD_RESULT_RESULT_ERROR'
                            break

                        case 'RESULT_ILL': 
                            responseStatus = false, 
                            responseText = 'CMD_RESULT_RESULT_ILL'
                            break

                        case 'RESULT_DEL': 
                            responseStatus = true, 
                            responseText = 'CMD_RESULT_RESULT_DEL'
                            break

                        case 'RESULT_NOT_FOUND': 
                            responseStatus = false, 
                            responseText = 'CMD_RESULT_RESULT_NOT_FOUND'
                            break

                        case 'RESULT_NOT_CONFIG': 
                            responseStatus = true, 
                            responseText = 'CMD_RESULT_RESULT_NOT_CONFIG'
                            break

                        case 'RESULT_IN_PROCESS': 
                            responseStatus = true, 
                            responseText = 'CMD_RESULT_RESULT_IN_PROCESS'
                            break

                        default: 
                            responseStatus = false, 
                            responseText = 'CMD_RESULT_UNDEFFINED'
                            break
                    }

                    state.sendCMDMsg = { success: responseStatus, text: getters.getTranslate[responseText] }

                    console.log('============================')

                } else {

                    state.sendCMDMsg = { success: false }
                    state.sendCMDMsg.text = getters.getTranslate[request.error] || request.error
                }
            
                state.sendCMDProcess = false
                
            } else {

                state.sendCMDMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        }
    },

    mutations: {

        setSendCMDMsg: (state, data) => state.sendCMDMsg = data
    }
}