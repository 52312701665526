export default {

    state: {

        cars: {

            array: []
        },
        carsProcess: false,
        carsMsg: null,

        carCreateProcess: false,
        carCreateMsg: null,

        carDeleteProcess: false,
        carDeleteMsg: null
    },

    getters: {

        getCars: state => state.cars,
        getCarsProcess: state => state.carsProcess,
        getCarsMsg: state => state.carsMsg, 

        getCarCreateProcess: state => state.carCreateProcess,

        getCarDeleteProcess: state => state.carDeleteProcess,
        getCarDeleteMsg: state => state.carDeleteMsg,
    },

    actions: {

        /**
         * Создаёт новый автомобиль
         * 
         * @param {Object} payload - объект с полями формы создания нового автомобиля
         */
        async createCar({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.carCreateProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/cars`, 
                    method: 'POST', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.carCreateMsg = { success: true, text: getters.getTranslate.CAR_CREATE_SUCCESS }

                } else {

                    state.carCreateMsg = { success: false }
                    state.carCreateMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.carCreateProcess = false

            } else {

                state.carCreateMsg = { success: false, text:getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Получает автомобили
         * 
         * @param {String} payload - параметры запроса [см.swagger]
         */
        async readCars({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.carsProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        if(typeof payload[param] == 'object' && payload[param].length) {

                            payload.vin_array.map( el => {

                                params.length ?
                                    params += '&' + param + '=' + el :
                                    params = '?' + param + '=' + el
                            })

                        } else {

                            params.length ?
                                params += '&' + param + '=' + payload[param] :
                                params = '?' + param + '=' + payload[param]
                        }
                    }
                }

                const cars = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/cars${params}`, 
                    method: 'GET', 
                    token
                })

                if(!cars.error) {

                    const favorites = await dispatch('readFavoriteCars')

                    if(favorites.vinArray) {

                        cars.array.map( car => {

                            if(favorites.vinArray.includes(car.vin)) {

                                car.favorite = true
                            }
                        })
                    }

                    state.cars = cars
                    state.carsMsg = { success: true }

                    state.carsProcess = false

                    return cars

                } else {

                    state.cars = { array: [] }

                    state.carsMsg = { success: false }
                    state.carsMsg.text = getters.getTranslate[cars.error] || cars.error

                    state.carsProcess = false
                }

                
            } else {

                state.carsMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }

            state.carsProcess = false

            return false
        },

        /**
         * Удаляет автомобили
         * 
         * @param {String} payload - UUID автомобиля
         */
        async deleteCar({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.carDeleteProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/cars/${payload}`, 
                    method: 'DELETE', 
                    token
                })

                if(!request.error) {

                    state.carDeleteMsg = { success: true, text: getters.getTranslate.CAR_DELETE_SUCCESS }

                } else {

                    state.carDeleteMsg = { success: false }
                    state.carDeleteMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.carDeleteProcess = false

            } else {

                state.carDeleteMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        }
    },

    mutations: {

        setCreateCarMsg: (state, data) => state.carCreateMsg = data,
        setCarMsg:       (state, data) => state.carMsg       = data,
        setDeleteCarMsg: (state, data) => state.carDeleteMsg = data
    }
}