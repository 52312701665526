export default {

    state: {

        sets: null,
        setsProcess: false,
        setsMsg: null,

        setCreateProcess: false,
        setCreateMsg: null,

        setsDeleteProcess: false,
        setsDeleteMsg: null,

        set: {},
        setUpdateProcess: false,
        setUpdateMsg: null,

        setDeleteProcess: false,
        setDeleteMsg: null,

        setOnes: {},
        setOnesProcess: false,
        setOnesMsg: null,

        setOnesCreateProcess: false,
        setOnesCreateMsg: null,

        setOneDeleteProcess: false,
        setOneDeleteMsg: null
    },

    actions: {

        /**
         * Создаёт новый набор
         * 
         * @param {Object} payload - объект с полями формы создания нового набора
         */
         async createSet({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.setCreateProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/policy/sets`, 
                    method: 'POST', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.setCreateMsg = { success: true, text: getters.getTranslate.POLICY_SET_CREATE_SUCCESS }

                } else {

                    state.setCreateMsg = { success: false }
                    state.setCreateMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.setCreateProcess = false

            } else {

                state.setCreateMsg = { success: false, text:getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Создаёт связь одного правила с одной полтикой
         * 
         * @param {Object} payload - объект с полями формы создания связи правило-политика
         */
         async createSetOnes({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.setOnesCreateProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/policy/links`, 
                    method: 'POST', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.setOnesCreateMsg = { success: true, text: getters.getTranslate.POLICY_SET_ONES_CREATE_SUCCESS  }

                } else {

                    state.setOnesCreateMsg = { success: false }
                    state.setOnesCreateMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.setOnesCreateProcess = false

            } else {

                state.setOnesCreateMsg = { success: false, text:getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Получает доступные наборы
         * 
         * @param {Object} payload - параметра для GET-запроса
         */
        async readSets({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.setsProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        params.length ?
                            params += '&' + param + '=' + payload[param] :
                            params = '?' + param + '=' + payload[param]
                    }
                }

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/policy/sets${params}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    state.sets = request
                    state.setsMsg = { success: true }

                } else {

                    state.sets = null

                    state.setsMsg = { success: false }
                    state.setsMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.setsProcess = false

            } else {

                state.setsMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Получает набор по UUID
         * 
         * @param {String} payload - UUID набора
         */
         async readSet({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.setProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/policy/sets/${payload}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    state.set = request
                    state.setMsg = { success: true }

                } else {

                    state.set = null

                    state.setMsg = { success: false }
                    state.setMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.setProcess = false

            } else {

                state.setMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Получает политики набора
         * 
         * @param {Object} payload - UUID набора
         */
         async readSetOnes({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.setOnesProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        params.length ?
                            params += '&' + param + '=' + payload[param] :
                            params = '?' + param + '=' + payload[param]
                    }
                }

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/policy/ones${params}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    state.setOnes = request
                    state.setOnesMsg = { success: true }

                } else {

                    state.setOnes = {}

                    state.setOnesMsg = { success: false }
                    state.setOnesMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.setOnesProcess = false

            } else {

                state.setOnesMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Обновляет набор
         * 
         * @param {Object} payload - объект с полями формы обновления набора
         */
         async updateSet({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.setUpdateProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/policy/sets`, 
                    method: 'POST', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.setUpdateMsg = { success: true, text: getters.getTranslate.POLICY_SET_UPDATE_SUCCESS }

                } else {

                    state.setUpdateMsg = { success: false }
                    state.setUpdateMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.setUpdateProcess = false

            } else {

                state.setUpdateMsg = { success: false, text:getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Удаляет набор
         * 
         * @param {String} payload - UUID набора
         */
        async deleteSets({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.setsDeleteProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/policy/sets/${payload}`, 
                    method: 'DELETE', 
                    token
                })

                if(!request.error) {

                    state.setsDeleteMsg = { success: true, text: getters.getTranslate.POLICY_SETS_DELETE_SUCCESS }

                } else {

                    state.sets = null

                    state.setsDeleteMsg = { success: false }
                    state.setsDeleteMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.setsDeleteProcess = false

            } else {

                state.setsDeleteMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }

            return false
        },

        /**
         * Удаляет политику из набора
         * 
         * @param {Object} payload - связка политика-набор [см.swagger]
         */
         async deleteSetOne({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.setOneDeleteProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/policy/links`, 
                    method: 'DELETE', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.setOneDeleteMsg = { success: true, text: getters.getTranslate.POLICY_SET_ONE_DELETE_SUCCESS }

                } else {

                    state.ones = null

                    state.setOneDeleteMsg = { success: false }
                    state.setOneDeleteMsg.text = getters.getTranslate[request.error] || request.error
                }

                state.setOneDeleteProcess = false

            } else {

                state.setOneDeleteMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }

            return false
        }
    },

    mutations: {

        setSetsMsg:             (state, data) => state.setsMsg              = data,
        setSetsCreateMsg:       (state, data) => state.setsCreateMsg        = data,
        setSetsDeleteMsg:       (state, data) => state.setsDeleteMsg        = data,

        setSet:                 (state, data) => state.set                  = data,
        setSetProcess:          (state, data) => state.setProcess           = data,
        setSetMsg:              (state, data) => state.setMsg               = data,
        setSetCreateMsg:        (state, data) => state.setCreateMsg         = data,
        setSetUpdateMsg:        (state, data) => state.setUpdateMsg         = data,
        setSetDeleteMsg:        (state, data) => state.setDeleteMsg         = data,

        setSetOnesCreateProcess:(state, data) => state.setOnesCreateProcess = data,
        setSetOnesCreateMsg:    (state, data) => state.setOnesCreateMsg     = data,

        setSetOneDeleteProcess: (state, data) => state.setOneDeleteProcess  = data,
        setSetOneDeleteMsg:     (state, data) => state.setOneDeleteMsg      = data
    }
}