export default {

    state: {

        events: {

            array: []
        },
        eventsProcess: false,
        eventsMsg: '',

        eventsCar: [],
        eventsCarPart: null,
        eventsCarPartMsg: {},
        eventsCarPartProcess: false
    },

    getters: {

        getEvents:              state => state.events,
        getEventsProcess:       state => state.eventsProcess,
        getEventsMsg:           state => state.eventsMsg,

        getEventsCarMsg:        state => state.eventsCarMsg,
        getEventsCarPartMsg:    state => state.eventsCarPartMsg,
        getEventsCarPartProcess: state => state.eventsCarPartProcess
    },

    actions: {

        /**
         * Получает все собатия
         */
        async readEvents({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.eventsProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        params.length ?
                            params += '&' + param + '=' + payload[param] :
                            params = '?' + param + '=' + payload[param]
                    }
                }

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/events${params}`, 
                    method: 'GET', 
                    token
                })


                if(!request.error) {

                    request.array.map( event => {

                        event.type_key = 'EVENT_' + event.type.toUpperCase()
                    })

                    state.events = request
                    state.eventsMsg = { success: true }

                } else {
                    state.eventsMsg = { success: false }
                    state.eventsMsg.text = getters.getTranslate[request.error] || request.error
                }
            
                state.eventsProcess = false
                
            } else {
                
                state.eventsMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Получает события выбранного автомобиля
         * 
         * @param {Object} payload 
         */
        async readEventsCar({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.eventsCarPartProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        params.length ?
                            params += '&' + param + '=' + payload[param] :
                            params = '?' + param + '=' + payload[param]
                    }
                }

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/events${params}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    request.array.map( event => {

                        event.type_key = 'EVENT_' + event.type.toUpperCase()
                    })

                    state.eventsCarPart = request
                    state.eventsCarPartMsg = { success: true }

                } else {

                    state.eventsCarPartMsg = { success: false }
                    state.eventsCarPartMsg.text = getters.getTranslate[request.error] || request.error

                }

                state.eventsCarPartProcess = false

            } else {

                state.eventsCarPartMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        }
    },

    mutations: {

        setEventsCar:       (state, data) => state.eventsCar        = data,
        setEventsCarPart:   (state, data) => state.eventsCarPart    = data,
        setEventsCarMsg:   (state, data) => state.eventsMsg         = data,
        setEventsCarPartMsg:   (state, data) => state.eventsCarPartMsg         = data
        
    }
}