export default function(mins) {

    const t         = 60 * 24

    const days      = (mins / t)
    const rDays     = Math.floor(days)

    const hours     = ((days - rDays) * t) / 60
    const rHours    = Math.floor(hours)

    const minutes   = (hours - rHours) * 60
    const rMinutes  = Math.round(minutes)

    return {

        days:       rDays,
        hours:      rHours,
        minutes:    rMinutes
    }
}