<template>

    <div class="adt-header-profile" v-if="Object.keys(profile).length">
        <router-link to="/profile" class="adt-header-profile__name">
            {{ profile.fio }}
        </router-link>
    </div>

</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {

    name: 'ADTHeaderProfile',

    computed: {

        ...mapState({

            token:      state => state.token.access,
            profile:    state => state.profile.profile
        })
    },

    methods: {

        ...mapActions([

            'readProfile'
        ])
    },

    mounted() {

        this.readProfile()
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/scss/components/header-profile';
</style>