<template>

    <nav class="adt-navi" role="navigation">

        <router-link to="/cars" class="adt-navi__link" :class="{ 'adt-navi__link--active': $route.path == '/cars' }">
            <img 
                :src="
                    $route.path == '/cars' ? 
                        require('@/assets/images/icons/icon-cars-active.png') : 
                        require('@/assets/images/icons/icon-cars.png')" 
                class="adt-navi__link-img"
                alt="" 
            >
            <span class="adt-navi__link-text">{{ getTranslate.MENU_CARS }}</span>
        </router-link> 

        <router-link to="/users" class="adt-navi__link" :class="{ 'adt-navi__link--active': $route.path == '/users' }" v-if="[ 'SUPPERADMINISTRATOR', 'ADMINISTRATOR' ].includes(profile.type)">
            <img 
                :src="
                    $route.path == '/users' ? 
                        require('@/assets/images/icons/icon-users-active.png') : 
                        require('@/assets/images/icons/icon-users.png')" 
                class="adt-navi__link-img"
                alt="" 
            >
            <span class="adt-navi__link-text">{{ getTranslate.MENU_USERS }}</span>
        </router-link> 

        <router-link to="/guide" class="adt-navi__link" :class="{ 'adt-navi__link--active': $route.path == '/guide' }">
            <img 
                :src="
                    $route.path == '/guide' ? 
                        require('@/assets/images/icons/icon-guide-active.png') : 
                        require('@/assets/images/icons/icon-guide.png')" 
                class="adt-navi__link-img"
                alt="" 
            >
            <span class="adt-navi__link-text">{{ getTranslate.MENU_GUIDE }}</span>
        </router-link> 

    </nav>

</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {

    name: 'ADTNavi',

    computed: {

        ...mapState({

            profile: state => state.profile.profile
        }),

        ...mapGetters([

            'getTranslate'
        ])
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/scss/components/navi.scss';
</style>