export default {

    state: {

        RU: {

            APP_NAME:                   'Sky web',

            SEARCH:                     'Поиск',

            PAGINATOR_PAGE:             'Страница',

            YES:                        'Да',
            NO:                         'Нет',

            START:                      'Старт',
            STOP:                       'Стоп',
            FINISH:                     'Финиш',

            NO_FAILURE:                 'Нет ошибок',

            // Единицы измерений
            UNIT_KM:                    'км',
            UNIT_KMH:                   'км/ч',
            UNIT_METRE:                 'м',
            UNIT_DAY:                   'д.',
            UNIT_HOUR:                  'ч.',
            UNIT_MINUTE:                'мин.',
            UNIT_SECOND:                'с',
            UNIT_SECOND_LONG:           'сек.',
            UNIT_LITER:                 'л',
            UNIT_LITER_100_KM:          'л/100км',
            UNIT_ATM:                   'атм',
            UNIT_RPM:                   'об/мин',
            UNIT_VOLT:                  'В',

            // Текст форм
            FORM_REQUIRED:              'Поля обязательные для заполнения',
            FORM_ENTER:                 'Введите значение',
            FORM_SELECT_COLOR:          'Выберите цвет из списка',
            FORM_SELECT:                'Выберите из списка',
            FORM_SEARCH:                'Поиск',

            // Текст кнопок
            BTN_ADD:                    'Добавить',
            BTN_CREATE:                 'Создать',
            BTN_NEW:                    'Новый',
            BTN_EDIT:                   'Редактировать',
            BTN_SAVE:                   'Сохранить',
            BTN_READMORE:               'Перейти',
            BTN_CANCEL:                 'Отмена',
            BTN_DELETE:                 'Удалить',
            BTN_CTRL:                   'Управление',
            BTN_REQUEST:                'Запросить',
            BTN_SHOW:                   'Показать',
            BTN_UPLOAD:                 'Загрузить',
            BTN_DOWNLOAD:               'Скачать',
            BTN_CLOSE:                  'Закрыть',
            BTN_SELECT:                 'Выбрать',
            BTN_EXPORT:                 'Экспорт',
            BTN_FAVORITES:              'Избранное',
            BTN_RESET:                  'Сбросить',
            BTN_SEND:                   'Послать',

            // Дни недели и "быстрые даты"
            MONDAY:                     'Понедельник',
            TUESDAY:                    'Вторник',
            WEDNESDAY:                  'Среда',
            THIRSDAY:                   'Четверг',
            FRIDAY:                     'Пятница',
            SATURDAY:                   'Суббота',
            SUNDAY:                     'Воскресенье',
            TODAY:                      'Сегодня',
            YESTERDAY:                  'Вчера',
            DAY_BEFORE_YESTERDAY:       'Позавчера',
            IN_A_WEEK:                  'За неделю',

            // Уровни жидкостей
            LEVEL_CRITICAL_LOW:         'Критически низкий уровень',
            LEVEL_LOW:                  'Низкий уровень',
            LEVEL_NORMAL:               'Нормальный уровень',
            LEVEL_HIGH:                 'Высокий уровень',

            // Пагинация
            PAGINATION_PREV:            'Пред.',
            PAGINATION_NEXT:            'След.',
            PAGINATION_PAGE:            'Страница',

            // Главное меню
            MENU_MAIN:                  'Главная',
            MENU_CARS:                  'Автомобили',
            MENU_SOFTWARE:              'Прошивки',
            MENU_GUIDE:                 'Справочник',
            MENU_USERS:                 'Пользователи',
            MENU_USER:                  'Пользователь',

            // Меню автомобиля
            CARMENU_INFO:               'Информация',
            CARMENU_MAP:                'Карта',
            CARMENU_STATE:              'Состояние',
            CARMENU_ECUS:               'ЭБУ',
            CARMENU_ECU:                'ЭБУ',
            CARMENU_LOGS:               'Логи',
            CARMENU_USERS:              'Пользователи',
            CARMENU_EVENTS:             'События',
            CARMENU_HISTORY:            'История показаний',

            // Заголовки страниц
            PAGE_MAIN:                  'Главная',
            PAGE_CARS:                  'Автомобили',
            PAGE_USERS:                 'Пользователи',
            PAGE_PROFILE:               'Профиль',
            PAGE_LOGS:                  'Логи',
            PAGE_GUIDE:                 'Справочник',
            PAGE_CAR:                   'Автомобиль',
            PAGE_CAR_INFO:              'Информация',
            PAGE_CAR_ECU:               'Электронные блоки',
            PAGE_CAR_MAP:               'Карта',
            PAGE_CAR_STATE:             'Состояние',
            PAGE_CAR_USERS:             'Пользователи',
            PAGE_CAR_LOGS:              'Логи',
            PAGE_CAR_EVENTS:            'События',
            PAGE_CAR_HISTORY:           'История показаний приборов и датчиков',

            // Авторизация и одноразовый вход
            AUTHORIZATION:              'Авторизация',
            LOGIN:                      'Логин',
            LOGIN_SHORT:                'Логин должен быть длиннее 6 знаков',
            PASSWORD:                   'Пароль',
            PASSWORD_SHORT:             'Пароль должен быть длиннее 6 знаков',
            CAPTCHA_VALUE:              'Введите код с картинки',
            AUTHORIZATE:                'Войти в систему',
            SINGLE_AUTH:                'Одноразовый вход',
            EMAIL:                      'E-mail',
            EMAIL_CODE:                 'Код из письма/смс',
            EMAIL_SHORT:                'E-mail должен быть длиннее 6 символов',
            REQUEST_PASSWORD:           'Запросить пароль',

            // Права пользователей
            READ:                       'Чтение',
            READ_WRITE:                 'Чтение и запись',
            READ_WRITE_DELETE:          'Чтение, запись и удаление',
            NO_ACCESS:                  'Нет прав',
            NO_RIGHT:                   'Нет прав',
            RIGHT_BLOCKED:              'Заблокирован',
            NONE:                       'Нет прав',

            // Группы пользователей
            NO_TYPE:                    'Без группы',
            DISABLER:                   'Заблокирован',
            EXCHANGER:                  'Exchanger',
            DRIVER:                     'Водитель',
            OPERATOR:                   'Оператор',
            ADMINISTRATOR:              'Администратор',
            SUPPERADMINISTRATOR:        'Владыка',

            // Пользователи
            USERS_NAME:                 'Имя',
            USERS_TYPE:                 'Группа',
            USERS_EMAIL:                'E-mail',

            // Пользователи и профиль
            PROFILE:                    'Профиль',
            LOGOUT:                     'Выйти',
            USER:                       'Пользователь',
            RESET_TOKEN:                'Завершить все сеансы',
            USER_CREATE:                'Добавить пользователя',
            USER_NAME:                  'Имя',
            USER_EMAIL:                 'E-mail',
            USER_PASS:                  'Пароль',
            USER_RIGHT:                 'Права',
            USER_TYPE:                  'Группа',
            USER_PHONE:                 'Телефон',
            USER_CREATED:               'Создан',
            USER_UPDATED:               'Обновлён',
            USER_CREATE_SUCCESS:        'Новый пользователь добавлен',
            USER_UPDATE_SUCCESS:        'Профиль пользователя обновлён',
            USER_DELETE:                'Удалить пользователя',
            USER_DELETE_TEXT:           'Этот пользователь будет удалён без возможности восстановления. Вы уверены, что хотите удалить его?',
            USER_DELETE_SUCCESS:        'Пользователь удалён',
            USERS:                      'Пользователи',
            USER_INFO:                  'Информация',
            USER_LOG:                   'Журнал действий',
            USER_VEHICLES:              'Автомобили',
            USER_INFO_HEADER:           'Информация о пользователе',
            USER_ACTIONS:               'Журнал действий пользователя',
            USER_ACTION:                'Действие',
            USER_DATETIME:              'Дата, время',
            USER_EMPTY:                 'У пользователя пока нет автомобилей',
            USER_FIO:                   'ФИО',
            USER_EMAIL_ERROR:           'Введите корректный E-mail',
            USER_TYPE_ERROR:            'Выберите тип пользователя',
            USER_RIGHT_ERROR:           'Выберите тип прав пользователя',
            USER_PASS_ERROR:            'Не менее 6 символов, содержит заглавные буквы и цифры',

            // Автомобили пользователя
            USER_CARS:                   'Автомобили пользователя',
            USER_CARS_EMPTY:             'У этого пользователя нет автомобилей',
            USER_CARS_CREATE:            'Автомобили пользователя',
            USER_CARS_ACCESS:            'Права',
            USER_CARS_CREATE_SUCCESS:    'Автомобили пользователя обновлены',
            USER_CARS_DELETE:            'Удалить автомобиль',
            USER_CARS_DELETE_TEXT:       'Автомобиль будет удалён без возможности восстановления',
            USER_CARS_DELETE_SUCCESS:    'Доступ к автомобилю удалён',
            USER_CARS_ADD:               'Добавить автомобиль',

            // Автомобили
            CARS:                       'Автомобили',
            CARS_MODEL:                 'Марка и модель',
            CARS_NAMI_NUMBER:           'Код производителя',
            CARS_TYPE:                  'Тип кузова',
            CARS_VIN:                   'VIN',
            CARS_SEARCH_MODE_ALL:       'Все',
            CARS_SEARCH_MODE_VIN:       'VIN',
            CARS_SEARCH_MODE_TID:       'id SDM',
            CARS_SEARCH_MODE_IMEI:      'IMEI',
            CARS_SEARCH_MODE_NUMBER:    'Гос.номер',
            CARS_SEARCH_MODE_NAMI:      'Номер НАМИ',
            CARS_SEARCH_MODE_PHONE:     'Номер телефона',

            // Автомобиль
            CAR:                        'Автомобиль',
            CAR_VIN:                    'VIN',
            CAR_NAME:                   'Название',
            CAR_NUMBER:                 'Гос.номер',
            CAR_PHONE:                  'Номер телефона SDM',
            CAR_NAMI_NUMBER:            'Код производителя',
            CAR_GARAGE:                 'Гаражный номер',
            CAR_STATE:                  'Описание',
            CAR_CARTYPE:                'Тип кузова',
            CAR_COLOR:                  'Цвет',
            CAR_ENGINE:                 'Двигатель',
            CAR_SUSPENSION:             'Подвеска',
            CAR_YEAR:                   'Модельный год',
            CAR_MODEL:                  'Модель',
            CAR_MODEL_UNKNOWN:          'Неизвестная модель',
            CAR_MANUFACTORY:            'Производство',
            CAR_CREATE_CARTYPE:         'Выбор типа кузова',
            CAR_TID:                    'id SDM',
            CAR_SOFT:                   'Версия ПО модуля',
            CAR_IMEI:                   'IMEI',
            CAR_MODULES:                'Модули',
            CAR_USERS:                  'Пользователи',
            CAR_CREATE:                 'Добавить автомобиль',
            CAR_CREATE_SUCCESS:         'Новый автомобиль добавлен',
            CAR_DELETE:                 'Удалить автомобиль',
            CAR_DELETE_TEXT:            'Этот автомобиль будет удалён без возможности восстановления. Вы уверены, что хотите удалить его?',
            CAR_DELETE_SUCCESS:         'Автомобиль удалён',
            CAR_INFO:                   'Информация об автомобиле',
            CAR_INFO_UPDATE_SUCCESS:    'Информация об автомобиле обновлена',
            CAR_USERS_ADD:              'Добавить пользователя',
            CAR_VIN_ERROR:              'Длина VIN 17 символов',
            CAR_IMEI_ERROR:             'Длина IMEI 15 символов',
            CAR_IMEI_TID:               'Число от 0 до 2147483647',
            CAR_COLOR_CHOOSE:           'Выберите цвет',
            CAR_PHOTO:                  'Фото',
            CAR_STATUS:                 'Статус',

            // Типы подвесок
            SUSPENSION_CONVENTIONAL:    'Обычная',
            SUSPENSION_AIR:             'Пневматическая',
            SUSPENSION_DUAL_AIR:        'Пневматическая (двойной контур)',

            // Типы кузовов
            CARTYPE_SEDAN:                   'Седан',
            CARTYPE_SEDAN_ARMORED:           'Седан бронированный',
            CARTYPE_LIMO_ARMORED_V12:        'Лимузин бронированный',
            CARTYPE_LIMO_ARMORED_V8:         'Лимузин бронированный',
            CARTYPE_LIMO_ARMORED_V8_CIVIL:   'Лимузин бронированный (гражд.)',
            CARTYPE_CABRIO:                  'Кабриолет',
            CARTYPE_CABRIO_SHOW:             'Кабриолет show',
            CARTYPE_SEDAN_4X2:               'Седан 4x2',
            CARTYPE_SEDAN_LONG:              'Седан удлинённый',
            CARTYPE_SEDAN_LONG_ARMORED:      'Седан удлинённый бронированный',
            CARTYPE_SUV:                     'SUV',
            CARTYPE_SUV_ARMORED:             'SUV бронированный',
            CARTYPE_MPV_4_SEATS:             'MPV 4-х местный',
            CARTYPE_MPV_ARMORED_4_SEATS:     'MPV бронированный 4-х местный',
            CARTYPE_MPV_ARMORED_8_SEATS:     'MPV бронированный 8-и местный',
            CARTYPE_MPV_9_SEATS:             'MPV 9-и местный',
            CARTYPE_MPV_ARMORED_9_SEATS:     'MPV бронированный 9-и местный',
            CARTYPE_MPV_ORBITA:              'MPV Орбита',
            CARTYPE_MPV_TABLETKA:            'MPV Таблетка',
            CARTYPE_RESERVED:                'Резерв',
            CARTYPE_UNKNOWN:                 'Неизвестный кузов',

            // Прошивки
            FIRMWARE:                   'Прошивки SDM',
            FIRMWARE_NAME:              'Имя файла',
            FIRMWARE_STATUS:            'Статус',
            FIRMWARE_STATUS_SENDED:     'Отправлен',
            FIRMWARE_STATUS_WAITING:    'Ожидает отправки',
            FIRMWARE_FILE:              'Файл',
            FIRMWARE_CREATE:            'Добавить файл',
            FIRMWARE_CREATE_SUCCESS:    'Файл прошивки успешно загружен, ожидайте запись на устройство',
            FIRMWARE_DELETE:            'Удалить файл',
            FIRMWARE_DELETE_TEXT:       'Этот файл прошивки будет удалён без возможности восстановления. Вы уверены, что хотите удалить его?',
            FIRMWARE_DELETE_SUCCESS:    'Файл прошивки удалён',
            FIRMWARE_FILE_EMPTY:        'Вы пытаетесь загрузить пустой файл прошивки',
            FIRMWARE_FILE_ERROR:        'Не выбран файл прошивки',
            FIRMWARE_FILE_LARGE:        'Слишком большой файл, максимальный вес файла для загрузки - 16 MB',

            ECUS_EXPORT_DESCR:          'Выберите блоки, отчёты которых будут загружены',
            ECUS_EXPORT_TYPE_DESCR:     'Выберите способ импорта',
            ECUS_EXPORT_SELECT_ALL:     'Выбрать всё',
            ECUS_EXPORT_DESELECT_ALL:   'Отменить всё',
            ECUS_EXPORT_PDF:            'Скачать PDF',
            ECUS_EXPORT_EMAIL:          'Отправить по e-mail',
            ECUS_EXPORT_EMAIL_LABEL:    'E-mail',
            ECUS_EXPORT_EMAIL_SUCCESS:  'Файл с результатами диагностики успешло отправлен на указанный e-mail',
            ECUS_EXPORT_ERROR_ECU:      'Не выбран ни один блок',
            ECUS_EXPORT_ERROR_EMAIL:    'Не указан e-mail для отправки отчёта',

            ECUS_DIAG_REFRESH:          'Обновить отчёт',
            ECUS_DIAG_REFRESH_SUCCESS:  'Диагностика запущена. Файлы отчётов будут обновлены',
            ECUS_DIAG_CLEAR_DTC:        'Очистить ошибки',
            DIAG_IMPORT_EMAIL_SUCCESS:  'Файл с отчётами диагностики успешно отправлен на указанный e-mail',

            // Пользователи автомобиля
            CARUSERS_EMPTY:             'У этого автомобиля нет пользователей',
            CARUSERS_CREATE:            'Пользователи',
            CARUSERS_CREATE_SUCCESS:    'Пользователи автомобиля обновлены',
            CARUSERS_DELETE:            'Удалить пользователя',
            CARUSERS_DELETE_TEXT:       'Этот пользователь потеряет доступ к информации об автомобиле',
            CARUSERS_DELETE_SUCCESS:    'Доступ пользователя к автомобилю заблокирован',

            // Типы кузовов
            CARTYPES:                   'Типы кузовов',
            CARTYPE_NEW:                'Новый тип',
            CARTYPE_BODY:               'Тип',
            CARTYPE_TYPE:               'Модификация',
            CARTYPE_UNDEFINED:          'Неизвестный тип',
            CARTYPE_CREATE_SUCCESS:     'Новый тип кузова создан',
            CARTYPE_UPDATE_SUCCESS:     'Тип кузова обновлён',
            CARTYPE_DELETE:             'Удаление типа кузова',
            CARTYPE_DELETE_TEXT:        'Этот тип кузова будет удалён без возможности восстановления. Вы уверены, что хотите удалить его?',
            CARTYPE_DELETE_SUCCESS:     'Тип кузова удалён',

            // События
            EVENTS:                     'События',
            EVENTS_START:               'Дата начала событий',
            EVENTS_END:                 'Дата окончания событий',
            EVENTS_ERROR_NO_LIMITS:     'Укажите даты начала и конца событий',
            EVENTS_ERROR_INVALID_LIMITS: 'Дата начала событий должна быть раньше даты конца',
            EVENTS_ERROR_MAX_PERIOD:    'Максимальный интервал для запроса событий - 31 день',
            EVENTS_EVENT_ON_MAP:        'Событие на карте',
            EVENTS_EVENT:               'Событие',
            EVENTS_EVENT_DATE:          'Дата события',
            EVENTS_REQUEST:             'Запросить',
            EVENTS_MAP:                 'Карта',

            // Файлы
            FILES:                      'Файлы',

            // Статусы онлайн
            ONLINE:                     'В сети',
            NOSATELLITES:               'В сети',
            OFFLINE:                    'Не в сети',

            // Команды автомобиля
            CMD_SEND_HEADER:                'Отправка команд',
            CMD_SEND:                       'Послать',
            CMD_SET_SERVER:                 'Установить сервер данных',
            CMD_RELOAD:                     'Перезагрузить SDM',
            CMD_SEND_SUCCESS:               'Команда отправлена',
            CMD_SUCCESS:                    'Успешно',
            CMD_FAILURE:                    'Ошибка',
            CMD_RESULT_NOT_SET:             'Статус не установлен',
            CMD_RESULT_SEND_IN_PROCESS:     'Команда отправлена на автомобиль',
            CMD_RESULT_SEND_TIMEOUT:        'Время подтверждения получения команды от автомобиля, превысило время ожидания',
            CMD_RESULT_SEND_CONFIRMED:      'Получено подтверждение получения команды от автомобиля',
            CMD_RESULT_RESULT_OK:           'Автомобиль прислал результат выполнения команды: успешное выполнение',
            CMD_RESULT_RESULT_ERROR:        'Автомобиль прислал результат выполнения команды: ошибка',
            CMD_RESULT_RESULT_ILL:          'Автомобиль прислал результат выполнения команды: ошибка, отсутствие в списке разрешённых команд',
            CMD_RESULT_RESULT_DEL:          'Автомобиль прислал результат выполнения команды: успешно удалена',
            CMD_RESULT_RESULT_NOT_FOUND:    'Автомобиль прислал результат выполнения команды: команда для удаления не найдена',
            CMD_RESULT_RESULT_NOT_CONFIG:   'Автомобиль прислал результат выполнения команды: успешное выполнение, отрицательный ответ',
            CMD_RESULT_RESULT_IN_PROCESS:   'Автомобиль прислал результат выполнения команды: команда передана на обработку',


            // Запрос треков
            CARTRACK:                       'Просмотр треков поездок',
            CARTRACK_START:                 'Начало периода',
            CARTRACK_END:                   'Конец периода',
            CARTRACK_EMPTY:                 'В этот период автомобиль не был в движении',
            CARTRACK_LIMIT:                 'Выберите период времени для запроса маршрута передвижения автомобиля [не более 31 дня]',
            CARTRACK_ERROR_MAX_PERIOD:      'Максимальный интервал для запроса маршрута - 31 день',
            CARTRACK_ERROR_INVALID_LIMITS:  'Дата начала маршрута должна быть раньше даты конца',
            CARTRACK_ERROR_NO_LIMITS:       'Укажите даты начала и конца маршрута',

            // Тип связи с автомобилем
            CONNECTION_OFF:             'Нет связи',
            CONNECTION_ON:              'На связи',
            CONNECTION_LTE:             'LTE',
            CONNECTION_UMTS:            'UMTS',
            CONNECTION_GSM:             'GSM',
            
            // Управление картой
            MAP_SIZE_FULL:              'Развернуть',
            MAP_SIZE_SMALL:             'Свернуть',
            MAP_LAYERS_SHOW:             'Показать специальные карты',
            MAP_LAYERS_HIDE:            'Скрыть специальные карты',
            MAP_CMD:                    'Отправить команду',
            MAP_CAR_TRACK:              'История маршрутов',
            MAP_CAR_TRACK_RESET:        'Сбросить маршрут',
            MAP_CAR_TRACK_MILEAGE:      'Пробег маршрута',

            // Модули EGTS
            EGTS_MODULES_MODULE_MAIN:   'Основной модуль',
            EGTS_MODULES_MODULE_INPUT:  'Модуль ввода/вывода',
            EGTS_MODULES_MODULE_NAVI:   'Навигационный модуль',
            EGTS_MODULES_MODULE_LINK:   'Модуль связи',
            EGTS_MODULES_VENDOR:        'Код производителя',
            EGTS_MODULES_FIRMWARE:      'Аппаратная версия',
            EGTS_MODULES_SOFTWARE:      'Версия ПО',
            EGTS_MODULES_MODIFICATION:  'Модификация',
            EGTS_MODULES_STATE:         'Состояние',
            EGTS_MODULES_STATE_1:       'Включен',
            EGTS_MODULES_STATE_0:       'Выключен',
            EGTS_MODULES_STATE_127:     'Неисправность',
            EGTS_MODULES_SERIAL:        'Серийный №',
            EGTS_MODULES_DESCR:         'Описание',
            EGTS_MODULES_DATE:          'Дата выпуска',

            // Статусы ошибок EGTS
            EGTS_PC_OK:                 'Успешно обработано',
            EGTS_PC_IN_PROGRESS:        'В процессе обработки',
            EGTS_PC_UNS_PROTOCOL:       'Неподдерживаемый протокол',
            EGTS_PC_DECRYPT_ERROR:      'Ошибка декодирования',
            EGTS_PC_PROC_DENIED:        'Обработка запрещена',
            EGTS_PC_INC_HEADERFORM:     'Неверный формат заголовка',
            EGTS_PC_INC_DATAFORM:       'Неверный формат данных',
            EGTS_PC_UNS_TYPE:           'Неподдерживаемый тип',
            EGTS_PC_NOTEN_PARAMS:       'Неверное количество параметров',
            EGTS_PC_DBL_PROC:           'Попытка повторной обработки',
            EGTS_PC_PROC_SRC_DENIED:    'Обработка данных от источника запрещена ',
            EGTS_PC_HEADERCRC_ERROR:    'Ошибка контрольной суммы заголовка',
            EGTS_PC_DATACRC_ERROR:      'Ошибка контрольной суммы данных',
            EGTS_PC_INVDATALEN:         'Не корректная длина данных',
            EGTS_PC_ROUTE_NFOUND:       'Маршрут не найден',
            EGTS_PC_ROUTE_CLOSED:       'Маршрут закрыт',
            EGTS_PC_ROUTE_DENIED:       'Маршрутизация запрещена',
            EGTS_PC_INVADDR:            'Неверный адрес',
            EGTS_PC_TTLEXPIRED:         'Превышено количество ретрансляции данных',
            EGTS_PC_NO_ACK:             'Нет подтверждения',
            EGTS_PC_OBJ_NFOUND:         'Объект не найден',
            EGTS_PC_EVNT_NFOUND:        'Событие не найдено',
            EGTS_PC_SRVC_NFOUND:        'Сервис не найден',
            EGTS_PC_SRVC_DENIED:        'Сервис запрещен',
            EGTS_PC_SRVC_UNKN:          'Неизвестный тип сервиса',
            EGTS_PC_AUTH_DENIED:        'Авторизация запрещена',
            EGTS_PC_ALREADY_EXISTS:     'Объект уже существует',
            EGTS_PC_ID_NFOUND:          'Идентификатор не найден',
            EGTS_PC_INC_DATETIME:       'Неправильная дата и время',
            EGTS_PC_IO_ERROR:           'Ошибка ввода/вывода',
            EGTS_PC_NO_RES_AVAIL:       'Недостаточно ресурсов',
            EGTS_PC_MODULE_FAULT:       'Внутренний сбой модуля',
            EGTS_PC_MODULE_PWR_FLT:     'Сбой в работе цепи питания модуля',
            EGTS_PC_MODULE_PROC_FLT:    'Сбой в работе микроконтроллера модуля',
            EGTS_PC_MODULE_SW_FLT:      'Сбой в работе программы модуля',
            EGTS_PC_MODULE_FW_FLT:      'Сбой в работе внутреннего ПО модуля',
            EGTS_PC_MODULE_IO_FLT:      'Сбой в работе блока ввода/вывода модуля',
            EGTS_PC_MODULE_MEM_FLT:     'Сбой в работе внутренней памяти модуля',
            EGTS_PC_TEST_FAILED:        'Тест не пройден',

            /**
             * Состояние автомобиля
             * 
             * "STATE_" + имя состояния в верхнем регистре
             */

            STATE_TABS_LAST_TRIP:               'Путевая статистика',
            STATE_TABS_WHEELS:                  'Давление шин',
            STATE_TABS_BRAKES:                  'Тормозная система',
            STATE_TABS_CLIMATE:                 'Климатическая система',
            STATE_TABS_TRANSMISSION:            'АКПП',
            STATE_TABS_BATTERY:                 'АКБ',
            STATE_TABS_LIGHT:                   'Освещение',
            STATE_TABS_ENGINE:                  'Двигатель',
            STATE_TABS_DOORS:                   'Двери/кузов',
            STATE_TABS_ELECTRIC:                'АКБ',

            STATE_DMFL_WARNING_INDICATOR:       'Состояние блока двери [передняя левая]', 
            STATE_DMFR_WARNING_INDICATOR:       'Состояние блока двери [передняя правая]', 
            STATE_DMRL_WARNING_INDICATOR:       'Состояние блока двери [задняя левая]', 
            STATE_DMRR_WARNING_INDICATOR:       'Состояние блока двери [задняя правая]', 

            STATE_DOOR_FRONT_LEFT_CLOSED:       'Замок двери [водитель]', 
            STATE_DOOR_FRONT_RIGHT_CLOSED:      'Замок двери [передний пассажир]', 
            STATE_DOOR_BACK_LEFT_CLOSED:        'Замок двери [задний левый пассажир]', 
            STATE_DOOR_BACK_RIGHT_CLOSED:       'Замок двери [задний правый пассажир]', 

            STATE_CORNERING_LIGHT_RIGHT_STATUS: 'Состояние правого блока адаптивного освещения', 
            STATE_CORNERING_LIGHT_LEFT_STATUS:  'Состояние левого блока адаптивного освещения', 

            STATE_T30I_FAULT_STATUS:            'Состояние линии Т30i', 
            STATE_T15_FAULT_STATUS:             'Состояние линии Т15', 
            STATE_T30D_FAULT_STATUS:            'Состояние линии Т30d', 

            STATE_BRAKE_LIGHT_LEFT_STATUS:      'Состояние левого стоп-сигнала', 
            STATE_BRAKE_LIGHT_RIGHT_STATUS:     'Состояние правого стоп-сигнала', 
            STATE_BRAKE_LIGHT_HM_STATUS:        'Состояние дополнительного стоп-сигнала', 

            STATE_WHEEL_BACK_RIGHT_STATUS:      'Датчик давления шин [передний задний]', 
            STATE_WHEEL_BACK_LEFT_STATUS:       'Датчик давления шин [задний левый]', 
            STATE_WHEEL_FRONT_LEFT_STATUS:      'Датчик давления шин [передний левый]', 
            STATE_WHEEL_FRONT_RIGHT_STATUS:     'Датчик давления шин [передний правый]', 

            STATE_WHEEL_FRONT_LEFT_PRESSURE:    'Давление шин [передняя левая]', 
            STATE_WHEEL_FRONT_RIGHT_PRESSURE:   'Давление шин [передняя правая]', 
            STATE_WHEEL_BACK_LEFT_PRESSURE:     'Давление шин [задняя левая]', 
            STATE_WHEEL_BACK_RIGHT_PRESSURE:    'Давление шин [задняя правая]', 

            STATE_TURN_INDICATOR_ML_STATUS:     'Состояние левого поворотника на зеркале', 
            STATE_TURN_INDICATOR_MR_STATUS:     'Состояние правого поворотника на зеркале', 

            STATE_WINDOW_FULLY_CLOSED_FL:       'Окно [переднее левое]', 
            STATE_WINDOW_FULLY_CLOSED_FR:       'Окно [переднее правое]', 
            STATE_WINDOW_FULLY_CLOSED_RL:       'Окно [заднее левое окно]', 
            STATE_WINDOW_FULLY_CLOSED_RR:       'Окно [заднее правое]', 
            STATE_WINDOW_OPEN:                  'Окно открыто',

            STATE_TPMS_BATTERY_STATUS:          'Состояние батареи датчика давления шины',
            STATE_TPMS_BATTERY_STATUS_RR:       'Состояние батареи датчика давления шины ЗП',
            STATE_TPMS_BATTERY_STATUS_RL:       'Состояние батареи датчика давления шины ЗЛ', 
            STATE_TPMS_BATTERY_STATUS_FR:       'Состояние батареи датчика давления шины ПП', 
            STATE_TPMS_BATTERY_STATUS_FL:       'Состояние батареи датчика давления шины ПЛ',  

            STATE_WHEEL_PRESSURE_STATUS_HIGH:   'Высокое давление',
            STATE_WHEEL_PRESSURE_STATUS_NORMAL: 'Нормальное давление',
            STATE_WHEEL_PRESSURE_STATUS_LOW:    'Низкое давление',
            STATE_WHEEL_PRESSURE_STATUS_CRITICAL: 'Критически низкое давление!',
            STATE_WHEEL_PRESSURE_STATUS_ERROR:  'Ошибка!',

            STATE_ENGINE_TIME:                  'Моточасы',

            STATE_RIDE_AVERAGE_SPEED: 'Ср. скорость за поездку', 
            STATE_TEMPERATURE_OUTSIDE: 'Температура снаружи', 
            STATE_BACK_LEFT_TEMPERATURE: 'Температура [климат-контроль; задний левый пассажир]', 
            STATE_OIL_PRESSURE_WARNING: 'Индикатор давления масла в двигателе', 
            STATE_IS_LOCKED: 'Статус замков дверей', 
            STATE_DOOR_FRONT_RIGHT: 'Дверь переднего пассажира', 
            STATE_FRONT_INSIDE_TEMPERATURE: 'Температура [внутри салона; передняя]', 
            STATE_FRONT_RIGHT_TEMPERATURE: 'Температура [климат-контроль; передний пассажир]', 
            STATE_STATUS: 'Статус автомобиля', 
            STATE_OIL_LEVEL: 'Уровень масла двигателя', 
            STATE_MIL_ONRQ: 'Индикатор ошибки двигателя', 
            STATE_POSITION_LIGHT_FR_STATUS: 'Состояние переднего правого габаритного огня', 
            STATE_HEAD_LIGHT_LEVELING_CTRL_FAILURE: 'Состояние системы автокоррекции фар', 
            STATE_LTC_COOLANT_LVL: 'Уровень охлаждающей жидкости высоковольтной системы', 
            STATE_BACK_INSIDE_TEMPERATURE: 'Температура [внутри салона; задняя]', 
            STATE_RECEIVED_SIGNAL_STRENGTH_INDICATOR: 'Received signal strength indicator', 
            STATE_DOOR_BACK_LEFT: 'Дверь заднего левого пассажира', 
            STATE_REVERSE_LIGHT_RIGHT_STATUS: 'Состояние правого сигнала заднего хода', 
            STATE_FRONT_LEFT_TEMPERATURE: 'Температура [климат-контроль; водитель]', 
            STATE_START_MOVING: 'Время начала движения', 
            STATE_STOP_MOVING: 'Время остановки автомобиля', 
            // STATE_VEHICLE_SECURE_LOCK_STATE: 'Статус блокировки замков', // Стейт удалён на стороне бекенда
            STATE_REAR_FOG_LIGHT_STATUS: 'Состояние задней ПТФ', 
            STATE_ABS_LAMP: 'Состояние АБС', 
            STATE_FRONT_RIGHT_FOG_LIGHT_STATUS: 'Состояние передней правой ПТФ', 
            STATE_POSITION_LIGHT_RR_STATUS: 'Состояние заднего правого габаритного огня', 
            STATE_TURN_INDICATOR_RR_STATUS: 'Состояние правого заднего поворотника', 
            STATE_LOW_BEAM_RIGHT_STATUS: 'Состояние правой фары ближнего света', 
            STATE_EPB_STATUS: 'Ручник', 
            STATE_ESP_LAMP: 'Состояние ESP', 
            STATE_TPMS_SENSOR_FAULT_FR: 'Ошибка датчика давления шины ПП', 
            STATE_TPMS_SENSOR_FAULT_RL: 'Ошибка датчика давления шины ЗЛ', 
            STATE_RIDE_MILEAGE: 'Пробег за поездку', 
            STATE_LAST_NAVIGATION_TIME: 'Последнее обновление навигации', 
            STATE_HV_SYSTEM_FAILURE: 'Высоковольтная система', 
            STATE_FUEL: 'Уровень топлива', 
            STATE_FRONT_LEFT_FOG_LIGHT_STATUS: 'Состояние передней левой ПТФ', 
            STATE_TURN_INDICATOR_FR_STATUS: 'Состояние правого переднего поворотника', 
            STATE_POSITION_LIGHT_RL_STATUS: 'Состояние заднего левого габаритного огня', 
            STATE_BCM_WARNING_INDICATOR: 'Состояние модуля кузовной электроники', 
            STATE_ONLINE: 'Online', 
            STATE_ENGINE_ON: 'Время запуска двигателя', 
            STATE_DAYTIME_LIGHT_RIGHT_STATUS: 'Состояние правого дневного ходового огня', 
            STATE_DOOR_TRUNK: 'Багажник', 
            STATE_MOBILE_NETWORK_TYPE_INDICATOR: 'Mobile network type indicator', 
            STATE_POSITION_LIGHT_FL_STATUS: 'Состояние переднего левого габаритного огня', 
            STATE_TPMS_SENSOR_FAULT_FL: 'Ошибка датчика давления шины ПЛ', 
            STATE_DAYTIME_LIGHT_LEFT_STATUS: 'Состояние левого дневного ходового огня', 
            STATE_DOOR_FRONT_LEFT: 'Дверь водителя', 
            STATE_DOOR_HOOD: 'Капот', 
            STATE_TPMS_SENSOR_FAULT_RR: 'Ошибка датчика давления шины ЗП', 
            STATE_ENGINE_OIL_TEMP: 'Температура [масло в двигателе]', 
            STATE_ENGINE_REV: 'Обороты двигателя', 
            STATE_TCS_LAMP: 'Состояние TCS', 
            STATE_EBD_LAMP: 'Состояние EBD', 
            STATE_TURN_INDICATOR_RL_STATUS: 'Состояние левого заднего поворотника', 
            // STATE_MILEAGE_ON_FUEL_RESIDUES: 'Пробег на остатке топлива', // Старый параметр
            STATE_HIGH_BEAM_LEFT_STATUS: 'Состояние левой фары дальнего света', 
            STATE_ENGINE_TEMPERATURE: 'Температура [охлаждающая жидкость]', 
            STATE_RIDE_TIME: 'Время в пути за поездку', 
            STATE_ENGINE_OFF: 'Время остановки двигателя', 
            STATE_WASHER_FLUID: 'Уровень омывающей жидкости', 
            STATE_WASHER_FLUID_WARNING: 'Низкий уровень омывающей жидкости',
            STATE_TOTAL_MILEAGE: 'Общий пробег', 
            STATE_BACK_RIGHT_TEMPERATURE: 'Температура [климат-контроль; задний правый пассажир]', 
            STATE_MIL_IND_BLINK: 'Индикатор критической ошибки двигателя', 
            STATE_COOLANT_FLUID: 'Уровень охлаждающей жидкости', 
            STATE_LOW_BEAM_LEFT_STATUS: 'Состояние левой фары ближнего света', 
            STATE_RIDE_FUEL_CONSUMPTION: 'Ср. расход за поездку', 
            STATE_ENGINE_OIL_LVIND: 'Индикатор уровня масла в двигателе', 
            STATE_TURN_INDICATOR_FL_STATUS: 'Состояние левого переднего поворотника', 
            STATE_DOOR_TRUNK_DIRECTION: 'Door trunk direction', 
            STATE_HIGH_BEAM_RIGHT_STATUS: 'Состояние правой фары дальнего света', 
            STATE_ESP_SPEED: 'Скорость', 
            STATE_LAST_CAN_PARAM_TIME: 'Последнее обновление параметров автомобиля', // Обновление Body-CAN
            STATE_TM_WARNING_INDICATOR: 'Состояние багажника', 
            STATE_REVERSE_LIGHT_LEFT_STATUS: 'Состояние левого сигнала заднего хода', 
            STATE_DOOR_BACK_RIGHT: 'Дверь заднего правого пассажира', 
            STATE_SERVICE_DATE: 'Дней до ТО',
            STATE_GEAR_POSITION: 'Положение рычага АКПП',
            STATE_LAST_DIAGNOSTICS_TIME: 'Последнее обновление диагностики', // Обновление Diag-CAN
            STATE_LAST_ONLINE: 'Последний выход на связь',
            STATE_SERVICE_MILEAGE: 'Пробег до ТО',
            STATE_ST_TRANSMISSION_WARNING: 'Состояние АКПП',
            STATE_SCU_CALIBRATION_STATUS: 'Состояние калибровки подвески',
            STATE_CCU_FAILURE: 'Состояние климатической системы',
            STATE_SCU_FAULT_STATUS: 'Состояние подвески',
            STATE_ERAG_FAILURE_STATUS: 'Состояние системы экстренного вызова',
            STATE_OPEN: 'Открыто',
            STATE_HALF_OPEN: 'Открыт наполовину',
            STATE_CLOSED: 'Закрыто',
            STATE_NOT_CLOSED: 'Не закрыто',
            STATE_RESERV: '(резерв)',
            STATE_MILEAGE_WARNING: 'ВНИМАНИЕ! Пробег, записанный в этом электронном блоке существенно отличается от пробега, хранящегося в электронном блоке ESP',
            STATE_SDM_UPTIME: 'Последнее включение/перезапуск', 
            STATE_VAS_ACTIVATION: 'Состояние охранной сигнализации', 
            STATE_LOCK_SECURE: 'На охране',
            STATE_CRUISING_RANGE: 'Пробег на остатке топлива', 

            // ПАРАМЕТРЫ ПОСЛЕ СБРОСА БК
            STATE_TOTAL_MILEAGE_AM:     'Пробег после сброса',
            STATE_TOTAL_AVG_SPEED_AM:   'Ср. скорость после сброса',
            STATE_TOTAL_MINS_AM:        'Время в пути после сброса',
            STATE_FUEL_CONSUMPTION:     'Ср. расход после сброса', 
            
            // АКБ / БАТАРЕЯ
            STATE_BAT_CAPACITY:     'Емкость',
            STATE_BAT_HEALTH:       'Здоровье',
            STATE_BAT_STATUS:       'Состояние',
            STATE_BAT_VOLTAGE:      'Напряжение',
            STATE_LVB_SOH:          'Здоровье АКБ 12В',
            STATE_LVB_SOC:          'Емкость АКБ 12В',
            STATE_BATTERY_STATUS:   'Состояние АКБ 12В', 
            STATE_BATTERY_VOLTAGE:  'Напряжение АКБ 12В', 
            STATE_SOH_HV_AKB:       'Здоровье ВВ АКБ',
            STATE_SOC_HV_AKB:       'Емкость ВВ АКБ',

            STATE_COMMON_RIDE_TIME:         'Время в пути', 
            STATE_COMMON_MILEAGE:           'Пробег', 
            STATE_COMMON_FUEL_CONSUMPTION:  'Средний расход', 
            STATE_COMMON_AVERAGE_SPEED:     'Средняя скорость', 

            // Графики архивных показаний датчиков
            // Компонент ADTStatesHistory
            HISTORY:                        'Архив показаний',
            HISTORY_DESCR:                  'Просмотр истории показаний приборов и датчиков за выбранный промежуток времени',
            HISTORY_REQUEST_TEXT:           'Выберите время начала и окончания показаний',
            HISTORY_START:                  'Начало',
            HISTORY_END:                    'Конец',
            HISTORY_SEARCH:                 'Поиск по названию...',
            HISTORY_PARAMS:                 'Параметры',
            HISTORY_TIME:                   'Время',
            HISTORY_ERROR_PARAMS:           'Параметры не выбраны!',
            HISTORY_ERROR_RANGE:            'Максимальный интервал для запроса графиков - 31 день',
            HISTORY_ERROR_TIME_INCORRECT:   'Время начала записи графиков должно быть раньше времени окончания',
            HISTORY_ERROR_TIME_EMPTY:       'Укажите даты начала и конца маршрута',
            HISTORY_EMPTY_POINTS:           'Нет записанных точек за указанный интервал времени',
            HISTORY_EMPTY_VALUE:            'Нет записанных состояний за указанный интервал времени',
            HISTORY_SHOW_GRID:              'Показать сетку',
            HISTORY_INSTRUCTION:            'Кликните на графике левой кл.мыши, затем потяните для увеличения выбранной области. Для перемещения графика зажмите Ctrl',
            HISTORY_INSTRUCTION_PANNING:    'Для увеличения масштаба растяните область на графике. Для сдвига графика проведите по нему двумя пальцами',

            // События
            // Компонент ADTEvents
            EVENT_VIN:                  'VIN',
            EVENT_TYPE:                 'Событие',
            EVENT_SEARCH:               'Поиск событий',
            EVENT_DATE:                 'Дата',
            EVENT_MAP:                  'На карте',
            EVENT_COORDS:               'Координаты',
            EVENT_GOTO_CAR:             'Перейти к автомобилю',
            EVENT_ENGINE_ON:            'Включение двигателя',
            EVENT_REMOTE_ENGINE_ON:     'Удаленное включение двигателя',
            EVENT_ENGINE_OFF:           'Выключение двигателя',
            EVENT_DECREASE_MILEAGE:     'Уменьшение общего пробега',
            EVENT_PROTECTED_AREA_IN:    'Вход в охранную зоны',
            EVENT_PROTECTED_AREA:       'Выход из охранной зоны',
            EVENT_UNLOCKED:             'Снятие автомобиля с охраны',
            EVENT_LOCKED:               'Постановка автомобиля под охрану',
            EVENT_CMD_ENGINE_ON:        'Отправлена команда на запуск двигателя',
            EVENT_CMD_ENGINE_OFF:       'Отправлена команда на остановку двигателя',
            EVENT_CMD_UNLOCK:           'Отправлена команда на снятие с охраны',
            EVENT_CMD_LOCK:             'Отправлена команда на постановку под охрану',
            EVENT_CMD_TRUNK_OPEN:       'Отправлена команда на открытие багажника',
            EVENT_CMD_TRUNK_CLOSE:      'Отправлена команда на закрытие багажника',
            EVENT_CMD_LIGHT:            'Отправлена команда мигнуть фарами',
            EVENT_CMD_BEEP:             'Отправлена команда подать звуковой сигнал',
            EVENT_LOW_BATTERY_VOLTAGE:  'Низкий заряд аккумулятора',
            EVENT_ALARM_SYSTEM_TRIGGERED:'Срабатывание штатной сигнализации',

            // События на карте
            MAP_EVENTS:                 'События',
            MAP_EVENTS_DESCR:           'Выберите группы событий для отображения на карте',
            MAP_EVENTS_SECURE:          'Охрана',
            MAP_EVENTS_SECURE_ADD:      'Постановка/снятие',
            MAP_EVENTS_ENGINE:          'Двигатель',
            MAP_EVENTS_ENGINE_ADD:      'Включение/выключение',
            MAP_EVENTS_TRUNK:           'Багажник',
            MAP_EVENTS_TRUNK_ADD:       'Открытие/закрытие',
            MAP_EVENTS_ZONE:            'Охранная зона',
            MAP_EVENTS_ZONE_ADD:        'Вход/выход',
            MAP_EVENTS_LIGHT:           'Освещение и сигнал',
            MAP_EVENTS_MILEAGE:         'Уменьшение пробега',
            MAP_EVENTS_VOLTAGE:         'Низкий заряд АКБ',
            MAP_EVENTS_ALL:             'Показать все',
            MAP_EVENTS_ALARM_SYSTEM_TRIGGERED:'Срабатывание штатной сигнализации',

            // Ошибки приложения
            ERROR:                      'Ошибка',
            ENotFound:                  'Не найдено',
            EUnknown:                   'Системная ошибка [#2]. Сообщите оператору!', // Неизвестная (неописанная) ошибка
            ECursor:                    'Системная ошибка [#3]. Сообщите оператору!', // Ошибка при передачи курсора листаемого списка
            EQuery:                     'Системная ошибка [#4]. Сообщите оператору!', // Ошибка запроса (попытка отправки в запросе недопустимых символов)
            EUuid:                      'Системная ошибка [#5]. Сообщите оператору!', // Передан неверный или пустой UUID
            EUpdate:                    'Ошибка обновления',
            ECaptchaExpired:            'Обновите проверочный код и повторите ввод',
            ECaptchaCheckError:         'Введён неверный проверочный код',
            ECaptchaError:              'Ошибка получения проверочного кода',
            ECodeExpired:               'Проверочный код устарел, повторите процедуру восстановления пароля ещё раз',
            ECodeCheckError:            'Введён неверный проверочный код',
            ECodeError:                 'Ошибка получения проверочного кода',
            ETokenExpired:              'Неверный ключ доступа, авторизуйтесь в приложении',
            ETokenError:                'Не удалось получить токен для доступа к сервису; попробуйте обновить страницу или авторизуйтесь в приложении заново',
            EUserEmail:                 'Введён неверный e-mail',
            EUserPassword:              'Введён неверный пароль',
            EPassword:                  'Введённый пароль не соответствует минимальным требованиям безопасности',
            EUserEmailAlreadyExist:     'Этот e-mail уже указан для одного из пользователей',
            EUserNotFound:              'Ошибка в имени пользователя или пароле',
            EUserEmptyPasswordOrCode:   'Не указан пароль или проверочный код',
            EUserPermissionDenied:      'У Вас нет доступа к этой информации',
            EUserFromContext:           'Системная ошибка [#3]. Сообщите оператору!', // User value not stored in ctx [???]
            EUserSelfDelete:            'Вы не можете удалить собственный аккаунт',
            EUserSendCode:              'Ошибка проверки проверочного кода',
            EUserAlreadyDeleted:        'Пользователь, которого Вы пытаетесь изменить был удалён',
            EUserCarsNotFound:          'Нет доступных автомобилей',
            EConnection:                'Ошибка запроса: потеряно соединение с Интернет или сервер недоступен',
            EWSNotSuport:               'Ваш браузер не поддерживает технологию WebSocket',
            EWSError:                   'Неизвестная ошибка, сообщите оператору!',
            EWSDisconnected:            'Сервер данных недоступен',
            EGarageNumber:              'Некорректный гаражный номер',
            EStartAfterStopOrEqual:     'Время начала мониторинга больше или равно времени окончания',

            ECommandSendTimeOut:                            'Команда не отправлена [время ожидания отправки превышено]',
            ECommandNotFound:                               'Команда не найдена',
            EEgtsParamNotFound:                             'Параметр команды не распознан',
            ECommandSendError:                              'Ошибка при попытке отправки команды',
            EEgtsModuleNotFound:                            'Модуль EGTS не найден',
            ECommandDataOverflow:                           'Превышен лимит размера тела команды',
            ECommandResponseUnknown:                        'При отправке команды произвошла неизвестная ошибка',
            ECommandResponseNotRespond:                     'Электронный блок не отвечает',
            ECommandResponseUnknownCommand:                 'Команда не распознана',
            ECommandResponseUnknownAddress:                 'Неизвестный адрес запрашиваемого электронного блока',
            ECommandResponseIgnitionIsOff:                  'Для выполнения этой команды нужно включить зажигание',
            ECommandResponseIgnitionIsOn:                   'Для выполнения этой команды нужно выключить зажигание',
            ECommandResponseDoorIsUnlock:                   'Для выполнения этой команды нужно заблокировать двери',
            ECommandResponseLowFuelLevel:                   'Для выполнения этой команды нехватает топлива в баке',
            ECommandResponseLowSoC:                         'Для выполнения этой команды нехватает заряда АКБ',
            ECommandResponseEngineRemoteStartNotSupport:    'Удаленный запуск двигателя не поддерживается',
            ECommandResponseEngineRemoteStartModeNeeded:    'Для выполнения этой команды переведите автомобиль в режим удаленного запуска',
            ECommandResponseParkingModeOfTCUNeeded:         'Для выполнения этой команды переведите селектор КПП в режим Parking',
            
            EEgtsFirmwareNotFound:      'Файлы прошивок не найдены',
            EEventNotFound:             'Записи событий за указанный период времени не найдены',
            EFileName:                  'Файл с указанным именем не найден',
            EEgtsReportNotFound:        'Записей диагностики не найдено',
            EPhone:                     'Ошибка при вводе номера телефона',
            ECarPermissionGranted:      'Доступ ко всем автомобилям',
            EUnauthRequest:             'Неверный ключ доступа, авторизуйтесь в приложении',
            EFormFields:                'Ошибка заполнения формы',
            EVin:                       'Не указан VIN-код',
            EUserAlreadyExist:          'Пользователь с этим именем или телефоном уже существует',
            EEmail:                     'Недопустимые символы в поле E-mail',
            EFavoriteFound:             'Нет избранных автомобилей. Вы можете назначать избранные автомобили, нажав на иконку в виде звезды в карточке автомобиля из списка.',
            EPolicyRuleNotFound:        'Нет правил',
            EPolicyOneNotFound:         'Нет политик',
            EPolicySetNotFound:         'Нет наборов',

            // Автомобили и кузова
            ECarAlreadyDeleted:         'Этот автомобиль был удалён ранее',
            ECarAlreadyExist:           'Такой автомобиль уже существует',
            ECarNumber:                 'Некорректный номер автомобиля',
            ECarPointNotFound:          'Записей маршрута за выбранный промежуток времени не найдено',
            ECarUserNotFound:           'Пользователи автомобиля не найдены',
            ECarNotOnLine:              'Устройство не в сети',
            ECarPermissionDenied:       'Вы не имеете доступ к этой информации',
            ECarNotFound:               'Автомобили с указанными данными не найдены',
            ECarTypeNotFound:           'Типы кузовов с указанными параметрами не найдены',
            ECarTypeCursor:             'Ошибка получения типов кузовов',
            ECarVin:                    'Автомобиль с таким VIN уже существует',
            ECarTypeAlreadyExist:       'Этот тип кузова уже создан',
            ECarPointAlreadyExist:      'Эта точка уже существует',
            ECarStateNotFound:          'Состояние не определено',
            ELogNotFound:               'Логи не найдены',

            // Онлайн телеметрия
            CHART_ONLINE_TEST:          'Компонент работает в тестовом режиме. Данные на графиках создаются имитатором',
            CHART_ONLINE_TITLE:         'Телеметрия онлайн',
            CHART_ONLINE_SEARCH:        'Поиск параметра',
            CHART_ONLINE_SEARCH_DESCR:  'Введите имя или часть имени параметра',
            CHART_ONLINE_SHOW_GRID:     'Показать сетку',
            CHART_ONLINE_ERROR_TOKEN:   'Ошибка авторизации на сервере данных',
            CHART_ONLINE_ERROR_1:       'Ошибка разбора полученных данных',
            CHART_ONLINE_TIMEOUT:       'Устройство не отвечает: возможно, оно занято. Попробуйте запросить данные позже',
            CHART_ONLINE_PERIOD:        'Частота обновления [сек.]',

            // Логи
            LOGS:                       'Логи',
            LOG:                        'Лог',
            LOGS_TYPE:                  'Тип лога',
            LOGS_TYPE_EGTS:             'Автомобили',
            LOGS_TYPE_GRPC:             'Пользователи',
            LOGS_VIN:                   'VIN',
            LOGS_VIN_MODAL:             'Выберите автомобиль',
            LOGS_USER_MODAL:            'Выберите пользователя',
            LOGS_DATE:                  'Дата',
            LOGS_USER:                  'Пользователь',
            LOGS_USER_ALL:              'Все пользователи',
            LOGS_ACTION:                'Действие',
            LOGS_ACTION_LOGIN:          'Авторизация',
            LOGS_ACTION_DELETE:         'Удаление',
            LOGS_ACTION_INSERT:         'Вставка',
            LOGS_ACTION_UPDATE:         'Обновление',
            LOGS_ACTION_WRITE:          'Запись',
            LOGS_ACTION_SEND_CMD:       'Отправка команд',
            LOGS_ACTION_SEND_NOTI:      'Отправка уведомлений',
            LOGS_ACTION_SEND_PARAMS:    'Отправка параметра',
            LOGS_ITEM_DATE:             'Дата',
            LOGS_ITEM_GEO:              'Геоданные',
            LOGS_ERROR_NO_PARAMS:       'Выберите параметры для запроса логов',

            // Цвета кузовов
            CAR_COLOR_BLACK_COPPER:     "Черная медь",
            CAR_COLOR_BLUE_MAGIC:       "Яркий голубой",
            CAR_COLOR_DEEP_FOREST:      "Темно-зеленый",
            CAR_COLOR_DESERT_RED:       "Красная пустыня",
            CAR_COLOR_DESERT_TAUPE:     "Серо-коричневый",
            CAR_COLOR_GALANT_GREY:      "Темно-серый",
            CAR_COLOR_GRAPHITE_TRACE:   "Графитовый",
            CAR_COLOR_LATTE_MACCHIATO:  "Кофейный",
            CAR_COLOR_LUSTRE_WHITE:     "Белый перламутр",
            CAR_COLOR_NOT_SET:          "Не установлен",
            CAR_COLOR_PORCELAIN:        "Светло-серый солид",
            CAR_COLOR_PULSAR_BLUE:      "Темно-синий",
            CAR_COLOR_RUSSIAN_GOLD:     "Русское золото",
            CAR_COLOR_SHADE_OF_NIGHT:   "Тень ночи",
            CAR_COLOR_SILVER_LINING:    "Стальной голубой",
            CAR_COLOR_SUNSET_GOLD:      "Золотистый",
            CAR_COLOR_UNIVERSE_BLACK:   "Чёрный",
            CAR_COLOR_VELVET_RUBY:      "Рубиновый",

            // Электронные блоки
            ECUS_ADDITIONAL:                'Дополнительная информация',
            ECUS_STATUS_DESCR:              'Описание статусов',
            ECUS_DESCR_BADGES:              'Метки',
            ECUS_DESCR_SDM:                 'SDM',
            ECUS_DESCR_SDM_ONLINE:          'Блок SDM на связи',
            ECUS_DESCR_SDM_NOSATELLITES:    'Блок SDM на связи, без спутников',
            ECUS_DESCR_SDM_OFFLINE:         'Блок SDM недоступен',
            ECUS_DESCR_ICON_ERROR:          'Количество активных ошибок',
            ECUS_DESCR_ICON_PASSIVE:        'Количество пассивных ошибок',
            ECUS_DESCR_ICON_WARNING:        'Предупреждения',
            ECUS_DESCR_BG:                  'Блоки',
            ECUS_DESCR_BG_ONLINE:           'Без ошибок / с пассивными ошибками',
            ECUS_DESCR_BG_OFFLINE:          'Отсутствует диагностика более 30 дней',
            ECUS_DESCR_BG_ERROR:            'С активными ошибками',
            ECUS_DESCR_BG_NO_INFO:          'Нет информации',

            // Электронный блок
            ECU_EXPORT:                 'Экспорт отчёта',
            ECU_INFO:                   'Информация',
            ECU_ERRORS:                 'Ошибки',
            ECU_ERRORS_ONLY_ACTIVE:     'Только активные',
            ECU_PARAMS:                 'Параметры',
            ECU_PARAMS_DISABLED:        'Графики онлайн / Онлайн-телеметрия: разработка приостановлена',
            ECU_SERIAL:                 'Серийный номер',
            ECU_HARDWARE:               'Аппаратная версия',
            ECU_FIRMWARE:               'Версия ПО',
            ECU_VIN:                    'VIN по ЭБУ',
            ECU_CALIBRATION:            'Калибровка',
            ECU_MILEAGE:                'Пробег',
            ECU_SOFTWARE:               'Прошивка',
            ECU_TIMESTAMP:              'Время последней диагностики',
            ECU_ERRORS_EMPTY:           'Ошибок не обнаружено',
            ECU_HISTORY:                'История отчетов',
            ECU_FILE:                   'Файлы',
            ECU_REMOVE:                 'Удалить',

            ECU_SDM_REBOOT:             'Перезагрузить SDM',
            ECU_SDM_SOFTWARES:          'Прошивки',
            ECU_SDM_LOGS:               'Журнал',
            ECU_SDM_UPDATE_DIAG:        'Обновление Diag-CAN',
            ECU_SDM_DESCR:              'Описание',
            ECU_SDM_SOFTWARE:           'Версия ПО',
            ECU_SDM_UPDATE_NAVI:        'Обновление навигации',
            ECU_SDM_MANUFACTORER:       'Код производителя',
            ECU_SDM_CREATE_DATE:        'Аппаратная версия',
            ECU_SDM_UPDATE_PARAMS:      'Обновление Body-CAN',
            ECU_SDM_MODIFICATION:       'Модификация',
            ECU_SDM_TID:                'id SDM',
            ECU_SDM_SET_SERVER:         'Установить сервер данных',
            ECU_SDM_SEND_CMD:           'Отправить команду',
            ECU_SDM_ONLINE_DATE:        'Время последнего выхода на связь',

            FAVORITE_CARS:                  'Избранное',
            FAVORITE_CARS_ADD:              'Добавить в избранное',
            FAVORITE_CARS_EMPTY:            'Нет избранных автомобилей. Нажмите "Добавить" и выберите нужные автомобили из списка',
            FAVORITE_CARS_CREATE_SUCCESS:   'Автомобиль успешно добавлен в избранное',
            FAVORITE_CARS_CREATE_FAILURE:   'Ошибка при попытке добавления избранного автомобиля. Сообщите админитратору!',
            FAVORITE_CARS_DELETE_HEADER:    'Удалить из избранного',
            FAVORITE_CARS_DELETE_DESCR:     'Этот автомобиль будет удалён из списка избранных. Вы по-прежнему сможете найти его в общем списке на экране "Автомобили"',
            FAVORITE_CARS_DELETE_FAILURE:   'Ошибка при попытке удаления избранного автомобиля. Сообщите админитратору!',
            FAVORITE_CARS_ERROR_DATA:       'При попытке получения избранных автомобилей возникла ошибка. Повторите Ваш запрос позже',

            POLICY:                             'Центр контроля доступа',
            POLICY_USERS:                       'Политики пользователей',

            POLICY_PERMIT:                      'Разрешено',
            POLICY_DENY:                        'Запрещено',

            // Модули атрибутов
            POLICY_AREA:                        'Геозоны',
            POLICY_AUTH:                        'Авторизация',
            POLICY_CAR:                         'Автомобили',
            POLICY_EGTS:                        'EGTS',
            POLICY_EVENT:                       'События',
            POLICY_FAVORITE:                    'Избранные авто',
            POLICY_LOGS:                        'Логи',
            POLICY_POINT:                       'Точки',
            POLICY_POLICY:                      'Политики',
            POLICY_USER:                        'Политики пользователя',
            POLICY_REMOTE:                      'Удалённое управление',

            // Атрибуты модулей
            POLICY_ATTR:                        'Атрибуты',

            POLICY_AUTH_CAN_AUTHORIZATION:      'Авторизация - Запросы с токеном',
            POLICY_AUTH_CAN_LOGIN:              'Авторизация - Логин',
            POLICY_AUTH_CAN_SEND_CODE:          'Авторизация - Вход по одноразовому коду',
            POLICY_AUTH_ATTR:                   'Авторизация - Атрибуты',

            POLICY_EGTS_ATTR:                   'EGTS - Атрибуты',
            POLICY_EGTS_LIST_MODULES:           'EGTS - Просмотр модулей',
            POLICY_EGTS_LIST_PARAM:             'EGTS - Запрос параметров прибора',
            POLICY_EGTS_LIST_FIRMWARE:          'EGTS - Просмотр прошивок',
            POLICY_EGTS_LIST_LAST_REPORT:       'EGTS - Просмотр последних отчётов',
            POLICY_EGTS_LIST_REPORT:            'EGTS - Просмотр отчётов',
            POLICY_EGTS_GET_PARAM:              'EGTS - Запрос параметра по ID',
            POLICY_EGTS_GET_PDF_REPORT:         'EGTS - Просмотр отчётов PDF',
            POLICY_EGTS_EMAIL_PDF_REPORT:       'EGTS - Отправка PDF-отчётов на EMAIL',
            POLICY_EGTS_SET_PARAM:              'EGTS - Установка параметров',
            POLICY_EGTS_ADD_FIRMWARE:           'EGTS - Загрузка прошивок',
            POLICY_EGTS_DEL_FIRMWARE:           'EGTS - Удаление прошивок',
            POLICY_EGTS_DEL_REPORT:             'EGTS - Удаление отчётов',
            POLICY_EGTS_GET_LAST_REPORT:        'EGTS - Последние отчёты',
            POLICY_EGTS_SEND_COMMAND:           'EGTS - Отправка команд',

            POLICY_ATTR_AREA:                   'Зоны',
            POLICY_AREA_DELETE:                 'Зоны - Удаление',
            POLICY_AREA_EDIT:                   'Зоны - Редактирование',
            POLICY_AREA_GET:                    'Зоны - Получение зоны по ID',
            POLICY_AREA_LIST:                   'Зоны - Получение списка зон',
            POLICY_AREA_ATTR:                   'Зоны - Атрибуты',

            POLICY_CAR_ATTR:                    'Автомобили - Атрибуты',
            POLICY_CAR_GET:                     'Автомобили - Чтение',
            POLICY_CAR_LIST:                    'Автомобили - Список',
            POLICY_CAR_EDIT:                    'Автомобили - Редактирование',
            POLICY_CAR_ADD:                     'Автомобили - Добавление',
            POLICY_CAR_DELETE:                  'Автомобили - Удаление',

            POLICY_LOG_ATTR:                    'Логи - Атрибуты',
            POLICY_LOG_LIST:                    'Логи - Список',

            POLICY_EVENT_GET:                   'События - Получение',
            POLICY_EVENT_LIST:                  'События - Список',
            POLICY_EVENT_TOKEN_PUT:             'События - Запись токена',
            POLICY_EVENT_TOKEN_DEL:             'События - Удаление токена',
            POLICY_EVENT_PARAM_LIST:            'События - Push-уведомления',
            POLICY_EVENT_PARAM_PUT:             'События - Подписка на push-уведомления',
            POLICY_EVENT_PARAM_DEL:             'События - Отписка от push-уведомлений',
            POLICY_EVENT_ATTR:                  'События - Атрибуты',

            POLICY_FAVORITE_GET:                'Избранное - Получение',
            POLICY_FAVORITE_PUT:                'Избранное - Добавление',
            POLICY_FAVORITE_DEL:                'Избранное - Удаление',
            POLICY_FAVORITE_ATTR:               'Избранное - Атрибуты',

            POLICY_POINT_PWS_SUBSCRIBE:         'Точки - Получение по WS',
            POLICY_POINT_LATEST_POINTS:         'Точки - Последние точки',
            POLICY_POINT_LATEST_STATES:         'Точки - Последние состояния',
            POLICY_POINT_TRACK:                 'Точки - Трек',
            POLICY_POINT_MILEAGE:               'Точки - Пробег',
            POLICY_POINT_ATTR:                  'Точки - Атрибуты',

            POLICY_USER_ATTR:                   'Пользователи - Атрибуты',
            POLICY_USER_GET:                    'Пользователи - Просмотр по UUID',
            POLICY_USER_LIST:                   'Пользователи - Просмотр всех',
            POLICY_USER_PUT:                    'Пользователи - Редактирование',
            POLICY_USER_DEL:                    'Пользователи - Удаление',

            POLICY_REMOTE_CONTROL_ATTR:         'Удалённое управление - Атрибуты',

            POLICY_POLICY_RULE_DELETE:          'Правила - Удаление',
            POLICY_POLICY_RULE_EDIT:            'Правила - Редактирование',
            POLICY_POLICY_RULE_GET:             'Правила - Просмотр',
            POLICY_POLICY_RULE_LIST:            'Правила - Список',
            POLICY_POLICY_ONE_DELETE:           'Политики - Удаление',
            POLICY_POLICY_ONE_EDIT:             'Политики - Редактирование',
            POLICY_POLICY_ONE_GET:              'Политики - Просмотр',
            POLICY_POLICY_ONE_LIST:             'Политики - Список',
            POLICY_POLICY_SET_DELETE:           'Наборы - Удаление',
            POLICY_POLICY_SET_EDIT:             'Наборы - Редактирование',
            POLICY_POLICY_SET_GET:              'Наборы - Просмотр',
            POLICY_POLICY_SET_LIST:             'Наборы - Списокы',
            POLICY_POLICY_LINK_DELETE:          'Связи - Удаление',
            POLICY_POLICY_LINK_ADD:             'Связи - Добавление',
            POLICY_POLICY_ATTR:                 'Политики - Атрибуты',

            POLICY_ATTRS:                       'Атрибуты',
            POLICY_ATTRS_EMPTY:                 'Нет атрибутов',
            POLICY_ATTRS_DELETE:                'Удаление атрибута',
            POLICY_ATTRS_DELETE_DESCR:          'Этот атрибут будет удалён без возможности восстановления. Вы уверены, что хотите удалить его?',
            POLICY_ATTRS_DELETE_SUCCESS:        'Атрибут удалён',
            POLICY_ATTRS_DELETED:               '- удалённый атрибут -',

            POLICY_ATTR_AUTH:                   'Авторизация',
            POLICY_ATTR_EGTS:                   'EGTS',
            POLICY_ATTR_LOGS:                   'Логи',
            POLICY_ATTR_CARS:                   'Автомобили',
            POLICY_ATTR_EVENTS:                 'События',
            POLICY_ATTR_FAVORITE:               'Избранное',
            POLICY_ATTR_POINTS:                 'Точки',
            POLICY_ATTR_USERS:                  'Пользователи',

            POLICY_DESCR:                       'Описание',
            POLICY_EFFECT:                      'Эффект',
            POLICY_ALGORYTHM:                   'Алгоритм',
            POLICY_ALGORYTHM_NS:                'Не задано',
            POLICY_ALGORYTHM_PO:                'Истина, если одно или несколько истина',
            POLICY_ALGORYTHM_DO:                'Ложь, если одно или несколько ложь',
            POLICY_ALGORYTHM_FA:                'Только первое решение',
            POLICY_ALGORYTHM_HP:                'Только решение c наивысшем приоритетом',
            POLICY_PRIORITY:                    'Приоритет',
            POLICY_MODULES:                     'Модули',

            POLICY_RULES:                       'Правила',

            POLICY_RULES_CREATE:                'Создать правило',
            POLICY_RULES_CREATE_SUCCESS:        'Новое правило добавлено',

            POLICY_RULES_DELETE:                'Удаление правила',
            POLICY_RULES_DELETE_DESCR:          'Это правило будет удалено без возможности восстановления. Вы уверены, что хотите удалить его?',
            POLICY_RULES_DELETE_SUCCESS:        'Правило удалено',
            POLICY_RULES_DELETE_ERROR:          'Ошибка при попытке удаления правила',

            POLICY_RULES_CAR_ADD:               'Добавление автомобилей',
            POLICY_RULES_CAR_GET:               'Просмотр автомобиля',
            POLICY_RULES_CAR_EDIT:              'Редактирование автомобилей',
            POLICY_RULES_CAR_DELETE:            'Удаление автомобилей',
            POLICY_RULES_CAR_LIST:              'Просмотр списка автомобилей',
            POLICY_RULES_CAR_ATTR:              'Просмотр атрибутов автомобилей',

            POLICY_RULES_EVENT_GET:             'Просмотр события',
            POLICY_RULES_EVENT_LIST:            'Просмотр списка событий',
            POLICY_RULES_EVENT_ATTR:            'Просмотр атрибутов событий',
            POLICY_RULES_EVENT_TOKEN_PUT:       'Записать токен [Firebase]',
            POLICY_RULES_EVENT_TOKEN_DELETE:    'Удалить токен [Firebase]',
            POLICY_RULES_EVENT_PARAM_LIST:      'Получить состояние подписки',
            POLICY_RULES_EVENT_PARAM_PUT:       'Записать параметр подписки',
            POLICY_RULES_EVENT_PARAM_DELETE:    'Удалить параметр подписки',

            POLICY_RULES_USER_ADD:              'Добавление/редактирование пользователей',
            POLICY_RULES_USER_GET:              'Просмотр пользователя',
            POLICY_RULES_USER_DELETE:           'Удаление пользователей',
            POLICY_RULES_USER_LIST:             'Просмотр списка пользователей',
            POLICY_RULES_USER_ATTR:             'Просмотр атрибутов пользователей',

            POLICY_RULE:                        'Правило',
            POLICY_RULE_UPDATE_SUCCESS:         'Правило успешно обновлено',
            POLICY_RULE_ATTR_CREATE:            'Добавить атрибуты',
            POLICY_RULE_ATTR_CREATE_DESCR:      'Описание',
            POLICY_RULE_ATTR_CREATE_SUCCESS:    'Атрибуты успешно добавлены в правило',

            POLICY_ONES:                        'Политики',
            POLICY_ONES_CREATE:                 'Создать политику',
            POLICY_ONES_CREATE_SUCCESS:         'Новая политика добавлена',
            POLICY_ONES_DELETE:                 'Удаление политики',
            POLICY_ONES_DELETE_DESCR:           'Эта политика будет удалена без возможности восстановления. Вы уверены, что хотите удалить её?',
            POLICY_ONES_DELETE_SUCCESS:         'Политика удалена',
            POLICY_ONES_DELETE_ERROR:           'Ошибка при попытке удаления политики',

            POLICY_ONE:                         'Политика',
            POLICY_ONE_UPDATE_SUCCESS:          'Политика обновлена',
            POLICY_ONE_RULES_CREATE:            'Добавить правило',
            POLICY_ONE_RULES_CREATE_SUCCESS:    'Правило успешно добавлено в политику',
            POLICY_ONE_RULE_DELETE:             'Удаление правила политики',
            POLICY_ONE_RULE_DELETE_DESCR:       'Это правило будет удалено из политики. Вы уверены, что хотите удалить его?',
            POLICY_ONE_RULE_DELETE_SUCCESS:     'Правибо удалено из политики',

            POLICY_SETS:                        'Наборы',
            POLICY_SET_CREATE:                  'Создать набор',
            POLICY_SET_CREATE_DESCR:            'Описание',
            POLICY_SET_CREATE_SUCCESS:          'Новый набор добавлен',

            POLICY_SETS_DELETE:                 'Удаление набора',
            POLICY_SETS_DELETE_DESCR:           'Этот набор будет удалён без возможности восстановления. Вы уверены, что хотите удалить его?',
            POLICY_SETS_DELETE_SUCCESS:         'Набор удалён',
            POLICY_SETS_DELETE_ERROR:           'Ошибка при попытке удаления набора',

            POLICY_SET:                         'Набор',
            POLICY_SET_UPDATE_SUCCESS:          'Набор обновлён',
            POLICY_SET_ONES_CREATE:             'Добавить политику',
            POLICY_SET_ONES_CREATE_SUCCESS:     'Политика успешно добавлена в набор',
            POLICY_SET_ONE_DELETE:              'Удаление политики набора',
            POLICY_SET_ONE_DELETE_DESCR:        'Эта политика будет удалена из набора. Вы уверены, что хотите удалить её?',
            POLICY_SET_ONE_DELETE_SUCCESS:      'Политика удалена из набора',

            POLICY_USERS_EMPTY:                 'Пользователи не найдены',
            POLICY_USER_SETS_EMPTY:             'Наборы не найдены',
            POLICY_USER_SET_CREATE_SUCCESS:     'Набор добавлен пользователю',
            POLICY_USER_SET_DELETE:             'Удаление набора пользователя',
            POLICY_USER_SET_DELETE_DESCR:       'Этот набор будет удален из пользователя. Вы уверены, что хотите удалить его?',
            POLICY_USER_SET_DELETE_SUCCESS:     'Набор удалён из пользователя',

            // MANUALS
            MANUAL_CAR:                         'Руководство по эксплуатации автомобиля',
            MANUAL_MMS:                         'Руководство по эксплуатации мультимедийной системы',

            COPYRIGHT:                          'Разработано ФГУП "НАМИ", 2022',

            // 404
            NOTFOUND_SUBTITLE:              'Страница не найдена',
            NOTFOUND_LINK:                  'На главную'
        },

        EN: {
            
            APP_NAME:                   'Sky web',

            SEARCH:                     'Search',

            PAGINATOR_PAGE:             'Page',

            YES:                        'Yes',
            NO:                         'No',

            START:                      'Start',
            STOP:                       'Stop',
            FINISH:                     'Finish',

            NO_FAILURE:                 'No failure',

            // Units
            UNIT_KM:                    'km',
            UNIT_KMH:                   'km/h',
            UNIT_METRE:                 'm',
            UNIT_DAY:                   'd',
            UNIT_HOUR:                  'h',
            UNIT_MINUTE:                'min',
            UNIT_SECOND:                's',
            UNIT_SECOND_LONG:           'sec.',
            UNIT_LITER:                 'l',
            UNIT_LITER_100km:           'l/100km',
            UNIT_ATM:                   'atm',
            UNIT_RPM:                   'rpm',
            UNIT_VOLT:                  'V',

            // Forms text
            FORM_REQUIRED:              'Required fields',
            FORM_ENTER:                 'Enter a value',
            FORM_SELECT_COLOR:          'Choose a color from the list',
            FORM_SELECT:                'Choose from the list',
            FORM_SEARCH:                'Search',

            // Buttons text
            BTN_ADD:                    'Add',
            BTN_CREATE:                 'Create',
            BTN_NEW:                    'New',
            BTN_EDIT:                   'Edit',
            BTN_SAVE:                   'Save',
            BTN_READMORE:               'Show',
            BTN_CANCEL:                 'Cancel',
            BTN_DELETE:                 'Delete',
            BTN_CTRL:                   'Control',
            BTN_REQUEST:                'Request',
            BTN_SHOW:                   'Show',
            BTN_UPLOAD:                 'Upload',
            BTN_DOWNLOAD:               'Download',
            BTN_CLOSE:                  'Close',
            BTN_SELECT:                 'Select',
            BTN_EXPORT:                 'Export',
            BTN_FAVORITES:              'Favorites',
            BTN_RESET:                  'Reset',
            BTN_SEND:                   'Send',

            // Week days & "quick dates"
            MONDAY:                     'Monday',
            TUESDAY:                    'Tuesday',
            WEDNESDAY:                  'Wednesday',
            THIRSDAY:                   'Thirsday',
            FRIDAY:                     'Friday',
            SATURDAY:                   'Saturday',
            SUNDAY:                     'Sunday',
            TODAY:                      'Today',
            YESTERDAY:                  'Yesterday',
            DAY_BEFORE_YESTERDAY:       'Two day ago',
            IN_A_WEEK:                  'Last week',

            // Liquid  levels
            LEVEL_CRITICAL_LOW:         'Critical low level',
            LEVEL_LOW:                  'Low level',
            LEVEL_NORMAL:               'Normal level',
            LEVEL_HIGH:                 'High level',

            // Pagination
            PAGINATION_PREV:            'Prev',
            PAGINATION_NEXT:            'Next',
            PAGINATION_PAGE:            'Page',

            // Main menu
            MENU_MAIN:                  'Home',
            MENU_CARS:                  'Cars',
            MENU_SOFTWARE:              'Softwares',
            MENU_GUIDE:                 'Guide',
            MENU_USERS:                 'Users',
            MENU_USER:                  'User',

            // Car menu
            CARMENU_INFO:               'Information',
            CARMENU_MAP:                'Map',
            CARMENU_STATE:              'States',
            CARMENU_ECUS:               'ECU`s',
            CARMENU_ECU:                'ECU',
            CARMENU_LOGS:               'Logs',
            CARMENU_USERS:              'Users',
            CARMENU_EVENTS:             'Events',
            CARMENU_HISTORY:            'History of indications',

            // Page headers
            PAGE_MAIN:                  'Main',
            PAGE_CARS:                  'Cars',
            PAGE_USERS:                 'Users',
            PAGE_PROFILE:               'Profile',
            PAGE_GUIDE:                 'Guide',
            PAGE_CAR:                   'Car',
            PAGE_CAR_INFO:              'Information',
            PAGE_CAR_ECU:               'Electronic units',
            PAGE_CAR_MAP:               'Map',
            PAGE_CAR_STATE:             'States',
            PAGE_CAR_USERS:             'Users',
            PAGE_CAR_LOGS:              'Logs',
            PAGE_CAR_EVENTS:            'Events',
            PAGE_CAR_HISTORY:           'History of readings of instruments and sensors',

            // Authorization & single auth
            AUTHORIZATION:              'Authorization',
            LOGIN:                      'Login',
            RESET_TOKEN:                'End all sessions',
            LOGIN_SHORT:                'Login must be longer than 3 characters',
            PASSWORD:                   'Password',
            PASSWORD_SHORT:             'Password must be longer than 6 characters',
            CAPTCHA_VALUE:              'Enter the code from the image',
            AUTHORIZATE:                'Log in to the system',
            SINGLE_AUTH:                'Single auth',
            EMAIL:                      'E-mail',
            EMAIL_CODE:                 'Code from e-mail/sms',
            EMAIL_SHORT:                'E-mail must be longer than 6 characters',
            REQUEST_PASSWORD:           'Request password',

            // User rights
            READ:                       'Read',
            READ_WRITE:                 'Read and write',
            READ_WRITE_DELETE:          'Read, write and delete',
            NO_ACCESS:                  'No rights',
            NO_RIGHT:                   'No rights',
            RIGHT_BLOCKED:              'Blocked',
            NONE:                       'No rights',

            // User groups
            NO_TYPE:                       'No group',
            DISABLER:                      'Banned',
            EXCHANGER:                     'Exchanger',
            DRIVER:                        'Driver',
            OPERATOR:                      'Operator',
            ADMINISTRATOR:                 'Administrator',
            SUPPERADMINISTRATOR:           'Oberadmin',

            // Пользователи
            USERS_NAME:                    'Name',
            USERS_TYPE:                    'Group',
            USERS_EMAIL:                   'E-mail',

            // Users & profile
            PROFILE:                       'Profile',
            LOGOUT:                        'Logout',
            USER:                          'User',
            USER_CREATE:                   'Create new user',
            USER_NAME:                     'Name',
            USER_EMAIL:                    'E-mail',
            USER_PASS:                     'Password',
            USER_RIGHT:                    'Rights',
            USER_TYPE:                     'Group',
            USER_PHONE:                    'Phone',
            USER_CREATED:                  'Created',
            USER_UPDATED:                  'Updated',
            USER_CREATE_SUCCESS:           'New user added',
            USER_UPDATE_SUCCESS:           'User profile updated',
            USER_DELETE:                   'Delete user',
            USER_DELETE_TEXT:              'This user will be deleted without possibility of restoration. Are you sure you want to delete it?',
            USER_DELETE_SUCCESS:           'User deleted',
            USERS:                         'Users',
            USER_INFO:                     'Info',
            USER_LOG:                      'Activity Log',
            USER_VEHICLES:                 'Cars',
            USER_INFO_HEADER:              'User info',
            USER_ACTIONS:                  'User actions log',
            USER_ACTION:                   'Action',
            USER_DATETIME:                 'Date, time',
            USER_EMPTY:                    'The user has no cars yet',
            USER_FIO:                      'Full name',
            USER_EMAIL_ERROR:              'Enter the correct E-mail',
            USER_TYPE_ERROR:               'Select the user type',
            USER_RIGHT_ERROR:              'Select the type of user rights',
            USER_PASS_ERROR:               'At least 6 characters, contains capital letters and numbers',

            // User cars
            USER_CARS:                   'User cars',
            USER_CARS_EMPTY:             'This user doesn`t have any cars',
            USER_CARS_CREATE:            'User cars',
            USER_CARS_ACCESS:            'Access',
            USER_CARS_CREATE_SUCCESS:    'User cars updated',
            USER_CARS_DELETE:            'Delete a car',
            USER_CARS_DELETE_TEXT:       'The car will be deleted without the possibility of recovery',
            USER_CARS_DELETE_SUCCESS:    'Access to the car is removed',
            USER_CARS_ADD:               'Add a car',

            // Cars
            CARS:                          'Cars',
            CARS_MODEL:                    'Model',
            CARS_NAMI_NUMBER:              'Manufactorer code',
            CARS_TYPE:                     'Body',
            CARS_VIN:                      'VIN',
            CARS_SEARCH_MODE_ALL:          'All',
            CARS_SEARCH_MODE_VIN:          'VIN',
            CARS_SEARCH_MODE_TID:          'id SDM',
            CARS_SEARCH_MODE_IMEI:          'IMEI',
            CARS_SEARCH_MODE_NUMBER:       'Car number',
            CARS_SEARCH_MODE_NAMI:         'NAMI number',
            CARS_SEARCH_MODE_PHONE:        'Phone',

            // Car
            CAR:                           'Car',
            CAR_VIN:                       'VIN',
            CAR_NAME:                      'Name',
            CAR_NUMBER:                    'Car number',
            CAR_PHONE:                     'Phone [SDM]',
            CAR_NAMI_NUMBER:               'Manufactorer code',
            CAR_GARAGE:                    'Inventory number',
            CAR_STATE:                     'Description',
            CAR_CARTYPE:                   'Car type',
            CAR_COLOR:                     'Color',
            CAR_ENGINE:                    'Engine',
            CAR_SUSPENSION:                'Suspension',
            CAR_YEAR:                      'Model year',
            CAR_MODEL:                     'Model',
            CAR_MODEL_UNKNOWN:             'Unknown model',
            CAR_MANUFACTORY:               'Manufactur',
            CAR_CREATE_CARTYPE:            'Select car type',
            CAR_CREATE_CARTYPE_SUCCESS:    'Car type selected',
            CAR_TID:                       'id SDM',
            CAR_SOFT:                      'Module software',
            CAR_IMEI:                      'IMEI',
            CAR_MODULES:                   'Modules info',
            CAR_USERS:                     'Car users',
            CAR_CREATE:                    'New car',
            CAR_CREATE_SUCCESS:            'New car created',
            CAR_DELETE:                    'Delete a car',
            CAR_DELETE_TEXT:               'This car will be deleted without possibility of restoration. Are you sure you want to delete it?',
            CAR_DELETE_SUCCESS:            'Car deleted',
            CAR_INFO:                      'Car info',
            CAR_INFO_UPDATE_SUCCESS:       'Car info updated',
            CAR_USERS_ADD:                 'Add car user',
            CAR_VIN_ERROR:                 'VIN length is 17 characters',
            CAR_IMEI_ERROR:                'IMEI length is 15 characters',
            CAR_IMEI_TID:                  'A number from 0 to 2147483647',
            CAR_COLOR_CHOOSE:              'Choose a color',
            CAR_PHOTO:                     'Photo',
            CAR_STATUS:                    'Status',

            // Типы подвесок
            SUSPENSION_CONVENTIONAL:       'Convensional',
            SUSPENSION_AIR:                'Air',
            SUSPENSION_DUAL_AIR:           'Air (dual)',

            // Типы кузовов
            CARTYPE_SEDAN:                   'Sedan',
            CARTYPE_SEDAN_ARMORED:           'Sedan armored',
            CARTYPE_LIMO_ARMORED_V12:        'Limo armored',
            CARTYPE_LIMO_ARMORED_V8:         'Limo armored',
            CARTYPE_LIMO_ARMORED_V8_CIVIL:   'Limo armored (civil)',
            CARTYPE_CABRIO:                  'Cabriolet',
            CARTYPE_CABRIO_SHOW:             'Cabriolet show',
            CARTYPE_SEDAN_4X2:               'Sedan 4x2',
            CARTYPE_SEDAN_LONG:              'Sedan long',
            CARTYPE_SEDAN_LONG_ARMORED:      'Sedan long armored',
            CARTYPE_SUV:                     'SUV',
            CARTYPE_SUV_ARMORED:             'SUV armored',
            CARTYPE_MPV_4_SEATS:             'MPV 4 seats',
            CARTYPE_MPV_ARMORED_4_SEATS:     'MPV armored 4 seats',
            CARTYPE_MPV_ARMORED_8_SEATS:     'MPV armored 8 seats',
            CARTYPE_MPV_9_SEATS:             'MPV 9 seats',
            CARTYPE_MPV_ARMORED_9_SEATS:     'MPV armored 9 seats',
            CARTYPE_MPV_ORBITA:              'MPV Orbita',
            CARTYPE_MPV_TABLETKA:            'MPV Tabletka',
            CARTYPE_RESERVED:                'Reserve',
            CARTYPE_UNKNOWN:                 'Unknown body',

            // Software
            FIRMWARE:                   'Softwares',
            FIRMWARE_NAME:              'File name',
            FIRMWARE_STATUS:            'Status',
            FIRMWARE_STATUS_SENDED:     'Sended',
            FIRMWARE_STATUS_WAITING:    'Waiting',
            FIRMWARE_FILE:              'File',
            FIRMWARE_FILE_SELECT:       'Select a file',
            FIRMWARE_CREATE:            'Create software file',
            FIRMWARE_CREATE_SUCCESS:    'The firmware file has been successfully uploaded, wait for the device update',
            FIRMWARE_DELETE:            'Delete a firmware',
            FIRMWARE_DELETE_TEXT:       'This software file will be deleted without possibility of restoration. Are you sure you want to delete it?',
            FIRMWARE_DELETE_SUCCESS:    'Software file deleted',
            FIRMWARE_FILE_EMPTY:        'You are trying to download an empty firmware file',
            FIRMWARE_FILE_ERROR:        'No firmware file selected',
            FIRMWARE_FILE_LARGE:        'The file is too large, the maximum file weight to upload - 16 MB',

            ECUS_EXPORT_DESCR:          'Select the report files to download',
            ECUS_EXPORT_TYPE_DESCR:     'Select the import method',
            ECUS_EXPORT_SELECT_ALL:     'Select all',
            ECUS_EXPORT_DESELECT_ALL:   'Deselect all',
            ECUS_EXPORT_PDF:            'Download PDF',
            ECUS_EXPORT_EMAIL:          'Send e-mail',
            ECUS_EXPORT_EMAIL_LABEL:    'E-mail',
            ECUS_EXPORT_EMAIL_SUCCESS:  'The file with the diagnostic results was successfully sent to the specified e-mail address',
            ECUS_EXPORT_ERROR_ECU:      'No block is selected',
            ECUS_EXPORT_ERROR_EMAIL:    'The e-mail address for sending the report is not specified',

            ECUS_DIAG_REFRESH:          'Update report',
            ECUS_DIAG_REFRESH_SUCCESS:  'Diagnostics is running. The report files will be updated',
            ECUS_DIAG_CLEAR_DTC:        'Clear DTC',
            DIAG_IMPORT_EMAIL_SUCCESS:  'The file with diagnostic reports has been successfully sent to the specified e-mail',

            // Car users
            CARUSERS_EMPTY:             'There is no users in this car',
            CARUSERS_CREATE:            'Update car users',
            CARUSERS_CREATE_SUCCESS:    'Car users updated',
            CARUSERS_DELETE:            'Delete user',
            CARUSERS_DELETE_TEXT:       'This user will lose access to the vehicle information',
            CARUSERS_DELETE_SUCCESS:    'Car user deleted',

            // Car types
            CARTYPES:                   'Car types',
            CARTYPE_NEW:                'New type',
            CARTYPE_BODY:               'Type',
            CARTYPE_TYPE:               'Modify',
            CARTYPE_UNDEFINED:          'Undefined car type',
            CARTYPE_CREATE_SUCCESS:     'New car type added',
            CARTYPE_UPDATE_SUCCESS:     'Car type updated',
            CARTYPE_DELETE:             'Car type deleting',
            CARTYPE_DELETE_TEXT:        'This user will be deleted without possibility of restoration. Are you sure you want to delete it?',
            CARTYPE_DELETE_SUCCESS:     'Car type deleted',

            // Events
            EVENTS:                     'Events',
            EVENTS_START:               'Events start date',
            EVENTS_END:                 'Events end date',
            EVENTS_ERROR_NO_LIMITS:     'Specify the start and end dates of the events',
            EVENTS_ERROR_INVALID_LIMITS: 'The start date of the events must be earlier than the end date',
            EVENTS_ERROR_MAX_PERIOD:    'The maximum interval for requesting events is 31 days',
            EVENTS_EVENT_ON_MAP:        'Event on map',
            EVENTS_EVENT:               'Event',
            EVENTS_EVENT_DATE:          'Event date',
            EVENTS_REQUEST:             'Request',
            EVENTS_MAP:                 'Map',
            

            // Online status
            ONLINE:                     'Online',
            NOSATELLITES:               'Online',
            OFFLINE:                    'Offline',

            // Car commands
            CMD_SEND_HEADER:                'Send CMD',
            CMD_SEND:                       'Send',
            CMD_SET_SERVER:                 'Set data server',
            CMD_RELOAD:                     'Reload SDM',
            CMD_SEND_SUCCESS:               'Command sended',
            CMD_SUCCESS:                    'Success',
            CMD_FAILURE:                    'Failure',
            CMD_RESULT_NOT_SET:             'Status not set',
            CMD_RESULT_SEND_IN_PROCESS:     'Send in progress',
            CMD_RESULT_SEND_TIMEOUT:        'Send timeout',
            CMD_RESULT_SEND_CONFIRMED:      'Send confirmed',
            CMD_RESULT_RESULT_OK:           'The car sent the result of the command execution: successful execution',
            CMD_RESULT_RESULT_ERROR:        'The car sent the result of the command execution: error',
            CMD_RESULT_RESULT_ILL:          'The car sent the result of the command execution: error, absence from the list of allowed commands',
            CMD_RESULT_RESULT_DEL:          'The car sent the result of the command execution: successfully deleted',
            CMD_RESULT_RESULT_NOT_FOUND:    'The car sent the result of the command execution: the command to delete was not found',
            CMD_RESULT_RESULT_NOT_CONFIG:   'The car sent the result of the command execution: successful execution, negative response',
            CMD_RESULT_RESULT_IN_PROCESS:   'The car sent the result of the command execution: the command has been submitted for processing',

            // Track request
            CARTRACK:                       'Viewing trip tracks',
            CARTRACK_START:                 'Period start',
            CARTRACK_END:                   'Period finish',
            CARTRACK_EMPTY:                 'At this time car wasn`t in motion',
            CARTRACK_LIMIT:                 'Select the time period to request a vehicle route [no more than 31 days]',
            CARTRACK_ERROR_MAX_PERIOD:      'The maximum interval for requesting a route is 31 days',
            CARTRACK_ERROR_INVALID_LIMITS:  'The start date of the route must be earlier than the end date',
            CARTRACK_ERROR_NO_LIMITS:       'Specify the start and end dates of the route',

            // Connection type
            CONNECTION_ON:              'Online',
            CONNECTION_OFF:             'No connection',
            CONNECTION_LTE:             'LTE',
            CONNECTION_UMTS:            'UMTS',
            CONNECTION_GSM:             'GSM',

            // Map controls
            MAP_SIZE_FULL:              'Expand',
            MAP_SIZE_SMALL:             'Roll up',
            MAP_LAYERS_SHOW:            'Show special layers',
            MAP_LAYERS_HIDE:            'Hide special layers',
            MAP_CMD:                    'Send CMD',
            MAP_CAR_TRACK:              'Car routes history',
            MAP_CAR_TRACK_RESET:        'Reset car route',
            MAP_CAR_TRACK_MILEAGE:      'Mileage per route',

            // EGTS modules
            EGTS_MODULES_MODULE_MAIN:   'Main module',
            EGTS_MODULES_MODULE_INPUT:  'Input module',
            EGTS_MODULES_MODULE_NAVI:   'Navigation module',
            EGTS_MODULES_MODULE_LINK:   'Connection link',
            EGTS_MODULES_VENDOR:        'Vendor code',
            EGTS_MODULES_FIRMWARE:      'Hardware',
            EGTS_MODULES_SOFTWARE:      'Software',
            EGTS_MODULES_MODIFICATION:  'Modification',
            EGTS_MODULES_STATE:         'State',
            EGTS_MODULES_SERIAL:        'S/n',
            EGTS_MODULES_DESCR:         'Description',
            EGTS_MODULES_DATE:          'Build date',

            // EGTS errors status
            EGTS_PC_OK:                 'Processed successfully',
            EGTS_PC_IN_PROGRESS:        'In the process',
            EGTS_PC_UNS_PROTOCOL:       'Unsupported protocol',
            EGTS_PC_DECRYPT_ERROR:      'Decoding error',
            EGTS_PC_PROC_DENIED:        'Processing is prohibited',
            EGTS_PC_INC_HEADERFORM:     'Invalid header format',
            EGTS_PC_INC_DATAFORM:       'Invalid data format',
            EGTS_PC_UNS_TYPE:           'Unsupported type',
            EGTS_PC_NOTEN_PARAMS:       'Invalid number of parameters',
            EGTS_PC_DBL_PROC:           'Re-processing attempt',
            EGTS_PC_PROC_SRC_DENIED:    'Processing of data from the source is prohibited',
            EGTS_PC_HEADERCRC_ERROR:    'Header сhecksum error',
            EGTS_PC_DATACRC_ERROR:      'Data Checksum error',
            EGTS_PC_INVDATALEN:         'Incorrect data length',
            EGTS_PC_ROUTE_NFOUND:       'Route not found',
            EGTS_PC_ROUTE_CLOSED:       'The route is closed',
            EGTS_PC_ROUTE_DENIED:       'Routing is not allowed',
            EGTS_PC_INVADDR:            'Invalid address',
            EGTS_PC_TTLEXPIRED:         'Exceeded the number of data retransmissions',
            EGTS_PC_NO_ACK:             'No confirmation',
            EGTS_PC_OBJ_NFOUND:         'Object not found',
            EGTS_PC_EVNT_NFOUND:        'Event not found',
            EGTS_PC_SRVC_NFOUND:        'Service not found',
            EGTS_PC_SRVC_DENIED:        'The service is prohibited',
            EGTS_PC_SRVC_UNKN:          'Unknown service type',
            EGTS_PC_AUTH_DENIED:        'Authorization is not allowed',
            EGTS_PC_ALREADY_EXISTS:     'The object already exists',
            EGTS_PC_ID_NFOUND:          'ID not found',
            EGTS_PC_INC_DATETIME:       'Incorrect date and time',
            EGTS_PC_IO_ERROR:           'I/O error',
            EGTS_PC_NO_RES_AVAIL:       'Insufficient resources',
            EGTS_PC_MODULE_FAULT:       'Internal module failure',
            EGTS_PC_MODULE_PWR_FLT:     'Module power supply circuit failure',
            EGTS_PC_MODULE_PROC_FLT:    'Failure of the modules microcontroller',
            EGTS_PC_MODULE_SW_FLT:      'Failure of the module program',
            EGTS_PC_MODULE_FW_FLT:      'Failure in the internal software of the module',
            EGTS_PC_MODULE_IO_FLT:      'Module I/O block failure',
            EGTS_PC_MODULE_MEM_FLT:     'Failure in the internal memory of the module',
            EGTS_PC_TEST_FAILED:        'Test failed',

            /**
             *  Car states
             * 
             * "STATE_" + state name in upper case
             */
            STATE_TABS_STATISTICS:              '',
            STATE_TABS_LAST_TRIP:               'Travel statistics',
            STATE_TABS_WHEELS:                  'Wheels pressure',
            STATE_TABS_BRAKES:                  'Brakes system',
            STATE_TABS_CLIMATE:                 'Climatic system',
            STATE_TABS_TRANSMISSION:            'Transmission',
            STATE_TABS_BATTERY:                 'Battery',
            STATE_TABS_LIGHT:                   'Light',
            STATE_TABS_ENGINE:                  'Engine',
            STATE_TABS_DOORS:                   'Doors & body',
            STATE_TABS_ELECTRIC:                'Battery',

            STATE_DMRL_WARNING_INDICATOR: 'DMRL warning indicator', 
            STATE_CORNERING_LIGHT_RIGHT_STATUS: 'Cornering light right status', 
            STATE_WHEEL_BACK_RIGHT_STATUS: 'Wheel back right status', 
            STATE_T30I_FAULT_STATUS: 'T30i fault status', 
            STATE_BRAKE_LIGHT_LEFT_STATUS: 'Brake light left status', 
            STATE_WHEEL_FRONT_RIGHT_PRESSURE: 'Wheel front right pressure', 
            STATE_TURN_INDICATOR_MR_STATUS: 'Turn indicator MR status', 
            STATE_DOOR_FRONT_LEFT_CLOSED: 'Door front left closed', 
            STATE_RIDE_AVERAGE_SPEED: 'Ride average speed', 
            STATE_TEMPERATURE_OUTSIDE: 'Temperature outside', 
            STATE_BACK_LEFT_TEMPERATURE: 'Back left temperature', 
            STATE_OIL_PRESSURE_WARNING: 'Oil pressure warning', 
            STATE_IS_LOCKED: 'Is locked', 
            STATE_DOOR_FRONT_RIGHT: 'Door front right', 
            STATE_FRONT_INSIDE_TEMPERATURE: 'Front inside temperature', 
            STATE_FRONT_RIGHT_TEMPERATURE: 'Front right temperature', 
            STATE_STATUS: 'Status', 
            STATE_DMFR_WARNING_INDICATOR: 'DMFR warning indicator', 
            STATE_OIL_LEVEL: 'Oil level', 

            STATE_TPMS_BATTERY_STATUS:      'TPMS battery status', 
            STATE_TPMS_BATTERY_STATUS_FL:   'TPMS battery status FL', 
            STATE_TPMS_BATTERY_STATUS_FR:   'TPMS battery status FR', 
            STATE_TPMS_BATTERY_STATUS_RL:   'TPMS battery status RL', 
            STATE_TPMS_BATTERY_STATUS_RR:   'TPMS battery status RR', 

            STATE_WHEEL_PRESSURE_STATUS_HIGH:   'High pressure',
            STATE_WHEEL_PRESSURE_STATUS_NORMAL: 'Normal pressure',
            STATE_WHEEL_PRESSURE_STATUS_LOW:    'Low pressure',
            STATE_WHEEL_PRESSURE_STATUS_CRITICAL: 'Critical low pressure!',
            STATE_WHEEL_PRESSURE_STATUS_ERROR:  'Error!',

            STATE_ENGINE_TIME:                  'Engine hours',

            STATE_WHEEL_BACK_LEFT_STATUS: 'Wheel back left status', 
            STATE_MIL_ONRQ: 'Mil onRq', 
            STATE_POSITION_LIGHT_FR_STATUS: 'Position light FR status', 
            STATE_HEAD_LIGHT_LEVELING_CTRL_FAILURE: 'Head light leveling ctrl failure', 
            STATE_LTC_COOLANT_LVL: 'LTC coolant lvl', 
            STATE_BACK_INSIDE_TEMPERATURE: 'Back inside temperature', 
            STATE_RECEIVED_SIGNAL_STRENGTH_INDICATOR: 'Received signal strength indicator', 
            STATE_DOOR_BACK_LEFT: 'Door back left', 
            STATE_WHEEL_BACK_RIGHT_PRESSURE: 'Wheel back right pressure', 
            STATE_REVERSE_LIGHT_RIGHT_STATUS: 'Reverse light right status', 
            STATE_FRONT_LEFT_TEMPERATURE: 'Front left temperature', 
            STATE_START_MOVING: 'Car start time', 
            STATE_STOP_MOVING: 'Car stop time', 
            STATE_DMRR_WARNING_INDICATOR: 'DMRR warning indicator', 
            // STATE_VEHICLE_SECURE_LOCK_STATE: 'Vehicle secure lock state', // State was deleted on backend
            STATE_REAR_FOG_LIGHT_STATUS: 'Rear fog light status', 
            STATE_DOOR_FRONT_RIGHT_CLOSED: 'Door front right closed', 
            STATE_ABS_LAMP: 'ABS lamp', 
            STATE_DMFL_WARNING_INDICATOR: 'DMFL warning indicator', 
            STATE_FRONT_RIGHT_FOG_LIGHT_STATUS: 'Front right fog light status', 
            STATE_POSITION_LIGHT_RR_STATUS: 'Position light RR status', 
            STATE_TURN_INDICATOR_RR_STATUS: 'Turn indicator RR status', 
            STATE_LOW_BEAM_RIGHT_STATUS: 'Low beam right status', 
            STATE_EPB_STATUS: 'Epb status', 
            STATE_ESP_LAMP: 'ESP lamp', 
            STATE_TPMS_SENSOR_FAULT_FR: 'TPMS sensor fault FR', 
            STATE_T15_FAULT_STATUS: 'T15 fault status', 
            STATE_WHEEL_FRONT_LEFT_PRESSURE: 'Wheel front left pressure', 
            STATE_TPMS_SENSOR_FAULT_RL: 'TPMS sensor fault RL', 
            STATE_RIDE_MILEAGE: 'Ride mileage', 
            STATE_LAST_NAVIGATION_TIME: 'Last navigation time', 
            STATE_T30D_FAULT_STATUS: 'T30d fault status', 
            STATE_BRAKE_LIGHT_RIGHT_STATUS: 'Brake light right status', 
            STATE_HV_SYSTEM_FAILURE: 'HV system failure', 
            STATE_FUEL: 'Fuel', 
            STATE_FRONT_LEFT_FOG_LIGHT_STATUS: 'Front left fog light status', 
            STATE_BRAKE_LIGHT_HM_STATUS: 'Brake light HM status', 
            STATE_TURN_INDICATOR_FR_STATUS: 'Turn indicator FR status', 
            STATE_POSITION_LIGHT_RL_STATUS: 'Position light RL status', 
            STATE_BCM_WARNING_INDICATOR: 'BCM warning indicator', 
            STATE_ONLINE: 'Online', 
            STATE_ENGINE_ON: 'Engine on', 
            STATE_DAYTIME_LIGHT_RIGHT_STATUS: 'Daytime light right status', 
            STATE_DOOR_TRUNK: 'Door trunk', 
            STATE_DOOR_BACK_LEFT_CLOSED: 'Door back left closed', 
            STATE_MOBILE_NETWORK_TYPE_INDICATOR: 'Mobile network type indicator', 
            STATE_POSITION_LIGHT_FL_STATUS: 'Position light FL status', 
            STATE_TPMS_SENSOR_FAULT_FL: 'TPMS sensor fault FL', 
            STATE_DAYTIME_LIGHT_LEFT_STATUS: 'Daytime light left status', 
            STATE_DOOR_FRONT_LEFT: 'Door front left', 
            STATE_DOOR_HOOD: 'Door hood', 
            STATE_TPMS_SENSOR_FAULT_RR: 'TPMS sensor fault RR', 
            STATE_ENGINE_OIL_TEMP: 'Engine oil temp', 
            STATE_ENGINE_REV: 'Engine rev', 

            STATE_WINDOW_FULLY_CLOSED_FL:       'Window [front left]', 
            STATE_WINDOW_FULLY_CLOSED_FR:       'Window [front right]', 
            STATE_WINDOW_FULLY_CLOSED_RL:       'Window [rear left]', 
            STATE_WINDOW_FULLY_CLOSED_RR:       'Window [rear right]', 
            STATE_WINDOW_OPEN:                  'The window is open',

            STATE_WHEEL_FRONT_RIGHT_STATUS: 'Wheel front right status', 
            STATE_TURN_INDICATOR_ML_STATUS: 'Turn indicator ML status', 
            STATE_TCS_LAMP: 'TCS lamp', 
            STATE_EBD_LAMP: 'EBD lamp', 
            STATE_DOOR_BACK_RIGHT_CLOSED: 'Door back right closed', 
            STATE_TURN_INDICATOR_RL_STATUS: 'Turn indicator RL status', 
            // STATE_MILEAGE_ON_FUEL_RESIDUES: 'Mileage on fuel residues', // Old state
            STATE_WHEEL_FRONT_LEFT_STATUS: 'Wheel front left status', 
            STATE_HIGH_BEAM_LEFT_STATUS: 'High beam left status', 
            STATE_ENGINE_TEMPERATURE: 'Engine temperature', 
            STATE_RIDE_TIME: 'Ride time', 
            STATE_ENGINE_OFF: 'Engine off', 
            STATE_WASHER_FLUID: 'Washer fluid', 
            STATE_WASHER_FLUID_WARNING: 'Low level of washing liquid',
            STATE_TOTAL_MILEAGE: 'Total mileage', 
            STATE_BACK_RIGHT_TEMPERATURE: 'Back right temperature', 
            STATE_WHEEL_BACK_LEFT_PRESSURE: 'Wheel back left pressure', 
            STATE_MIL_IND_BLINK: 'Mil ind blink', 
            STATE_COOLANT_FLUID: 'Coolant fluid', 
            STATE_LOW_BEAM_LEFT_STATUS: 'Low beam left status', 
            STATE_RIDE_FUEL_CONSUMPTION: 'Ride fuel consumption', 
            STATE_ENGINE_OIL_LVIND: 'Engine oil level indicator', 
            STATE_TURN_INDICATOR_FL_STATUS: 'Turn indicator FL status', 
            STATE_DOOR_TRUNK_DIRECTION: 'Door trunk direction', 
            STATE_CORNERING_LIGHT_LEFT_STATUS: 'Cornering light left status', 
            STATE_HIGH_BEAM_RIGHT_STATUS: 'High beam right status', 
            STATE_ESP_SPEED: 'Esp speed', 
            STATE_LAST_CAN_PARAM_TIME: 'Last can param time', 
            STATE_TM_WARNING_INDICATOR: 'TM warning indicator', 
            STATE_REVERSE_LIGHT_LEFT_STATUS: 'Reverse light left status', 
            STATE_DOOR_BACK_RIGHT: 'Door back right',  
            STATE_SERVICE_DATE: 'Service date',
            STATE_GEAR_POSITION: 'Gear position',
            STATE_LAST_DIAGNOSTICS_TIME: 'Last diagnostic time',
            STATE_LAST_ONLINE: 'Last online',
            STATE_SERVICE_MILEAGE: 'Service millage',
            STATE_ST_TRANSMISSION_WARNING: 'Transmission warning',
            STATE_SCU_CALIBRATION_STATUS: 'SCU calibration status',
            STATE_CCU_FAILURE: 'CCU failure',
            STATE_SCU_FAULT_STATUS: 'SCU fault status',
            STATE_ERAG_FAILURE_STATUS: 'ERAG failure status',
            STATE_OPEN: 'Open',
            STATE_HALF_OPEN: 'Half open',
            STATE_CLOSED: 'Closed',
            STATE_NOT_CLOSED: 'Not closed',
            STATE_RESERV: '(reserve)',
            STATE_MILEAGE_WARNING: 'ATTENTION! The mileage recorded in this electronic unit is significantly different from the mileage stored in the ESP electronic unit',
            STATE_SDM_UPTIME: 'Last power on/restart', 
            STATE_VAS_ACTIVATION: 'Security alarm status', 
            STATE_LOCK_SECURE: 'Set on guard',
            STATE_CRUISING_RANGE: 'Mileage on fuel residues', 

            // Params after BC reset
            STATE_TOTAL_MILEAGE_AM: 'Total mileage after BC reset',
            STATE_TOTAL_AVG_SPEED_AM: 'Average speed after BC reset',
            STATE_TOTAL_MINS_AM: 'Time after BC reset',
            STATE_FUEL_CONSUMPTION: 'Fuel consumption after BC reset', 

            // Battery
            STATE_SOH_HV_AKB: 'HV Battery health',
            STATE_SOC_HV_AKB: 'HV battery capacity',
            STATE_LVB_SOH: 'Battery health',
            STATE_LVB_SOC: 'Battery capacity',
            STATE_BATTERY_STATUS: 'Battery status', 
            STATE_BATTERY_VOLTAGE: 'Battery voltage', 

            // History params charts
            HISTORY:                        'Archive of states',
            HISTORY_DESCR:                  'View the history of states for a selected period of time',
            HISTORY_REQUEST_TEXT:           'Select the start and end time of the readings',
            HISTORY_START:                  'Start',
            HISTORY_END:                    'End',
            HISTORY_SEARCH:                 'Search by name...',
            HISTORY_PARAMS:                 'Params',
            HISTORY_TIME:                   'Time',
            HISTORY_ERROR_PARAMS:           'Params not selected!',
            HISTORY_ERROR_RANGE:            'The maximum interval for requesting charts is 31 days',
            HISTORY_ERROR_TIME_INCORRECT:   'The start time for recording graphs should be earlier than the end time',
            HISTORY_ERROR_TIME_EMPTY:       'Specify the start and end dates of the route',
            HISTORY_EMPTY_POINTS:           'No points recorded for the selected time interval',
            HISTORY_EMPTY_VALUE:            'No states recorded for the selected time interval',
            HISTORY_SHOW_GRID:              'Show grid',
            HISTORY_INSTRUCTION:            'Click on the chart with the left mouse button, then drag to enlarge the selected area. To move the chart, hold down Ctrl key',
            HISTORY_INSTRUCTION_PANNING:    'To zoom in, stretch the area on the graph. To shift the graph, swipe with two fingers',

            // Events
            EVENT_VIN:                  'VIN',
            EVENT_TYPE:                 'Event',
            EVENT_MAP:                  'Event on map',
            EVENT_SEARCH:               'Search events',
            EVENT_DATE:                 'Date',
            EVENT_COORDS:               'Coordinates',
            EVENT_GOTO_CAR:             'Go to car',
            EVENT_ENGINE_ON:            'Turning on the engine',
            EVENT_REMOTE_ENGINE_ON:     'Remote engine start-up',
            EVENT_ENGINE_OFF:           'Turning off the engine',
            EVENT_DECREASE_MILEAGE:     'Decrease in total mileage',
            EVENT_PROTECTED_AREA_IN:    'Entrance to the security zone',
            EVENT_PROTECTED_AREA:       'Exit from the security zone',
            EVENT_UNLOCKED:             'Disarming the vehicle',
            EVENT_LOCKED:               'Putting the car under protection',
            EVENT_CMD_ENGINE_ON:        'The command to start the engine has been sent',
            EVENT_CMD_ENGINE_OFF:       'The command to stop the engine was sent',
            EVENT_CMD_UNLOCK:           'A command to disarm has been sent',
            EVENT_CMD_LOCK:             'A team has been sent to secure it',
            EVENT_CMD_TRUNK_OPEN:       'A command was sent to open the trunk',
            EVENT_CMD_TRUNK_CLOSE:      'Sent a command to close the trunk',
            EVENT_CMD_LIGHT:            'The flash lights command was sent',
            EVENT_CMD_BEEP:             'The beep command has been sent',
            EVENT_LOW_BATTERY_VOLTAGE:  'Low battery charge',
            EVENT_ALARM_SYSTEM_TRIGGERED:'Triggering of the standard alarm',

            // Events on map
            MAP_EVENTS:                 'Events',
            MAP_EVENTS_DESCR:           'Choose the event groups to display on the map',
            MAP_EVENTS_SECURE:          'Secure',
            MAP_EVENTS_SECURE_ADD:      'On/off',
            MAP_EVENTS_ENGINE:          'Engine',
            MAP_EVENTS_ENGINE_ADD:      'Start/stop',
            MAP_EVENTS_TRUNK:           'Trunk',
            MAP_EVENTS_TRUNK_ADD:       'Open/close',
            MAP_EVENTS_ZONE:            'Secure zone',
            MAP_EVENTS_ZONE_ADD:        'Enter/exit',
            MAP_EVENTS_LIGHT:           'Light and signal',
            MAP_EVENTS_MILEAGE:         'Reducing mileage',
            MAP_EVENTS_VOLTAGE:         'Low battery charge',
            MAP_EVENTS_ALL:             'Show all',
            MAP_EVENTS_ALARM_SYSTEM_TRIGGERED:'Triggering of the standard alarm',

            // Application errors
            ERROR:                      'Error',
            ENotFound:                  'Not found',
            EUnknown:                   'Application error [#2]. Please inform the administrator!',
            ECursor:                    'Application error [#3]. Please inform the administrator!',
            EQuery:                     'Application error [#4]. Please inform the administrator!',
            EUuid:                      'Application error [#5]. Please inform the administrator!',
            EUpdate:                    'No rows affected by update',
            ECaptchaExpired:            'Captcha is expired',
            ECaptchaCheckError:         'Captcha check error',
            ECaptchaError:              'Captcha error',
            ECodeExpired:               'Code is expired',
            ECodeCheckError:            'Code check error',
            ECodeError:                 'Code error',
            ETokenExpired:              'Token is expired, please authorize', 
            ETokenError:                'Token is invalid, please try refresh page or repeat authorization',
            EUserEmail:                 'Email not valid or empty',
            EUserPassword:              'Password not valid or empty',
            EPassword:                  'The entered password does not meet the minimum security requirements',
            EUserEmailAlreadyExist:     'This e-mail address has already been used for one of the users',
            EUserNotFound:              'User name or password error',
            EUserEmptyPasswordOrCode:   'Password or code empty',
            EUserPermissionDenied:      'Permission denied',
            EUserFromContext:           'User value not stored in ctx',
            EUserSelfDelete:            'User try to self delete',
            EUserSendCode:              'Send code error',
            EUserAlreadyDeleted:        'User already deleted',
            EUserCarsNotFound:          'No cars available',
            EUserCursor:                'Cursor error',
            EConnection:                'Reqesst error: Internet connection have been lost or server is unavailable',
            EEcuFileAlreadyDeleted:     'This file has been deleted',
            EEcuFileNotFound:           'Files not found',
            EEcuFileCursor:             'Application error, please inform the administrator!',
            EEcuFileName:               'EEcuFileName',
            EEcuFileVersion:            'EEcuFileVersion',
            EEcuFileAuthor:             'EEcuFileAuthor',
            EEcuFileType:               'EEcuFileType',
            EEcuFileUuidOrBlock:        'Application error, please inform the administrator!',
            EUnauthRequest:             'Request failed: please log-in first',
            EFormFields:                'Form fields error',
            EPasswordShort:             'Password must be longer than 6 symbols',
            EEcuNotFound:               'ECU`s with the specified data were not found',
            EEcuAlreadyDeleted:         'This ECU has already been deleted',
            EEcuCarNotFound:            'ECU`s not found',
            EWSNotSuport:               'Your browser does not support websocket technology',
            EWSError:                   'Application error, please inform the administrator!',
            EWSDisconnected:            'The data server is unavailable',
            EGarageNumber:              'Incorrect garage number',
            EStartAfterStopOrEqual:     'The start time is greater than or equal to the end time of monitoring',

            ECommandSendTimeOut:                            'Command not sent [send timeout]',
            ECommandNotFound:                               'Command not found',
            EEgtsParamNotFound:                             'Param not found',
            ECommandSendError:                              'Command send error',
            EEgtsModuleNotFound:                            'Egts module not found',
            ECommandDataOverflow:                           'Command data to big',
            ECommandResponseUnknown:                        'Default, unknown error',
            ECommandResponseNotRespond:                     'ECU not respond',
            ECommandResponseUnknownCommand:                 'Unknown command',
            ECommandResponseUnknownAddress:                 'Unknown address ECU',
            ECommandResponseIgnitionIsOff:                  'To execute this command, turn on the ignition',
            ECommandResponseIgnitionIsOn:                   'To execute this command, turn off the ignition',
            ECommandResponseDoorIsUnlock:                   'To execute this command, lock the doors',
            ECommandResponseLowFuelLevel:                   'Insufficion fuel in the tank to execute this command',
            ECommandResponseLowSoC:                         'The battery charge is too low to execute this command',
            ECommandResponseEngineRemoteStartNotSupport:    'Remote engine start is not supported',
            ECommandResponseEngineRemoteStartModeNeeded:    'To execute the command, it is required that the car be in remote start mode',
            ECommandResponseParkingModeOfTCUNeeded:         'To execute this command, switch the transmission to parking mode',

            EEgtsFirmwareNotFound:      'Software files are not found',
            EEventNotFound:             'Events for the specified time period were not found',
            EFileName:                  'The file with the specified name was not found',
            EEgtsReportNotFound:        'No diagnostic records found',
            EPhone:                     'Error when entering a phone number',
            ECarPermissionGranted:      'Access to all cars',
            ELogNotFound:               'Logs not found',
            EVin:                       'No VIN-code selection',
            EUserAlreadyExist:          'User with this email or phone already exist',
            EEmail:                     'Invalid characters in the E-mail field',
            EFavoriteFound:             'There are no favorite cars. You can assign favorite cars by clicking on the star icon in the car card from the list.',
            EPolicyRuleNotFound:        'Rules not found',
            EPolicyOneNotFound:         'Policies not found',
            EPolicySetNotFound:         'Sets not found',

            // Cars & car types
            ECarVin:                    'This VIN-code has already been used for one of the cars',
            ECarAlreadyDeleted:         'This car has already been deleted',
            ECarAlreadyExist:           'This car has already exist',
            ECarNumber:                 'Incorrect car number',
            ECarPointNotFound:          'Route entries for the selected time period were not found',
            ECarUserNotFound:           'Car users not found',
            ECarNotOnLine:              'Device is not online',
            ECarPermissionDenied:       'You do not have access to this information',
            ECarNotFound:               'Cars with the specified data were not found',
            ECarTypeAlreadyExist:       'Car type already exits',
            ECarTypeNotFound:           'Car type not found',
            ECarPointAlreadyExist:      'Car point already exist',
            ECarStateNotFound:          'Car state not found',
            ECarTypeCursor:             'Car types request failed',

            // Online graph
            CHART_ONLINE_TEST:          'The component is working in test mode. The data on the charts is created by the simulator',
            CHART_ONLINE_TITLE:         'Live graph',
            CHART_ONLINE_SEARCH:        'Search params',
            CHART_ONLINE_SEARCH_DESCR:  'Enter the name or part of the parameter name',
            CHART_ONLINE_SHOW_GRID:     'Show grid',
            CHART_ONLINE_ERROR_TOKEN:   'Authorization error on the data server',
            CHART_ONLINE_ERROR_1:       'Error parsing the received data',
            CHART_ONLINE_TIMEOUT:       'The device is not responding: it may be busy. Try requesting the data later',
            CHART_ONLINE_PERIOD:        'Update frequency [sec.]',

            // Logs
            LOGS:                       'Logs',
            LOG:                        'Log',
            LOGS_TYPE:                  'Logs type',
            LOGS_TYPE_EGTS:             'Cars',
            LOGS_TYPE_GRPC:             'Users',
            LOGS_USER_ALL:              'All users',
            LOGS_VIN:                   'VIN',
            LOGS_VIN_MODAL:             'Select car',
            LOGS_USER_MODAL:            'Select user',
            LOGS_DATE:                  'Date',
            LOGS_USER:                  'User',
            LOGS_ACTION:                'Action',
            LOGS_ACTION_LOGIN:          'Login',
            LOGS_ACTION_DELETE:         'Delete',
            LOGS_ACTION_INSERT:         'Insert',
            LOGS_ACTION_UPDATE:         'Update',
            LOGS_ACTION_WRITE:          'Write',
            LOGS_ACTION_SEND_CMD:       'Send CMD',
            LOGS_ACTION_SEND_NOTI:      'Send notification',
            LOGS_ACTION_SEND_PARAMS:    'Send params',
            LOGS_ITEM_DATE:             'Date',
            LOGS_ITEM_GEO:              'Location',
            LOGS_ERROR_NO_PARAMS:       'Select params to request logs',

            // Car colors
            CAR_COLOR_BLACK_COPPER:     "Black copper",
            CAR_COLOR_BLUE_MAGIC:       "Blue magic",
            CAR_COLOR_DEEP_FOREST:      "Deep forest",
            CAR_COLOR_DESERT_RED:       "Desert red",
            CAR_COLOR_DESERT_TAUPE:     "Desert taupe",
            CAR_COLOR_GALANT_GREY:      "Galant grey",
            CAR_COLOR_GRAPHITE_TRACE:   "Graphite trace",
            CAR_COLOR_LATTE_MACCHIATO:  "Latte macchiato",
            CAR_COLOR_LUSTRE_WHITE:     "Lustre white",
            CAR_COLOR_NOT_SET:          "Not set",
            CAR_COLOR_PORCELAIN:        "Porcelain",
            CAR_COLOR_PULSAR_BLUE:      "Pulsar blue",
            CAR_COLOR_RUSSIAN_GOLD:     "Russian gold",
            CAR_COLOR_SHADE_OF_NIGHT:   "Shade of night",
            CAR_COLOR_SILVER_LINING:    "SIlver lining",
            CAR_COLOR_SUNSET_GOLD:      "Sunset gold",
            CAR_COLOR_UNIVERSE_BLACK:   "Universe black",
            CAR_COLOR_VELVET_RUBY:      "Velvet ruby",

            // ECU`s
            ECUS_ADDITIONAL:                'Additional info',
            ECUS_STATUS_DESCR:              'Status description',
            ECUS_DESCR_BADGES:              'Badges',
            ECUS_DESCR_SDM:                 'SDM',
            ECUS_DESCR_SDM_ONLINE:          'SDM online',
            ECUS_DESCR_SDM_NOSATELLITES:    'SDM online, no satellites connection',
            ECUS_DESCR_SDM_OFFLINE:         'SDM offline',
            ECUS_DESCR_ICON_ERROR:          'Number of active errors',
            ECUS_DESCR_ICON_PASSIVE:        'Number of passive errors',
            ECUS_DESCR_ICON_WARNING:        'Warnings',
            ECUS_DESCR_BG:                  'ECU colors',
            ECUS_DESCR_BG_ONLINE:           'Without errors / with passive errors',
            ECUS_DESCR_BG_OFFLINE:          'No diagnostics for more than 30 days',
            ECUS_DESCR_BG_ERROR:            'With active errors',
            ECUS_DESCR_BG_NO_INFO:          'No Data',

            // ECU
            ECU_EXPORT:                 'Export report',
            ECU_INFO:                   'Information',
            ECU_ERRORS:                 'Errors',
            ECU_ERRORS_ONLY_ACTIVE:     'Only active',
            ECU_PARAMS:                 'Params',
            ECU_PARAMS_DISABLED:        'Charts online / Online telemetry: development is on hold',
            ECU_SERIAL:                 'Serian no.',
            ECU_HARDWARE:               'Hardware',
            ECU_FIRMWARE:               'Firmware',
            ECU_VIN:                    'ECU VIN',
            ECU_CALIBRATION:            'Calibration',
            ECU_MILEAGE:                'Milleage',
            ECU_SOFTWARE:               'Software',
            ECU_TIMESTAMP:              'Last diagnistic time',
            ECU_ERRORS_EMPTY:           'No errors detected',
            ECU_HISTORY:                'Reports history',
            ECU_FILE:                   'Files',
            ECU_REMOVE:                 'Delete',

            ECU_SDM_REBOOT:             'Reboot SDM',
            ECU_SDM_SOFTWARES:          'Softwares',
            ECU_SDM_LOGS:               'Logs',
            ECU_SDM_UPDATE_DIAG:        'Update Diag-CAN',
            ECU_SDM_DESCR:              'Description',
            ECU_SDM_SOFTWARE:           'Software',
            ECU_SDM_UPDATE_NAVI:        'Navigation update',
            ECU_SDM_MANUFACTORER:       'Manufactorer code',
            ECU_SDM_CREATE_DATE:        'Hardware',
            ECU_SDM_UPDATE_PARAMS:      'Update Body-CAN',
            ECU_SDM_MODIFICATION:       'Modification',
            ECU_SDM_TID:                'id SDM',
            ECU_SDM_SET_SERVER:         'Set data server',
            ECU_SDM_SEND_CMD:           'Send CMD',
            ECU_SDM_ONLINE_DATE:        'Last contact time',

            ECU_FIRMWARE_CREATE_SUCCESS:'The firmware file has been successfully uploaded to the server. Wait for the download to the device',
            ECU_FIRMWARE_DELETE_SUCCESS:'The firmware file has been successfully deleted',

            FAVORITE_CARS:                  'Favourites',
            FAVORITE_CARS_ADD:              'Add favorites',
            FAVORITE_CARS_EMPTY:            'There are no favorite cars. Click "Add" and select the desired cars from the list',
            FAVORITE_CARS_CREATE_SUCCESS:   'Car successfully added to favorites',
            FAVORITE_CARS_CREATE_FAILURE:   'An error occurred while trying to add a favorite vehicle. Notify the administrator!',
            FAVORITE_CARS_DELETE_HEADER:    'An error occurred while trying to delete a favorite vehicle. Notify the administrator!',
            FAVORITE_CARS_DELETE_DESCR:     'An error occurred while trying to delete a favorite vehicle. Notify the administrator!',
            FAVORITE_CARS_DELETE_FAILURE:   'An error occurred while trying to delete a favorite vehicle. Notify the administrator!',
            FAVORITE_CARS_ERROR_DATA:       'An error occurred while trying to get favorite cars. Please retry your request later',

            POLICY:                             'Access control center',
            POLICY_USERS:                       'Users policy',

            POLICY_PERMIT:                      'Permit',
            POLICY_DENY:                        'Deny',

            // Attributes modules
            POLICY_AREA:                        'Zones',
            POLICY_AUTH:                        'Authorization',
            POLICY_CAR:                         'Cars',
            POLICY_EGTS:                        'EGTS',
            POLICY_EVENT:                       'Eventrs',
            POLICY_FAVORITE:                    'Favorites cars',
            POLICY_LOGS:                        'Logs',
            POLICY_POINT:                       'Points',
            POLICY_POLICY:                      'Policy',
            POLICY_USER:                        'Policy user',
            POLICY_REMOTE:                      'Remote control',

            // Атрибуты модулей
            POLICY_ATTR:                        'Attributes',

            POLICY_AUTH_CAN_AUTHORIZATION:      'Auth - Read',
            POLICY_AUTH_CAN_LOGIN:              'Auth - Add',
            POLICY_AUTH_CAN_SEND_CODE:          'Auth - Delete',
            POLICY_AUTH_ATTR:                   'Auth - Attributes',

            POLICY_EGTS_ATTR:                   'EGTS - Attributes',
            POLICY_EGTS_LIST_MODULES:           'EGTS - Read modules',
            POLICY_EGTS_LIST_PARAM:             'EGTS - Read params',
            POLICY_EGTS_LIST_FIRMWARE:          'EGTS - Read firmwares',
            POLICY_EGTS_LIST_LAST_REPORT:       'EGTS - Read list last report',
            POLICY_EGTS_LIST_REPORT:            'EGTS - Read list reports',
            POLICY_EGTS_GET_PARAM:              'EGTS - Read params',
            POLICY_EGTS_GET_PDF_REPORT:         'EGTS - Read PDF-report',
            POLICY_EGTS_EMAIL_PDF_REPORT:       'EGTS - Send PDF-report on e-mail',
            POLICY_EGTS_SET_PARAM:              'EGTS - Set params',
            POLICY_EGTS_ADD_FIRMWARE:           'EGTS - Download firmwares',
            POLICY_EGTS_DEL_FIRMWARE:           'EGTS - Delete firmwares',
            POLICY_EGTS_DEL_REPORT:             'EGTS - Delete reports',
            POLICY_EGTS_GET_LAST_REPORT:        'EGTS - Read last reports',
            POLICY_EGTS_SEND_COMMAND:           'EGTS - Send commands',

            POLICY_ATTR_AREA:                   'Zones',
            POLICY_AREA_DELETE:                 'Zones - Delete',
            POLICY_AREA_EDIT:                   'Zones - Edit',
            POLICY_AREA_GET:                    'Zones - Get',
            POLICY_AREA_LIST:                   'Zones - List',
            POLICY_AREA_ATTR:                   'Zones - Attributes',

            POLICY_CAR_ATTR:                    'Cars - Attributes',
            POLICY_CAR_GET:                     'Cars - Read',
            POLICY_CAR_LIST:                    'Cars - List',
            POLICY_CAR_EDIT:                    'Cars - Edit',
            POLICY_CAR_ADD:                     'Cars - Add',
            POLICY_CAR_DELETE:                  'Cars - Delete',

            POLICY_LOG_ATTR:                    'Logs - Attributes',
            POLICY_LOG_LIST:                    'Logs - List',

            POLICY_EVENT_GET:                   'Events - Read',
            POLICY_EVENT_LIST:                  'Events - List',
            POLICY_EVENT_TOKEN_PUT:             'Events - Put token',
            POLICY_EVENT_TOKEN_DEL:             'Events - Delete token',
            POLICY_EVENT_PARAM_LIST:            'Events - Params list',
            POLICY_EVENT_PARAM_PUT:             'Events - Put params',
            POLICY_EVENT_PARAM_DEL:             'Events - Delete params',
            POLICY_EVENT_ATTR:                  'Events - Attributes',

            POLICY_FAVORITE_GET:                'Events - Read',
            POLICY_FAVORITE_PUT:                'Events - Add',
            POLICY_FAVORITE_DEL:                'Events - Delete',
            POLICY_FAVORITE_ATTR:               'Events - Attributes',

            POLICY_POINT_PWS_SUBSCRIBE:         'Points - Get by WS',
            POLICY_POINT_LATEST_POINTS:         'Points - Last points',
            POLICY_POINT_LATEST_STATES:         'Points - Last states',
            POLICY_POINT_TRACK:                 'Points - Track',
            POLICY_POINT_MILEAGE:               'Points - Mileage',
            POLICY_POINT_ATTR:                  'Points - Attributes',

            POLICY_USER_ATTR:                   'Users - Attributes',
            POLICY_USER_GET:                    'Users - Read',
            POLICY_USER_LIST:                   'Users - List',
            POLICY_USER_PUT:                    'Users - Edit',
            POLICY_USER_DEL:                    'Users - Delete',

            POLICY_REMOTE_CONTROL_ATTR:         'Remote control - Attributes',

            POLICY_POLICY_RULE_DELETE:          'Rules - Delete',
            POLICY_POLICY_RULE_EDIT:            'Rules - Edit',
            POLICY_POLICY_RULE_GET:             'Rules - Read',
            POLICY_POLICY_RULE_LIST:            'Rules - List',

            POLICY_POLICY_ONE_DELETE:           'Ones - Delete',
            POLICY_POLICY_ONE_EDIT:             'Ones - Edit',
            POLICY_POLICY_ONE_GET:              'Ones - Read',
            POLICY_POLICY_ONE_LIST:             'Ones - List',
            POLICY_POLICY_ATTR:                 'Ones - Attributes',

            POLICY_POLICY_SET_DELETE:           'Sets - Delete',
            POLICY_POLICY_SET_EDIT:             'Sets - Edit',
            POLICY_POLICY_SET_GET:              'Sets - Read',
            POLICY_POLICY_SET_LIST:             'Sets - List',

            POLICY_POLICY_LINK_DELETE:          'Links - Delete',
            POLICY_POLICY_LINK_ADD:             'Links - Add',

            POLICY_ATTRS:                       'Attributes',
            POLICY_ATTRS_EMPTY:                 'No attributes',
            POLICY_ATTRS_DELETE:                'Delete attribute',
            POLICY_ATTRS_DELETE_DESCR:          'This attribute will be deleted without possibility of restoration. Are you sure you want to delete it?',
            POLICY_ATTRS_DELETE_SUCCESS:        'Attribute deleted',
            POLICY_ATTRS_DELETED:               '- deleted attribute -',

            POLICY_ATTR_AUTH:                   'Authorization',
            POLICY_ATTR_EGTS:                   'EGTS',
            POLICY_ATTR_LOGS:                   'Logs',
            POLICY_ATTR_CARS:                   'Cars',
            POLICY_ATTR_EVENTS:                 'Events',
            POLICY_ATTR_FAVORITE:               'Favorite',
            POLICY_ATTR_POINTS:                 'Points',
            POLICY_ATTR_USERS:                  'Users',
            
            POLICY_DESCR:                       'Description',
            POLICY_EFFECT:                      'Effect',
            POLICY_ALGORYTHM:                   'Algorythm',
            POLICY_ALGORYTHM_NS:                'Not set',
            POLICY_ALGORYTHM_PO:                'True if one or more is true',
            POLICY_ALGORYTHM_DO:                'False if one or more lies',
            POLICY_ALGORYTHM_FA:                'Only the first solution',
            POLICY_ALGORYTHM_HP:                'Only the solution with the highest priority',
            POLICY_PRIORITY:                    'Priority',
            POLICY_MODULES:                     'Modules',

            POLICY_RULES:                       'Rules',

            POLICY_RULES_CREATE:                'Create rule',
            POLICY_RULES_CREATE_SUCCESS:        'New rule created',

            POLICY_RULES_DELETE:                'Delete rule',
            POLICY_RULES_DELETE_DESCR:          'This rule will be deleted without possibility of restoration. Are you sure you want to delete it?',
            POLICY_RULES_DELETE_SUCCESS:        'Rule deleted',
            POLICY_RULES_DELETE_ERROR:          'Error when trying to delete a rule',

            POLICY_RULES_CAR_ADD:               'Add car',
            POLICY_RULES_CAR_GET:               'Get car',
            POLICY_RULES_CAR_EDIT:              'Edit car',
            POLICY_RULES_CAR_DELETE:            'Delete car',
            POLICY_RULES_CAR_LIST:              'Get cars list',
            POLICY_RULES_CAR_ATTR:              'Get cars attributes',

            POLICY_RULES_EVENT_GET:             'Get events',
            POLICY_RULES_EVENT_LIST:            'Get events list',
            POLICY_RULES_EVENT_ATTR:            'Get events attributes',
            POLICY_RULES_EVENT_TOKEN_PUT:       'Put token [Firebase]',
            POLICY_RULES_EVENT_TOKEN_DELETE:    'Delete token [Firebase]',
            POLICY_RULES_EVENT_PARAM_LIST:      'Get subscribe state',
            POLICY_RULES_EVENT_PARAM_PUT:       'Put subscribe params',
            POLICY_RULES_EVENT_PARAM_DELETE:    'Delete subscribe params',

            POLICY_RULES_USER_ADD:              'Add users',
            POLICY_RULES_USER_GET:              'Get users',
            POLICY_RULES_USER_DELETE:           'Delete users',
            POLICY_RULES_USER_LIST:             'Get users list',
            POLICY_RULES_USER_ATTR:             'Get users attributes',

            POLICY_RULE:                        'Rule',
            POLICY_RULE_UPDATE_SUCCESS:         'Rule updated',
            POLICY_RULE_ATTR_CREATE:            'Add attributes',
            POLICY_RULE_ATTR_CREATE_DESCR:      'Description',
            POLICY_RULE_ATTR_CREATE_SUCCESS:    'The selected attributes are now added to the rule',

            POLICY_ONES:                        'Policies',
            POLICY_ONES_CREATE:                 'Create policy',
            POLICY_ONES_CREATE_SUCCESS:         'New policy created',
            POLICY_ONES_DELETE:                 'Delete policy',
            POLICY_ONES_DELETE_DESCR:           'This policy will be deleted without possibility of restoration. Are you sure you want to delete it?',
            POLICY_ONES_DELETE_SUCCESS:         'Policy deleted',
            POLICY_ONES_DELETE_ERROR:           'Error when trying to delete a policy',

            POLICY_ONE:                         'One',
            POLICY_ONE_UPDATE_SUCCESS:          'Policy updated',
            POLICY_ONE_RULES_CREATE:            'Add rules',
            POLICY_ONE_RULES_CREATE_SUCCESS:    'Selected rule successfuly added',
            POLICY_ONE_RULE_DELETE:             'Ones rule delete',
            POLICY_ONE_RULE_DELETE_DESCR:       'This rule will be removed from the one. Are you sure you want to delete it?',
            POLICY_ONE_RULE_DELETE_SUCCESS:     'Rule deleted from this one',

            POLICY_SETS:                        'Sets',
            POLICY_SETS_CREATE:                 'Create set',
            POLICY_SETS_CREATE_DESCR:           'Description',
            POLICY_SETS_CREATE_SUCCESS:         'New set created',
            POLICY_SETS_DELETE:                 'Delete set',
            POLICY_SETS_DELETE_DESCR:           'This set will be deleted without possibility of restoration. Are you sure you want to delete it?',
            POLICY_SETS_DELETE_SUCCESS:         'Set deleted',
            POLICY_SETS_DELETE_ERROR:           'Error when trying to delete a set',

            POLICY_SET:                         'Set',
            POLICY_SET_UPDATE_SUCCESS:          'Set updated',
            POLICY_SET_ONES_CREATE:             'Add ones',
            POLICY_SET_ONES_CREATE_SUCCESS:     'Selected one successfuly added',
            POLICY_SET_ONE_DELETE:              'Set one delete',
            POLICY_SET_ONE_DELETE_DESCR:        'This one will be removed from the set. Are you sure you want to delete it?',
            POLICY_SET_ONE_DELETE_SUCCESS:      'One deleted from this set',

            POLICY_USERS_EMPTY:                 'Users not found',
            POLICY_USER_SETS_EMPTY:             'Sets not found',
            POLICY_USER_SET_DELETE:             'User set delete',
            POLICY_USER_SET_DELETE_DESCR:       'This set will be removed from the user. Are you sure you want to delete it?',
            POLICY_USER_SET_DELETE_SUCCESS:     'Set deleted from this user',

            // MANUALS
            MANUAL_CAR:                         'Vehicle Operation manual',
            MANUAL_MMS:                         'Multimedia system operation manual',

            COPYRIGHT:                          'Provided by FSUE "NAMI", 2022',

            // 404
            NOTFOUND_SUBTITLE:              'Page not found',
            NOTFOUND_LINK:                  'To the main'
        }
    },

    getters: {

        getTranslate: (state, getters) => state[getters.getLang]
    }
}