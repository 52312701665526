import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/index.js'
import * as bootstrap from 'bootstrap'

const router = createRouter({

    history: createWebHistory(process.env.BASE_URL),

    routes: [

        {
            path: '/',
            name: 'main',
            component: () => import('@/views/Cars.vue'),
            meta: {
                onlyAuth: true,
                breadcrumb: []
            }
        },

        {
            path: '/auth',
            name: 'auth',
            component: () => import('@/views/Auth.vue'),
            meta: {
                notAuth: true,
                breadcrumb: []
            }
        },

        {
            path: '/profile',
            name: 'profile',
            component: () => import('@/views/Profile.vue'),
            meta: {
                onlyAuth: true,
                breadcrumb: [
                    { name: store.getters.getTranslate.MENU_MAIN, link: '/' },
                    { name: store.getters.getTranslate.PROFILE }
                ]
            }
        },

        {
            path: '/cars',
            name: 'cars',
            component: () => import('@/views/Cars.vue'),
            meta: {
                onlyAuth: true,
                breadcrumb: [
                    { name: store.getters.getTranslate.MENU_MAIN, link: '/' },
                    { name: store.getters.getTranslate.CARS }
                ]
            }
        },

        {
            path: '/cars/:vin',
            name: 'car',
            props: true,
            component: () => import('@/views/Car.vue'),
            meta: {
                onlyAuth: true,
                breadcrumb: [
                    { name: store.getters.getTranslate.MENU_MAIN, link: '/' },
                    { name: store.getters.getTranslate.CAR }
                ]
            },
            children: [{

                path: '/cars/:vin',
                name: store.getters.getTranslate.MENU_INFORMATION,
                props: true,
                component: () => import('@/components/car/ADTCarInfo.vue'),
                meta: {
                    onlyAuth: true,
                    breadcrumb: [
                        { name: store.getters.getTranslate.MENU_MAIN, link: '/' },
                        { name: store.getters.getTranslate.CAR, link: '/' },
                        { name: store.getters.getTranslate.MENU_INFORMATION }
                    ]
                }

            }, {

                path: 'ecus',
                name: store.getters.getTranslate.CARMENU_ECUS,
                props: true,
                component: () => import('@/components/car/ADTCarEcus.vue'),
                meta: {
                    onlyAuth: true,
                    breadcrumb: [
                        { name: store.getters.getTranslate.MENU_MAIN, link: '/' },
                        { name: store.getters.getTranslate.CAR },
                        { name: store.getters.getTranslate.MENU_ECUS }
                    ]
                },
                children: [{

                    path: 'ecus',
                    name: store.getters.getTranslate.CARMENU_ECUS,
                    props: true,
                    component: () => import('@/components/car/ADTCarEcusList.vue'),
                    meta: {
                        onlyAuth: true,
                        breadcrumb: [
                            { name: store.getters.getTranslate.MENU_MAIN, link: '/' },
                            { name: store.getters.getTranslate.CAR, link: '/' },
                            { name: store.getters.getTranslate.CARMENU_ECUS }
                        ]
                    }
                }, {

                    path: '/cars/:vin/ecus/:ecuName',
                    name: 'ecu',
                    props: true,
                    component: () => import('@/components/car/ADTCarEcu.vue'),
                    meta: {
                        onlyAuth: true,
                        breadcrumb: [
                            { name: store.getters.getTranslate.MENU_MAIN, link: '/' },
                            { name: store.getters.getTranslate.CAR, link: '/' },
                            { name: store.getters.getTranslate.CARMENU_ECUS }
                        ]
                    }
                }, {

                    path: '/cars/:vin/ecus/SDM',
                    name: 'sdm',
                    props: true,
                    component: () => import('@/components/car/ADTCarSDM.vue'),
                    meta: {
                        onlyAuth: true,
                        breadcrumb: [
                            { name: store.getters.getTranslate.MENU_MAIN, link: '/' },
                            { name: store.getters.getTranslate.CAR, link: '/' },
                            { name: store.getters.getTranslate.CARMENU_ECUS }
                        ]
                    }
                }]
            }, {

                path: 'map',
                name: store.getters.getTranslate.CARMENU_MAP,
                props: true,
                component: () => import('@/components/car/ADTCarMap.vue'),
                meta: {
                    onlyAuth: true,
                    breadcrumb: [
                        { name: store.getters.getTranslate.MENU_MAIN, link: '/' },
                        { name: store.getters.getTranslate.CAR },
                        { name: store.getters.getTranslate.CARMENU_MAP }
                    ]
                }

            }, {

                path: 'states',
                name: store.getters.getTranslate.CARMENU_STATE,
                props: true,
                component: () => import('@/components/car/ADTCarStates.vue'),
                meta: {
                    onlyAuth: true,
                    breadcrumb: [
                        { name: store.getters.getTranslate.MENU_MAIN, link: '/' },
                        { name: store.getters.getTranslate.CAR },
                        { name: store.getters.getTranslate.CARMENU_STATE }
                    ]
                }
            }, {

                path: 'users',
                name: store.getters.getTranslate.CARMENU_USERS,
                props: true,
                component: () => import('@/components/car/ADTCarUsers.vue'),
                meta: {
                    onlyAuth: true,
                    breadcrumb: [
                        { name: store.getters.getTranslate.MENU_MAIN, link: '/' },
                        { name: store.getters.getTranslate.CAR },
                        { name: store.getters.getTranslate.CARMENU_USERS }
                    ]
                },

            },{

                path: 'events',
                name: store.getters.getTranslate.CARMENU_EVENTS,
                props: true,
                component: () => import('@/components/car/ADTCarEvents.vue'),
                meta: {
                    onlyAuth: true,
                    breadcrumb: [
                        { name: store.getters.getTranslate.MENU_MAIN, link: '/' },
                        { name: store.getters.getTranslate.CAR },
                        { name: store.getters.getTranslate.CARMENU_USERS }
                    ]
                },
        
            },
            {

                path: 'history',
                name: store.getters.getTranslate.CARMENU_HISTORY,
                props: true,
                component: () => import('@/components/ADTChartHistory.vue'),
                meta: {
                    onlyAuth: true,
                    breadcrumb: [
                        { name: store.getters.getTranslate.MENU_MAIN, link: '/' },
                        { name: store.getters.getTranslate.CAR },
                        { name: store.getters.getTranslate.CARMENU_USERS }
                    ]
                },
            }]
        },

        {
            path: '/guide',
            name: 'guide',
            component: () => import('@/views/Guide.vue'),
            meta: {
                onlyAuth: true,
                breadcrumb: [
                    { name: store.getters.getTranslate.MENU_MAIN, link: '/' },
                    { name: store.getters.getTranslate.GUIDE }
                ]
            }
        },

        {
            path: '/users',
            name: 'users',
            component: () => import('@/views/Users.vue'),
            meta: {
                onlyAuth: true,
                breadcrumb: [
                    { name: store.getters.getTranslate.MENU_MAIN, link: '/' },
                    { name: store.getters.getTranslate.USERS }
                ]
            }
        },

        {
            path: '/users/:uuid',
            name: 'user',
            props: true,
            component: () => import('@/views/User.vue'),
            meta: {
                onlyAuth: true,
                breadcrumb: [
                    { name: store.getters.getTranslate.MENU_MAIN, link: '/' },
                    { name: store.getters.getTranslate.USERS, link: '/users' },
                    { name: store.getters.getTranslate.USER }
                ]
            },
            children: [{
                    path: '/users/:uuid',
                    name: store.getters.getTranslate.MENU_INFORMATION,
                    props: true,
                    component: () => import('@/components/user/ADTUserInfo.vue'),
                    meta: {
                        onlyAuth: true,
                        breadcrumb: [
                            { name: store.getters.getTranslate.MENU_MAIN, link: '/' },
                            { name: store.getters.getTranslate.USERS, link: '/users' },
                            { name: store.getters.getTranslate.USER }
                        ]
                    }
                },
                {
                    path: 'logs',
                    name: store.getters.getTranslate.MENU_INFORMATION,
                    props: true,
                    component: () => import('@/components/user/ADTUserLogs.vue'),
                    meta: {
                        onlyAuth: true,
                        breadcrumb: [
                            { name: store.getters.getTranslate.MENU_MAIN, link: '/' },
                            { name: store.getters.getTranslate.USERS, link: '/users' },
                            { name: store.getters.getTranslate.USER }
                        ]
                    }
                },
                {
                    path: 'cars',
                    name: store.getters.getTranslate.MENU_INFORMATION,
                    props: true,
                    component: () => import('@/components/user/ADTUserCars.vue'),
                    meta: {
                        onlyAuth: true,
                        breadcrumb: [
                            { name: store.getters.getTranslate.MENU_MAIN, link: '/' },
                            { name: store.getters.getTranslate.USERS, link: '/users' },
                            { name: store.getters.getTranslate.USER }
                        ]
                    }

                }
            ]
        },

        {
            path: '/policy',
            name: 'policy',
            props: true,
            component: () => import('@/views/Policy.vue'),
            children: [
                {
                    path: '',
                    name: store.getters.getTranslate.POLICY,
                    props: true,
                    component: () => import('@/views/policy/PolicyUsers.vue'),
                    meta: {
                        onlyAuth: true,
                        breadcrumb: [
                            { name: store.getters.getTranslate.MENU_MAIN, link: '/' },
                            { name: store.getters.getTranslate.POLICY, link: '/policy' },
                        ]
                    }, 
                    children: [
                        {
                            path: '',
                            name: store.getters.getTranslate.POLICY_USERS,
                            props: true,
                            component: () => import('@/components/policy/users/ADTPolicyUsers.vue'),
                            meta: {
                                onlyAuth: true,
                                breadcrumb: [
                                    { name: store.getters.getTranslate.MENU_MAIN, link: '/' },
                                    { name: store.getters.getTranslate.POLICY, link: '/policy' }
                                ]
                            }
                        }, 
                        {
                            path: '/policy/users/:uuid',
                            name: store.getters.getTranslate.POLICY_USER,
                            props: true,
                            component: () => import('@/components/policy/users/ADTPolicyUser.vue'),
                            meta: {
                                onlyAuth: true,
                                breadcrumb: [
                                    { name: store.getters.getTranslate.MENU_MAIN, link: '/' },
                                    { name: store.getters.getTranslate.POLICY, link: '/policy' }
                                ]
                            }
                        }
                    ]
                }, 
                {
                    path: 'rules',
                    name: store.getters.getTranslate.POLICY_RULES,
                    props: true,
                    component: () => import('@/views/policy/PolicyRules.vue'),
                    meta: {
                        onlyAuth: true,
                        breadcrumb: [
                            { name: store.getters.getTranslate.MENU_MAIN, link: '/' },
                            { name: store.getters.getTranslate.POLICY, link: '/policy' },
                            { name: store.getters.getTranslate.POLICY_RULES }
                        ]
                    },
                    children: [
                        {
                            path: 'rules',
                            name: store.getters.getTranslate.POLICY_RULES,
                            props: true,
                            component: () => import('@/components/policy/rules/ADTPolicyRules.vue'),
                            meta: {
                                onlyAuth: true,
                                breadcrumb: [
                                    { name: store.getters.getTranslate.MENU_MAIN, link: '/' },
                                    { name: store.getters.getTranslate.POLICY, link: '/policy' }
                                ]
                            }
                        }, 
                        {
                            path: '/policy/rules/:uuid',
                            name: store.getters.getTranslate.POLICY_RULE,
                            props: true,
                            component: () => import('@/components/policy/rules/ADTPolicyRule.vue'),
                            meta: {
                                onlyAuth: true,
                                breadcrumb: [
                                    { name: store.getters.getTranslate.MENU_MAIN, link: '/' },
                                    { name: store.getters.getTranslate.POLICY, link: '/policy' },
                                    { name: store.getters.getTranslate.POLICY_RULES, link: '/policy/rules' },
                                ]
                            }
                        }
                    ]
                },
                {
                    path: 'ones',
                    name: store.getters.getTranslate.POLICY_ONES,
                    props: true,
                    component: () => import('@/views/policy/PolicyOnes.vue'),
                    meta: {
                        onlyAuth: true,
                        breadcrumb: [
                            { name: store.getters.getTranslate.MENU_MAIN, link: '/' },
                            { name: store.getters.getTranslate.POLICY, link: '/policy' },
                            { name: store.getters.getTranslate.POLICY_ONES }
                        ]
                    }, 
                    children: [
                        {
                            path: 'ones',
                            name: store.getters.getTranslate.POLICY_ONES,
                            props: true,
                            component: () => import('@/components/policy/ones/ADTPolicyOnes.vue'),
                            meta: {
                                onlyAuth: true,
                                breadcrumb: [
                                    { name: store.getters.getTranslate.MENU_MAIN, link: '/' },
                                    { name: store.getters.getTranslate.POLICY, link: '/policy' }
                                ]
                            }
                        }, 
                        {
                            path: '/policy/ones/:uuid',
                            name: store.getters.getTranslate.POLICY_ONE,
                            props: true,
                            component: () => import('@/components/policy/ones/ADTPolicyOne.vue'),
                            meta: {
                                onlyAuth: true,
                                breadcrumb: [
                                    { name: store.getters.getTranslate.MENU_MAIN, link: '/' },
                                    { name: store.getters.getTranslate.POLICY, link: '/policy' },
                                    { name: store.getters.getTranslate.POLICY_RULES, link: '/policy/ones' },
                                ]
                            }
                        }
                    ]
                },
                {
                    path: 'sets',
                    name: store.getters.getTranslate.POLICY_SETS,
                    props: true,
                    component: () => import('@/views/policy/PolicyOnes.vue'),
                    meta: {
                        onlyAuth: true,
                        breadcrumb: [
                            { name: store.getters.getTranslate.MENU_MAIN, link: '/' },
                            { name: store.getters.getTranslate.POLICY, link: '/policy' },
                            { name: store.getters.getTranslate.POLICY_SETS }
                        ]
                    }, 
                    children: [
                        {
                            path: 'sets',
                            name: store.getters.getTranslate.POLICY_SETS,
                            props: true,
                            component: () => import('@/components/policy/sets/ADTPolicySets.vue'),
                            meta: {
                                onlyAuth: true,
                                breadcrumb: [
                                    { name: store.getters.getTranslate.MENU_MAIN, link: '/' },
                                    { name: store.getters.getTranslate.POLICY, link: '/policy' }
                                ]
                            }
                        }, 
                        {
                            path: '/policy/sets/:uuid',
                            name: store.getters.getTranslate.POLICY_SET,
                            props: true,
                            component: () => import('@/components/policy/sets/ADTPolicySet.vue'),
                            meta: {
                                onlyAuth: true,
                                breadcrumb: [
                                    { name: store.getters.getTranslate.MENU_MAIN, link: '/' },
                                    { name: store.getters.getTranslate.POLICY, link: '/policy' },
                                    { name: store.getters.getTranslate.POLICY_RULES, link: '/policy/sets' },
                                ]
                            }
                        }
                    ]
                }
            ]
        },

        {
            path: '/test',
            name: 'test',
            props: true,
            component: () => import('@/views/Test.vue'),
            meta: {
                onlyAuth: true,
                breadcrumb: [
                    { name: store.getters.getTranslate.MENU_MAIN, link: '/' },
                    { name: 'Test form' }
                ]
            }
        },

        {
            path: "/:catchAll(.*)",
            name: '404',
            component: () => import('@/views/404.vue'),
            meta: {
                onlyAuth: true
            }
        }
    ]
})

router.beforeEach((to, from, next) => {

    const naviMenu = document.getElementById('navbarSupportedContent')

    if( naviMenu && naviMenu.classList.contains('show') ) {

        new bootstrap.Collapse(naviMenu, { hide: true })
    }

    if(to.fullPath == '/auth' && store.state.token.access) {

        next('/') 
        return
    }

    if(to.matched.some(record => record.meta.onlyAuth)) {

        if (store.state.token.access) {

            next() 
            return
        }

        next('/auth') 

    } else {

        next() 
    }
})

export default router
