export default {

    state: {

        profile: {},
        profileProcess: false,
        profileMsg: {},

        profileUpdateProcess: false,
        profileUpdateMsg: {}
    },

    actions: {

        /**
         * Получает профиль пользователя
         * 
         * @param {String} payload - UUID пользователя в системе
         */
        async readProfile({rootState, state, getters, dispatch}) {

            const token = rootState.token.access

            if(token) {

                state.profileProcess = true

                /**
                 * Здесь берём UUID пользователя из localStorage, т.к. во время загрузки приложения в store UUID ещё нет
                 */
                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/users/${localStorage.getItem('ADT_UUID')}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    state.profile = request
                    state.profileMsg = { success: true }

                    state.profileProcess = false

                    return request

                } else {

                    state.profileMsg = { success: false }
                    state.profileMsg.text = getters.getTranslate[request.error] || request.error

                    state.profileProcess = false
                }

            } else {

                state.profileMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        },

        /**
         * Обновляет профиль пользователя
         * @param {Object} payload - поля формы редактирования профиля пользователя
         */
        async updateProfile({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.profileUpdateProcess = true

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/users`, 
                    method: 'PUT', 
                    token,
                    data: payload
                })

                if(!request.error) {

                    state.profile = request
                    state.profileUpdateMsg = { success: true, text: getters.getTranslate.USER_UPDATE_SUCCESS }

                } else {

                    state.profileUpdateMsg = { success: false }
                    state.profileUpdateMsg.text = getters.getTranslate[request.error] || request.error
                }
            
                state.profileUpdateProcess = false
                
            } else {

                state.profileUpdateMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        }
    },

    mutations: {

        setProfile:             (state, data) => state.profile          = data,
        setUpdateProfileMsg:    (state, data) => state.profileUpdateMsg = data
    }
}