export default {

    state: {

        logs: {

            array: []
        },
        logsProcess: false,
        logsMsg: ''
    },

    getters: {

        getLogs: state => state.logs,
        getLogsProcess: state => state.logsProcess,
        getLogsMsg: state => state.logsMsg
    },

    actions: {

        /**
         * Получает информацию об автомобиле
         * 
         * @param {String} payload - VIN-код автомобиля
         */
        async readLogs({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.access

            if(token) {

                state.logsProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        params.length ?
                            params += '&' + param + '=' + payload[param] :
                            params = '?' + param + '=' + payload[param]
                    }
                }

                const request = await dispatch('fetchRequest', {
                    address: `${rootState.config.httpServer}/logs${params}`, 
                    method: 'GET', 
                    token
                })

                if(!request.error) {

                    state.logs      = request
                    state.logsMsg   = { success: true, text: '' }

                } else {

                    state.logs          = {array: []}
                    state.logsMsg       = { success: false }
                    state.logsMsg.text  = getters.getTranslate[request.error] || request.error
                }

                state.logsProcess = false

            } else {

                state.carinfoMsg = { success: false, text: getters.getTranslate.EUnauthRequest }
            }
        }
    },

    mutations: {

        clearLogs: state => { 

            state.logs = { array: [] }
            state.logsMsg = null
        },
    }
}