export default {

    state: {

        httpServer: '/v1',
        wsServer:   '/v1',

        UDS_HTTP:   'https://uds-parser.shatl-t.ru',
        UDS_WS:     'wss://uds-parser.shatl-t.ru',

        imgServer:  'https://aurus-status.its-telematics.com/web/sky_images/'
    },

    mutations: {

        setHttpServer:  (state, data) => state.httpServer    = data,
        setWsServer:    (state, data) => state.wsServer      = data
    }
}